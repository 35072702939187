<template>
  <div id="kt_header" class="header header-sticky" data-kt-sticky="true"
       data-kt-sticky-name="header" data-kt-sticky-offset="{default: '200px', lg: '300px'}"
  >
    <!--begin::Container-->
    <div class="container d-flex align-items-stretch justify-content-between">
      <!--begin::Left-->
      <div class="d-flex align-items-center">
        <!--begin::Title-->
        <h3 class="text-dark fw-bolder my-1 fs-2">{{getPageTitle($route)}}</h3>
        <!--end::Title-->
      </div>
      <!--end::Left-->

      <!--begin::Right-->
      <div class="d-flex align-items-center">
          <!-- Toggle Profile -->
          <div class="ms-1 ms-lg-6" style="position: relative;">
              <div class="btn btn-icon btn-sm btn-active-bg-accent" :class="{'btn-accent': (this.$route?.name || '').startsWith('settings')}" @click="this.$router.push({name: 'settings'})">
                <span class="fs-4">
                    <span class="bi bi-gear"></span>
                </span>
              </div>
          </div>

        <!-- Toggle Profile -->
        <div class="ms-1" style="position: relative;">
          <div class="btn btn-icon btn-sm btn-active-bg-accent" @click="toggleProfile(profileToggled ? 'hide' : 'show')" :class="profileToggled ? 'show menu-dropdown' : ''">
            <span class="svg-icon svg-icon-1 svg-icon-dark" @click="toggleProfile(profileToggled ? 'hide' : 'show')" :class="profileToggled ? 'show menu-dropdown' : ''">
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" @click="toggleProfile(profileToggled ? 'hide' : 'show')" :class="profileToggled ? 'show menu-dropdown' : ''">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24" />
                  <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                  <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero" />
                </g>
              </svg>
            </span>
            <!--end::Svg Icon-->
          </div>
          <div ref="profileMenu" id="profileMenu"
               class="menu modal-sticky menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold m-5 w-250px"
               data-toggle="modal" data-target="#staticBackdrop"
               :class="profileToggled ? 'show menu-dropdown' : ''"
               :style="profileToggled ? 'z-index: auto; position: absolute; top: 30px; right: -20px;' : ''"
          >
            <!--begin::ModalHeader-->
            <div class="menu-content fw-bold d-flex align-items-center bg-primary rounded-top">
              <div class="h-40px m-auto mt-5">
                <span class="text-white fw-bolder fs-4">Bonjour, {{ currentUser.firstName }}</span>
              </div>
              <div class="btn btn-icon btn-sm" @click="toggleProfile('hide')">
                <i class="bi text-white bi-x-lg"></i>
              </div>
            </div>
            <!--end::ModalHeader-->
            <!--begin::Row-->
            <div class="row row-cols-2 g-0">
              <router-link @click="toggleProfile('hide')" data-dismiss="modal" ref="menu_link1" :to="{name: 'settings.account_settings'}" class="col border-end text-center py-10 btn btn-active-color-primary rounded-0">
                <!--begin::Svg Icon | path: icons/duotone/Layout/Layout-4-blocks.svg-->
                <span class="svg-icon svg-icon-3x me-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24" />
                      <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5" />
                      <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" fill="#000000" opacity="0.3" />
                    </g>
                  </svg>
                </span>
                <!--end::Svg Icon-->
                <span class="fw-bolder fs-6 d-block pt-3">Paramètres</span>
              </router-link>
              <router-link @click="toggleProfile('hide')" data-dismiss="modal" ref="menu_link2" :to="{name: 'logout'}" class="col text-center py-10 btn btn-active-color-primary rounded-0">
                <!--begin::Svg Icon | path: icons/duotone/Navigation/Sign-out.svg-->
                <span class="svg-icon svg-icon-3x me-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24" />
                      <path d="M14.0069431,7.00607258 C13.4546584,7.00607258 13.0069431,6.55855153 13.0069431,6.00650634 C13.0069431,5.45446114 13.4546584,5.00694009 14.0069431,5.00694009 L15.0069431,5.00694009 C17.2160821,5.00694009 19.0069431,6.7970243 19.0069431,9.00520507 L19.0069431,15.001735 C19.0069431,17.2099158 17.2160821,19 15.0069431,19 L3.00694311,19 C0.797804106,19 -0.993056895,17.2099158 -0.993056895,15.001735 L-0.993056895,8.99826498 C-0.993056895,6.7900842 0.797804106,5 3.00694311,5 L4.00694793,5 C4.55923268,5 5.00694793,5.44752105 5.00694793,5.99956624 C5.00694793,6.55161144 4.55923268,6.99913249 4.00694793,6.99913249 L3.00694311,6.99913249 C1.90237361,6.99913249 1.00694311,7.89417459 1.00694311,8.99826498 L1.00694311,15.001735 C1.00694311,16.1058254 1.90237361,17.0008675 3.00694311,17.0008675 L15.0069431,17.0008675 C16.1115126,17.0008675 17.0069431,16.1058254 17.0069431,15.001735 L17.0069431,9.00520507 C17.0069431,7.90111468 16.1115126,7.00607258 15.0069431,7.00607258 L14.0069431,7.00607258 Z" fill="#000000" fill-rule="nonzero" opacity="0.5" transform="translate(9.006943, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-9.006943, -12.000000)" />
                      <rect fill="#000000" opacity="0.5" transform="translate(14.000000, 12.000000) rotate(-270.000000) translate(-14.000000, -12.000000)" x="13" y="6" width="2" height="12" rx="1" />
                      <path d="M21.7928932,9.79289322 C22.1834175,9.40236893 22.8165825,9.40236893 23.2071068,9.79289322 C23.5976311,10.1834175 23.5976311,10.8165825 23.2071068,11.2071068 L20.2071068,14.2071068 C19.8165825,14.5976311 19.1834175,14.5976311 18.7928932,14.2071068 L15.7928932,11.2071068 C15.4023689,10.8165825 15.4023689,10.1834175 15.7928932,9.79289322 C16.1834175,9.40236893 16.8165825,9.40236893 17.2071068,9.79289322 L19.5,12.0857864 L21.7928932,9.79289322 Z" fill="#000000" fill-rule="nonzero" transform="translate(19.500000, 12.000000) rotate(-90.000000) translate(-19.500000, -12.000000)" />
                    </g>
                  </svg>
                </span>
                <!--end::Svg Icon-->
                <span class="fw-bolder fs-6 d-block pt-3">Déconnexion</span>
              </router-link>
            </div>
            <div class="p-3">
              <div v-if="this.currentUser.isSuperuser && this.currentUser.orgs.length > 0">
                <h4 class="menu-content text-muted mb-0 ps-0 fs-6 fw-bold text-uppercase">ORG</h4>
                <select class="form-select form-select-solid" aria-label="Select project" @change="updateOrg">
                  <option v-for="org in this.currentUser.orgs" :value="org.id" :name="org.name" :key="org.id" :selected="org.id === this.currentUser.org.id">{{ org.name }}</option>
                </select>
              </div>
            </div>
            <!--end::Row-->
          </div>
        </div>

        <!-- Notifications -->
        <!--
        <div class="ms-1 ms-lg-6">
          &lt;!&ndash;begin::Dropdown&ndash;&gt;
          <button class="btn btn-icon btn-sm btn-light-danger fw-bolder pulse pulse-danger" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" id="kt_activities_toggle">
            <span class="position-absolute fs-6">{{notif_count}}</span>
            <span class="pulse-ring"></span>
          </button>
          &lt;!&ndash;begin::Menu&ndash;&gt;
          <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded fw-bold menu-title-gray-800 menu-hover-bg menu-state-title-primary w-250px w-md-300px" data-kt-menu="true">
            <div class="menu-item mx-3">
              <div class="menu-content fs-6 text-dark fw-bolder py-6">4 New Notifications</div>
            </div>
            <div class="separator mb-3"></div>
            <div class="menu-item mx-3">
              <a href="#" class="menu-link px-4 py-3">
                <div class="symbol symbol-35px">
													<span class="symbol-label bg-light-info">
														&lt;!&ndash;begin::Svg Icon | path: icons/duotone/Home/Library.svg&ndash;&gt;
														<span class="svg-icon svg-icon-3 svg-icon-info">
															<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																	<rect x="0" y="0" width="24" height="24" />
																	<path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000" />
																	<rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)" x="16.3255682" y="2.94551858" width="3" height="18" rx="1" />
																</g>
															</svg>
														</span>
                            &lt;!&ndash;end::Svg Icon&ndash;&gt;
													</span>
                </div>
                <div class="ps-4">
                  <span class="menu-title fw-bold mb-1">New Uer Library Added</span>
                  <span class="text-muted fw-bold d-block fs-7">3 Hours ago</span>
                </div>
              </a>
            </div>
            <div class="menu-item mx-3">
              <a href="#" class="menu-link px-4 py-3">
                <div class="symbol symbol-35px">
													<span class="symbol-label bg-light-warning">
														&lt;!&ndash;begin::Svg Icon | path: icons/duotone/Devices/Mic.svg&ndash;&gt;
														<span class="svg-icon svg-icon-3 svg-icon-warning">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																<path d="M12.9975507,17.929461 C12.9991745,17.9527631 13,17.9762852 13,18 L13,21 C13,21.5522847 12.5522847,22 12,22 C11.4477153,22 11,21.5522847 11,21 L11,18 C11,17.9762852 11.0008255,17.9527631 11.0024493,17.929461 C7.60896116,17.4452857 5,14.5273206 5,11 L7,11 C7,13.7614237 9.23857625,16 12,16 C14.7614237,16 17,13.7614237 17,11 L19,11 C19,14.5273206 16.3910388,17.4452857 12.9975507,17.929461 Z" fill="#000000" fill-rule="nonzero" />
																<rect fill="#000000" opacity="0.3" transform="translate(12.000000, 8.000000) rotate(-360.000000) translate(-12.000000, -8.000000)" x="9" y="2" width="6" height="12" rx="3" />
															</svg>
														</span>
                            &lt;!&ndash;end::Svg Icon&ndash;&gt;
													</span>
                </div>
                <div class="ps-4">
                  <span class="menu-title fw-bold mb-1">Clean Microphone</span>
                  <span class="text-muted fw-bold d-block fs-7">5 Hours ago</span>
                </div>
              </a>
            </div>
            <div class="menu-item mx-3">
              <a href="#" class="menu-link px-4 py-3">
                <div class="symbol symbol-35px">
													<span class="symbol-label bg-light-primary">
														&lt;!&ndash;begin::Svg Icon | path: icons/duotone/Communication/Group-chat.svg&ndash;&gt;
														<span class="svg-icon svg-icon-3 svg-icon-primary">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																<path d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z" fill="#000000" />
																<path d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z" fill="#000000" opacity="0.3" />
															</svg>
														</span>
                            &lt;!&ndash;end::Svg Icon&ndash;&gt;
													</span>
                </div>
                <div class="ps-4">
                  <span class="menu-title fw-bold mb-1">Quick Chat Created</span>
                  <span class="text-muted fw-bold d-block fs-7">A Day ago</span>
                </div>
              </a>
            </div>
            <div class="menu-item mx-3">
              <a href="#" class="menu-link px-4 py-3">
                <div class="symbol symbol-35px">
													<span class="symbol-label bg-light-danger">
														&lt;!&ndash;begin::Svg Icon | path: icons/duotone/General/Attachment2.svg&ndash;&gt;
														<span class="svg-icon svg-icon-3 svg-icon-danger">
															<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																	<rect x="0" y="0" width="24" height="24" />
																	<path d="M11.7573593,15.2426407 L8.75735931,15.2426407 C8.20507456,15.2426407 7.75735931,15.6903559 7.75735931,16.2426407 C7.75735931,16.7949254 8.20507456,17.2426407 8.75735931,17.2426407 L11.7573593,17.2426407 L11.7573593,18.2426407 C11.7573593,19.3472102 10.8619288,20.2426407 9.75735931,20.2426407 L5.75735931,20.2426407 C4.65278981,20.2426407 3.75735931,19.3472102 3.75735931,18.2426407 L3.75735931,14.2426407 C3.75735931,13.1380712 4.65278981,12.2426407 5.75735931,12.2426407 L9.75735931,12.2426407 C10.8619288,12.2426407 11.7573593,13.1380712 11.7573593,14.2426407 L11.7573593,15.2426407 Z" fill="#000000" opacity="0.3" transform="translate(7.757359, 16.242641) rotate(-45.000000) translate(-7.757359, -16.242641)" />
																	<path d="M12.2426407,8.75735931 L15.2426407,8.75735931 C15.7949254,8.75735931 16.2426407,8.30964406 16.2426407,7.75735931 C16.2426407,7.20507456 15.7949254,6.75735931 15.2426407,6.75735931 L12.2426407,6.75735931 L12.2426407,5.75735931 C12.2426407,4.65278981 13.1380712,3.75735931 14.2426407,3.75735931 L18.2426407,3.75735931 C19.3472102,3.75735931 20.2426407,4.65278981 20.2426407,5.75735931 L20.2426407,9.75735931 C20.2426407,10.8619288 19.3472102,11.7573593 18.2426407,11.7573593 L14.2426407,11.7573593 C13.1380712,11.7573593 12.2426407,10.8619288 12.2426407,9.75735931 L12.2426407,8.75735931 Z" fill="#000000" transform="translate(16.242641, 7.757359) rotate(-45.000000) translate(-16.242641, -7.757359)" />
																	<path d="M5.89339828,3.42893219 C6.44568303,3.42893219 6.89339828,3.87664744 6.89339828,4.42893219 L6.89339828,6.42893219 C6.89339828,6.98121694 6.44568303,7.42893219 5.89339828,7.42893219 C5.34111353,7.42893219 4.89339828,6.98121694 4.89339828,6.42893219 L4.89339828,4.42893219 C4.89339828,3.87664744 5.34111353,3.42893219 5.89339828,3.42893219 Z M11.4289322,5.13603897 C11.8194565,5.52656326 11.8194565,6.15972824 11.4289322,6.55025253 L10.0147186,7.96446609 C9.62419433,8.35499039 8.99102936,8.35499039 8.60050506,7.96446609 C8.20998077,7.5739418 8.20998077,6.94077682 8.60050506,6.55025253 L10.0147186,5.13603897 C10.4052429,4.74551468 11.0384079,4.74551468 11.4289322,5.13603897 Z M0.600505063,5.13603897 C0.991029355,4.74551468 1.62419433,4.74551468 2.01471863,5.13603897 L3.42893219,6.55025253 C3.81945648,6.94077682 3.81945648,7.5739418 3.42893219,7.96446609 C3.0384079,8.35499039 2.40524292,8.35499039 2.01471863,7.96446609 L0.600505063,6.55025253 C0.209980772,6.15972824 0.209980772,5.52656326 0.600505063,5.13603897 Z" fill="#000000" opacity="0.3" transform="translate(6.014719, 5.843146) rotate(-45.000000) translate(-6.014719, -5.843146)" />
																	<path d="M17.9142136,15.4497475 C18.4664983,15.4497475 18.9142136,15.8974627 18.9142136,16.4497475 L18.9142136,18.4497475 C18.9142136,19.0020322 18.4664983,19.4497475 17.9142136,19.4497475 C17.3619288,19.4497475 16.9142136,19.0020322 16.9142136,18.4497475 L16.9142136,16.4497475 C16.9142136,15.8974627 17.3619288,15.4497475 17.9142136,15.4497475 Z M23.4497475,17.1568542 C23.8402718,17.5473785 23.8402718,18.1805435 23.4497475,18.5710678 L22.0355339,19.9852814 C21.6450096,20.3758057 21.0118446,20.3758057 20.6213203,19.9852814 C20.2307961,19.5947571 20.2307961,18.9615921 20.6213203,18.5710678 L22.0355339,17.1568542 C22.4260582,16.76633 23.0592232,16.76633 23.4497475,17.1568542 Z M12.6213203,17.1568542 C13.0118446,16.76633 13.6450096,16.76633 14.0355339,17.1568542 L15.4497475,18.5710678 C15.8402718,18.9615921 15.8402718,19.5947571 15.4497475,19.9852814 C15.0592232,20.3758057 14.4260582,20.3758057 14.0355339,19.9852814 L12.6213203,18.5710678 C12.2307961,18.1805435 12.2307961,17.5473785 12.6213203,17.1568542 Z" fill="#000000" opacity="0.3" transform="translate(18.035534, 17.863961) scale(1, -1) rotate(45.000000) translate(-18.035534, -17.863961)" />
																</g>
															</svg>
														</span>
                            &lt;!&ndash;end::Svg Icon&ndash;&gt;
													</span>
                </div>
                <div class="ps-4">
                  <span class="menu-title fw-bold mb-1">32 New Attachements</span>
                  <span class="text-muted fw-bold d-block fs-7">2 Day ago</span>
                </div>
              </a>
            </div>
            <div class="separator mt-3"></div>
            <div class="menu-item mx-2">
              <div class="menu-content py-5">
                <a href="#" class="btn btn-primary fw-bolder me-2 px-5">Report</a>
                <a href="#" class="btn btn-light fw-bolder px-5">Reset</a>
              </div>
            </div>
          </div>
          &lt;!&ndash;end::Menu&ndash;&gt;
          &lt;!&ndash;end::Dropdown&ndash;&gt;
        </div>
-->

        <!-- Aside Toggler -->
        <button @click="toggleAside()" class="btn btn-icon btn-sm btn-active-bg-accent d-lg-none ms-1 ms-lg-6" id="kt_aside_toggler">
          <!--begin::Svg Icon | path: icons/duotone/Text/Menu.svg-->
          <span class="svg-icon svg-icon-1 svg-icon-dark">
            <svg v-if="!this.asideToggled" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <rect fill="#000000" x="4" y="5" width="16" height="3" rx="1.5" />
                <path d="M5.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 Z M5.5,10 L18.5,10 C19.3284271,10 20,10.6715729 20,11.5 C20,12.3284271 19.3284271,13 18.5,13 L5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z" fill="#000000" opacity="0.3" />
              </g>
            </svg>
            <i v-else class="bi bi-x fs-1" width="24px" height="24px"></i>
          </span>
          <!--end::Svg Icon-->
        </button>
      </div>
      <!--end::Right-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import kalmStore from "@/store";
import {CURRENT_USER_QUERY, MUTATION_SUPERUSER_CHANGE_ORG} from "@/graphql/graphql";

export default {
  name: "MainHeader",
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
    }
  },
  data() {
    return {
      title: "Tableau de bord",
      notif_count: 3,
      notifs: [
        {
          name: "Nouveau projet ajouté",
          link: "/project/01",
          type: "project",
          time: "3 hours ago"
        }
      ],
      currentUser: {
        email: '',
        firstName: '',
        lastName: '',
      }
    }
  },
  methods: {
    updateOrg(event) {
      const orgId = event.target.value || 0;

      this.$apollo.mutate({
        mutation: MUTATION_SUPERUSER_CHANGE_ORG,
        variables: {
          newOrgId: orgId
        },
      }).then(() => {
        window.location.reload();
      })
    },
    getPageTitle (route) {
      let title;
      if(
          route.name &&
          (route.name === 'project'
              || route.name === 'project.home'
              || route.name.startsWith('project.'))
      ){
        title = "Projet – "+kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus).name;
      }
      else {
        title = route.meta.title;
      }
      return title;
    },
    toggleAside() {
      kalmStore.commit('toggleAside')
    },
    toggleProfile(mode) {
      kalmStore.commit('toggleProfile', mode === "show" ? mode : "hide")
    },
  },
  computed: {
    asideToggled() {
      return kalmStore.state.display.asideToggled
    },
    profileToggled() {
      return kalmStore.state.display.profileToggled
    },
    printMode() {
      switch (this.$route.name) {
        case "project.tender.table":
        case "project.tender.scenarios":
          return "landscape"
        default:
          return "portrait"
      }
    },
  }
}
</script>

<style scoped>
  .header-sticky {
      /*noinspection CssInvalidPropertyValue*/
      position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #FBF9F6;
  }

  @media print {
    .header-sticky {
      display: none;
    }
  }
</style>

<style>
  @media print {
    .print-hidden {
      display: none;
    }
    body {
      margin: 25mm 5mm 25mm 5mm;
      background-color: #FFFFFF !important;
    }
    tr, th {
      break-inside: avoid;
    }
    .card {
      box-shadow: none;
    }
    @page {
      size: auto;
    }
  }
</style>