<template>
  <node-view-wrapper class="vue-component">
<!--
    <div @click="this.propExpanded = !this.propExpanded"
          class="bi cursor-pointer print-hidden" :class="this.propExpanded ? 'bi-arrow-down text-primary' : 'bi-arrow-right'"
          style="position: relative; left: -25px; top: -27px; height: 1px;">
    </div>
    <span v-if="!this.propExpanded" class="m-2 text-muted print-hidden">Contenu du lot masqué. Cliquez sur <i class="bi bi-arrow-right"></i> ci-dessus pour afficher tous les ouvrages.</span>
    -->
    <node-view-content id="nodeViewContent"></node-view-content>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent } from '@tiptap/vue-3'

export default {
  name: "LotContentComponent",
  props: {
    node: {
      type: Object,
      required: true,
      default: () => ({
        expanded: true
      })
    },
    updateAttributes: {
      type: Function,
      required: true,
    }
  },
  components: {
    NodeViewWrapper,
    NodeViewContent
  },
  computed: {
    propExpanded: {
      get () {
        return this.node.attrs.expanded
      },
      set (value) {
        this.updateAttributes({
          expanded: value,
        })
      }
    },
  },
  methods: {}
}
</script>

<style>

.ProseMirror-focused:focus {
  outline: none !important;
}
.ProseMirror:focus {
  outline: none !important;
}
.ProseMirror-focused:focus-visible {
  outline: none !important;
}
.ProseMirror:focus-visible {
  outline: none !important;
}

@media print {
  .ouvrage-actions {
    display: none;
  }

  #nodeViewContent {
    display: block !important;
  }
}

/*.vue-component {
  background: #FAF594;
  border: 3px solid #0D0D0D;
  border-radius: 0.5rem;
  margin: 1rem 0;
  position: relative;
}

.label {
  margin-left: 1rem;
  background-color: #0D0D0D;
  font-size: 0.6rem;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  position: absolute;
  top: 0;
  padding: 0.25rem 0.75rem;
  border-radius: 0 0 0.5rem 0.5rem;
}

.content {
  margin: 2.5rem 1rem 1rem;
  padding: 0.5rem;
  border: 2px dashed #0D0D0D20;
  border-radius: 0.5rem;
}*/
</style>