<template>
    <div id="kt_aside" style="z-index: 10000 !important;" class="aside bg-info print-hidden" :class="drawerClass">
        <div class="aside-secondary overflow-y-auto d-flex flex-row-fluid bg-white">
            <!--begin::Workspace-->
            <div class="aside-workspace my-7 ps-5 pe-4 ps-lg-10 pe-lg-6" id="kt_aside_wordspace">
                <!--begin::Logo-->
                <div class="aside-logo pt-2 pb-3" id="kt_aside_logo">
                    <router-link :to="{name: 'dashboard'}">
                        <img alt="Logo" src="/assets/media/logos/logo-compact-positive.png" fill="#01949A"
                             class="mh-100px"
                             @click="this.closeAside()"/>
                    </router-link>
                </div>
                <!--end::Logo-->
                <!--begin::Menu-->
                <div
                    class="menu menu-column menu-rounded menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold fs-6"
                    data-kt-menu="true">
                    <div class="hover-scroll-y pe-4 pe-lg-5" id="kt_aside_menu_scroll" data-kt-scraoll="true"
                         data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_aside_logo"
                         data-kt-scroll-wrappers="#kt_aside_wordspace" data-kt-scroll-offset="10px">
                        <div class="menu-wrapper menu-column">
                            <!-- FIRST HALF - GENERAL LINKS -->
                            <div class="menu-item">
                                <h4 class="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">GÉNÉRAL</h4>
                                <div class="menu-sub menu-fit menu-sub-accordion show pb-4">
                                    <div class="menu-item" v-for="item in general_items" :key="item.destination">
                                        <template v-if="item?.type === 'link'">
                                            <a :href="item.destination" class="menu-link py-2 link-primary"
                                               target="_blank"
                                               v-bind:class="(item.force_active || isPageActive(item.destination)) ? 'active' : ''"
                                               @click="this.closeAside()">
                                <span class="menu-icon" v-if="item.icon !== undefined">
                                    <i class="bi fs-3" v-bind:class="item.icon"></i>
                                </span>
                                                <span class="menu-title">{{ item.name }}</span>
                                            </a>
                                        </template>
                                        <template v-else>
                                            <router-link :to="{ name: item.destination, hash: item?.hash }"
                                                         class="menu-link py-2 link-primary"
                                                         v-bind:class="(item.force_active || isPageActive(item.destination)) ? 'active' : ''"
                                                         @click="this.closeAside()">
                                <span class="menu-icon" v-if="item.icon !== undefined">
                                    <i class="bi fs-3" v-bind:class="item.icon"></i>
                                </span>
                                                <span class="menu-title">{{ item.name }}</span>
                                            </router-link>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <!-- SECOND HALF - PROJECT SPECIFIC LINKS -->
                            <div class="menu-item">
                                <h4 class="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">PROJET</h4>
                                <select class="form-select form-select-solid" ref="mainTourProjectSwitch"
                                        aria-label="Select project" @change="updateFocus($event); closeAside()"
                                        @click="updateFocus($event)">
                                    <option v-for="project in sortedProjects.filter(p => !p.archived)"
                                            :value="project.id" :name="project.name" :key="project.id"
                                            :selected="project.id === currentProjectFocus">{{ project.name }}
                                    </option>
                                </select>
                                <br>
                                <div class="menu-sub menu-fit menu-sub-accordion show pb-10">
                                    <div class="menu-item"
                                         v-for="item in project_items.filter(item => !item?.group || item?.group && this.currentUser?.groups?.includes(item.group))"
                                         :key="item.destination">

                                        <template v-if="item?.type === 'link'">
                                            <a class="menu-link py-2 link-primary" :href="item.destination"
                                               target="_blank"
                                               v-if="!item.disabled && (!item.group || this.currentUser?.groups?.includes(item.group))"
                                               v-bind:class="{ active: item.force_active || isPageActive(item.destination), disabled: item.disabled || item.group && !this.currentUser?.groups?.includes(item.group) }"
                                               @click="this.closeAside()"
                                            >
                      <span class="menu-icon" v-if="item.icon !== undefined">
                          <i class="bi fs-3" v-bind:class="item.icon"></i>
                      </span>
                                                <span class="menu-title">{{ item.name }}</span>
                                            </a>
                                        </template>
                                        <template v-else>
                                            <router-link
                                                :to="{ name: item.destination, params: { id: currentProjectFocus }}"
                                                class="menu-link py-2 link-primary"
                                                v-if="!item.disabled && (!item.group || this.currentUser?.groups?.includes(item.group))"
                                                v-bind:class="{ active: item.force_active || isPageActive(item.destination), disabled: item.disabled || item.group && !this.currentUser?.groups?.includes(item.group) }"
                                                @click="this.closeAside()"
                                            >
                      <span class="menu-icon" v-if="item.icon !== undefined">
                          <i class="bi fs-3" v-bind:class="item.icon"></i>
                      </span>
                                                <span class="menu-title">{{ item.name }}</span>
                                            </router-link>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end::Menu-->
            </div>
            <!--end::Workspace-->
        </div>
    </div>
</template>

<script>

import {
ALL_PROJECTS_QUERY,
CURRENT_USER_QUERY,
MUTATION_SUPERUSER_CHANGE_ORG
} from "@/graphql/graphql";
import kalmStore from "@/store";
import * as Sentry from "@sentry/vue";


export default {
    name: "MainMenu",
    inject: ['toggle', 'hideWidget', 'showWidget', 'onLoad', 'visitor', 'setAttributes'],
    apollo: {
        projects: ALL_PROJECTS_QUERY,
        currentUser: {
            query: CURRENT_USER_QUERY,
            result(queryResult) {
                const user = queryResult.data.currentUser;

                // identify the user on posthog
                this.$posthog.identify(
                    user.id,  // Replace 'distinct_id' with your user's unique identifier
                    {email: user.email, name: user.firstName + ' ' + user.lastName} // optional: set additional user properties
                );

                // identify the user on sentry
                if (Sentry?.setUser) {
                    Sentry.setUser({
                        id: user.id,
                        email: user.email,
                        username: user.firstName + ' ' + user.lastName + ' ' + user?.org?.name
                    });
                }

                // identify the user on trustlead
                if (window?.trustlead && user?.trustleadActivated && user?.trustleadToken && user?.trustleadOrganizationId) {
                    window.trustlead({
                        token: user.trustleadToken,
                        organisationId: user.trustleadOrganizationId,
                    })
                }

                // identify the user on tawk.to
                this.onLoad(() => {
                    setTimeout(() => this.setAttributes({
                        email: user.email,
                        name: user.firstName + ' ' + user.lastName
                    }), 3000)
                })
            },
        },
    },
    data() {
        return {
            currentUser: {
                orgs: [],
                org: {
                    id: "",
                },
                groups: [],
                isSuperuser: false,
            },
            general_items: [
                {
                    name: "Tableau de bord",
                    icon: "bi-house-door",
                    force_active: false,
                    id: "dashboard",
                    destination: "dashboard",
                },
                {
                    name: "Contacts",
                    icon: "bi-people",
                    force_active: false,
                    id: "contacts",
                    destination: "contacts",
                },
                {
                    name: "Bibliothèques",
                    icon: "bi-bookmark-check",
                    force_active: false,
                    id: "biblios",
                    destination: "biblios",
                },
                {
                    name: "Documents",
                    icon: "bi-folder",
                    force_active: false,
                    id: "documents",
                    destination: "documents",
                },
                {
                    name: "Todo",
                    icon: "bi-check2-square",
                    force_active: false,
                    id: "todos",
                    destination: "todos",
                    group: "feature_todos",
                },
                {
                    name: "Projets",
                    icon: "bi-collection",
                    force_active: false,
                    id: "projects",
                    destination: "projects",
                },
            ],
            project_items: [
                {
                    name: "Cockpit",
                    icon: "bi-bullseye",
                    force_active: false,
                    id: "project.home",
                    destination: "project.home",
                },
                {
                    name: "Descriptif",
                    icon: "bi-file-text",
                    force_active: false,
                    id: "description",
                    destination: "project.description",
                },
                {
                    name: "Documents",
                    icon: "bi-folder",
                    force_active: false,
                    id: "documents",
                    destination: "project.documents",
                },
                {
                    name: "Planning",
                    icon: "bi-calendar3",
                    force_active: false,
                    id: "project_planning",
                    group: "feature_planning_old",
                    destination: "project.planning",
                },
                {
                    name: "Planning (v2 - beta)",
                    icon: "bi-calendar3",
                    force_active: false,
                    id: "project_planning",
                    group: "feature_planning_new",
                    destination: "project.planning_new",
                },
                {
                    name: "Consultation",
                    icon: "bi-journals",
                    force_active: false,
                    disabled: false,
                    id: "description",
                    destination: "project.tender",
                },
                {
                    name: "Notes projet",
                    icon: "bi-journal-bookmark",
                    force_active: false,
                    id: "project_notes",
                    destination: "project.notes",
                    group: "feature_notes"
                },
                {
                    name: "Suivi de chantier (ancien)",
                    icon: "bi-card-checklist",
                    force_active: false,
                    id: "monitoring_old",
                    destination: "project.monitoring_old",
                    group: "feature_appsheet",
                },
                {
                    name: "Suivi de chantier",
                    icon: "bi-list-task",
                    force_active: false,
                    id: "tasks",
                    destination: "project.tasks",
                    group: "feature_chantier",
                },
                {
                    name: "Todo",
                    icon: "bi-check2-square",
                    force_active: false,
                    id: "project_todos",
                    destination: "project.todos",
                    group: "feature_todos",
                },
                {
                    name: "FAQ",
                    type: "link",
                    icon: "bi-question-circle",
                    force_active: false,
                    id: "faq",
                    destination: "https://drive.google.com/file/d/1Fp5uCzAx-CYee2-2PIDuQjt7_--3ir2t/view?usp=sharing",
                },
            ],
            windowWidth: window.innerWidth,
            asideMode: '',
            projects: []
        }
    },
    methods: {
        closeAside() {
            kalmStore.commit('closeAside');
        },
        a() {
            this.$router.push({name: 'contacts'})
        },
        /**
         * Compares a menu page ID to the current route
         * @param page_id
         * @returns {boolean} t rue if page_id matches current route
         */
        isPageActive(page_id) {
            return String(this.$route.name).startsWith(page_id);
        },
        toggleAside() {
            kalmStore.commit('toggleAside');
        },
        updateAsideMode() {
            this.windowWidth = window.innerWidth;
            this.asideMode = (this.windowWidth < 992) ? 'drawer' : 'permanent';
            return this.asideMode;
        },
        updateFocus(event) {
            // preventing a bug on firefox where selectedIndex is not defined
            if (event?.target?.selectedIndex || event?.target?.selectedIndex === 0) {
                const projectId = event.target.value || 0;
                const projectName = event.target[event.target.selectedIndex].name || "0";

                kalmStore.commit("setCurrentProjectFocus", {projectId, projectName})

                const current_route = String(this.$route.name);
                if (current_route === "project.home" || current_route.startsWith("project.")) {
                    this.$router.push({name: current_route, params: {id: projectId}})
                }
            }
        },
        updateOrg(event) {
            const orgId = event.target.value || 0;

            this.$apollo.mutate({
                mutation: MUTATION_SUPERUSER_CHANGE_ORG,
                variables: {
                    newOrgId: orgId
                },
            })
        }
    },
    async mounted() {
        window.addEventListener("resize", this.updateAsideMode);

        const projects = await this.$apollo.query({query: ALL_PROJECTS_QUERY});

        projects.data.projects.forEach((p) => {
            const doc = JSON.parse(p.document);

            const projectId = p.id;
            const lots = p.lots;
            const locations = p.locations;
            const contacts = p.contacts;
            const name = p.name;
            const address = p.address;
            const infoOpName = p.infoOpName;
            const importantInfo = p.importantInfo;
            const archived = p.archived;
            const featureMultiLocation = p.featureMultiLocation;

            kalmStore.commit("initializeLocalProject", {
                projectId,
                name,
                address,
                infoOpName,
                importantInfo,
                archived,
                newProject: doc,
                lots,
                locations,
                contacts,
                featureMultiLocation,
                phases: JSON.parse(p.phases) || [],
                currentPhase: p.currentPhase,
            })
        })

        if (!kalmStore.getters.currentProjectFocus
            || kalmStore.getters.currentProjectFocus === 0
            || kalmStore.getters.currentProjectFocus === "0") {
            kalmStore.commit("setCurrentProjectFocus", {projectId: projects.data.projects[0].id})
        }
    },
    unmounted() {
        window.removeEventListener("resize", this.updateAsideMode);
    },
    beforeMount() {
        this.updateAsideMode()
    },
    computed: {
        asideToggled() {
            return kalmStore.state.display.asideToggled;
        },
        drawerClass() {
            let mode = this.asideMode;
            let toggled = kalmStore.state.display.asideToggled;

            if (mode === 'permanent') {
                return "";
            } else if (mode === 'drawer' && !toggled) {
                return "drawer drawer-start";
            } else if (mode === 'drawer' && toggled) {
                return "drawer drawer-start drawer-on";
            }
            return null;
        },
        currentProjectFocus() {
            return kalmStore.state.display.currentProjectFocus || 1
        },
        sortedProjects() {
            return JSON.parse(JSON.stringify(this.projects)).sort(function (a, b) {
                if ('' + a.name < '' + b.name) return -1
                if ('' + a.name > '' + b.name) return 1
                return 0
            })
        },
        routeName() {
            return this.$route.name;
        },
    },
    watch: {
        routeName(to) {
            if (to === "project.notes") {
                if (window?.Tawk_API?.hideWidget) {
                    this.hideWidget()
                }
            } else {
                if (window?.Tawk_API?.showWidget) {
                    this.showWidget()
                }
            }
        },
    }
}

</script>

<style scoped>
a.disabled {
    pointer-events: none;
}

a.disabled span, a.disabled span i {
    color: lightgray !important;
}

@media print {
    .print-hidden {
        display: none;
    }
}
</style>