<template>
    <div>
        <span class="badge badge-primary me-2" v-for="task in tasks" :key="task.id">
            {{ task.title }}
        </span>
    </div>
</template>

<script>
export default {
    name: "TaskTagList",
    props: {
        tasks: {
            type: Array,
            required: true,
        },
    },
}
</script>