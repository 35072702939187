<template>
  <!--begin::Row-->
  <div class="row g-0 g-xl-5 g-xxl-8"
       :class="isModalActive ? 'blur-content' : ''">
    <div class="col-12">
      <div class="alert bg-light-primary border border-dashed border-primary d-flex flex-column flex-sm-row p-5 mb-5">
        <div class="">
          <span class="bi bi-person-fill text-primary me-4 fs-1 align-middle"></span>
        </div>
        <div class="d-flex flex-column pe-0 pe-sm-10">
          <h5 class="mb-1">Les contacts internes déménagent !</h5>
          <span>Gérez vos utilisateurs et retrouvez tous les paramètres concernant votre équipe dans la page <router-link :to="{name: 'settings.studio_settings'}">Paramètres > Mon studio</router-link>.</span>
        </div>
        <button type="button" class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
          <i class="bi bi-x fs-1"></i>
        </button>
      </div>
      <!-- MES CONTACTS - TABLE -->
      <div class="card card-stretch">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder text-dark fs-3">Mes contacts</span>
            <span class="text-muted mt-2 fw-bold fs-6">{{ contacts_count }} contact<span v-if="contacts_count > 1">s</span></span>
          </h3>
          <div class="card-toolbar align-self-center">
            <ul class="nav nav-pills nav-light">
              <li class="nav-item m-0">
                <button class="btn btn-active-light btn-color-muted btn-outline btn-outline-light border-end-0 rounded-end-0" :class="{'active': this.filterMode === 'all'}" @click="this.setFilterMode('all')">Tous</button>
              </li>
              <li class="nav-item m-0">
                <button class="btn btn-active-light btn-color-muted btn-outline btn-outline-light border-end-0 rounded-0" :class="{'active': this.filterMode === 'customers'}" @click="this.setFilterMode('customers')">Clients<span class="bi bi-person ms-2"></span></button>
              </li>
              <li class="nav-item m-0">
                <button class="btn btn-active-light btn-color-muted btn-outline btn-outline-light rounded-start-0" :class="{'active': this.filterMode === 'companies'}" @click="this.setFilterMode('companies')">Entreprises<span class="bi bi-building ms-2"></span></button>
              </li>
            </ul>
          </div>
          <div class="card-toolbar justify-content-between">
            <div>
              <ul class="nav nav-pills nav-pills-sm nav-light justify-content-end">
                <!--              <li class="nav-item align-middle">
                                <button class="nav-link align-middle btn btn-active-light btn-color-muted fw-bolder px-4" @click="openContactModal"><i class="bi bi-cloud-download"></i> Importer</button>
                              </li>-->
                <li class="nav-item align-middle">
                  <button class="nav-link align-middle btn btn-active-light btn-color-muted fw-bolder px-4" @click="this.modalCreateContactShow()">+ Ajouter</button>
                </li>
                <li>
                  <input class="form-control form-control-solid ms-3" v-model="this.contact_search_filter" placeholder="Rechercher">
                </li>
              </ul>
            </div>

          </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-3 mt-n3">
          <div class="tab-content mt-4" id="myTabTables2">
            <div class="table-responsive">
              <table class="table table-borderless align-middle">
                <thead>
                <tr>
                  <th class="p-0 w-50px"></th>
                  <th class="p-0 min-w-150px"></th>
                  <th class="p-0 min-w-120px"></th>
                  <th class="p-0 min-w-70px"></th>
                  <th class="p-0 min-w-70px"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="contact in getContactsFiltered" :key="contact.id">
                  <td class="px-0 py-3">
                    <div class="symbol symbol-55px mt-1 me-5">
																				<span class="symbol-label bg-light-primary align-items-end">
																					<img alt="Logo" src="/assets/media/svg/avatars/001-boy.svg" class="mh-40px" />
																				</span>
                    </div>
                  </td>
                  <td class="px-0">
                    <span class="text-black fw-bold d-block mt-1">{{ contact.company }}</span>
                    <span class="text-muted fw-bold d-block mt-1">{{ contact.name }}</span>
                  </td>
                  <td class="text-center">
                    <span class="text-gray-800 fw-bolder d-block fs-6">{{ contact.infoCorps }}</span>
                  </td>
                  <td class="text-center">
                    <span class="text-gray-800 d-block fs-6">{{ contact.email }}</span>
                    <span class="text-gray-800 d-block fs-6">{{ contact.phone }}</span>
                  </td>
                  <td class="text-end pe-0">
                    <button class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                      @click="modalConfirmDeleteShow(contact.id)">
                      <span class="bi bi-trash-fill"></span>
                    </button>
                    <button class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white"
                      @click="modalEditContactShow(contact.id)">
                      <span class="bi bi-pencil-fill"></span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
      <!--end::Table Widget 2-->
    </div>
  </div>
  <!--end::Row-->
  <!-- MODAL - CREATE CONTACT -->
  <div class="modal"
       tabindex="-1"
       :style="this.modalCreateContact.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalCreateContactCancel()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactModalForm" class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Nouveau Contact</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Nom(s)</label>
                        <input v-model="modalCreateContact.current.name" type="text" class="form-control form-control-lg form-control-solid" placeholder="Jean & Laura Dupont">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Téléphone</label>
                        <input v-model="modalCreateContact.current.phone" type="text" class="form-control form-control-lg form-control-solid" placeholder="+33 6 23 45 67 89">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div v-if="modalCreateContact.current.phone" class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Téléphone <span class="text-muted">(2)</span></label>
                        <input v-model="modalCreateContact.current.phone2" type="text" class="form-control form-control-lg form-control-solid" placeholder="01 23 45 67 89">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">E-mail</label>
                        <input v-model="modalCreateContact.current.email" type="text" class="form-control form-control-lg form-control-solid" placeholder="jean.dupont@gmail.com">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Entreprise</label>
                        <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                        <input v-model="modalCreateContact.current.company" type="text" class="form-control form-control-lg form-control-solid" placeholder="Sanzot SARL">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row"
                           v-for="typeOption in contact_modal_type_options"
                           :key="typeOption.id">
                        <!--begin:Option-->
                        <label class="d-flex flex-stack mb-6 cursor-pointer">
                          <span class="d-flex align-items-center me-2">
                            <span class="symbol symbol-50px me-6">
                              <span class="symbol-label" :class="typeOption.bg">
                                <i class="fs-1 bi" :class="typeOption.icon"></i>
                              </span>
                            </span>
                            <span class="d-flex flex-column">
                              <span class="fw-bolder fs-6">{{ typeOption.title }}</span>
                              <span class="fs-7 text-muted">{{ typeOption.subtitle }}</span>
                            </span>
                          </span>
                          <span class="form-check form-check-custom form-check-solid">
                            <input class="form-check-input" type="radio" v-model="modalCreateContact.current.type"
                                   :value="typeOption.id">
                          </span>
                        </label>
                        <!--end::Option-->
                      </div>
                      <!--end::Form Group-->

                      <div v-if="modalCreateContact.current.type === 'CUSTOMER_PRO' || modalCreateContact.current.type === 'COMPANY'">
                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">SIRET</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                          <input v-model="modalCreateContact.current.infoSiret" type="text" class="form-control form-control-lg form-control-solid" placeholder="339 863 417 00418">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">Corps d'état</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                          <input v-model="modalCreateContact.current.infoCorps" type="text" class="form-control form-control-lg form-control-solid" placeholder="Électricité">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">Site web</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                          <input v-model="modalCreateContact.current.infoWebsite" type="url" class="form-control form-control-lg form-control-solid" placeholder="https://www.kalm.ai">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">N° TVA</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                          <input v-model="modalCreateContact.current.infoTva" type="text" class="form-control form-control-lg form-control-solid" placeholder="FR03339863417">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">Adresse</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                          <textarea v-model="modalCreateContact.current.infoAddress" class="form-control form-control-lg form-control-solid" placeholder="15 rue du Petit Musc&#10;75004 Paris 4ème"></textarea>
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">Assurance</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Lien vers le fichier ou Numéro)</label>
                          <div class="input-group align-middle">
                            <input v-model="modalCreateContact.current.infoInsurance" type="text" class="form-control form-control-lg form-control-solid" placeholder="https://drive.google.com/uazdAZOjazdlakzdLKAZlkaz">
                            <a v-if="modalCreateContact.current.infoInsurance && modalCreateContact.current.infoInsurance.startsWith('http')" :href="modalCreateContact.current.infoInsurance || '#'" class="input-group-text border-0" :class="{'cursor-default': !modalCreateContact.current.infoInsurance}" :target="modalCreateContact.current.infoInsurance ? '_blank' : ''"><span class="bi bi-link"></span></a>
                          </div>
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">Commentaire</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                          <textarea v-model="modalCreateContact.current.infoComment" class="form-control form-control-lg form-control-solid" placeholder="Électricité générale"></textarea>
                        </div>
                        <!--end::Form Group-->

                      </div>
                        <div class="mt-5">
                            <p class="mt-7 fw-bold">Remplacer l'affichage par défaut du contact par des infos personnalisées :</p>

                            <!--begin::Form Group-->
                            <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                <label class="fs-6 fw-bolder text-dark form-label">Affichage personnalisé - Ligne 1</label>
                                <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                                <input v-model="modalCreateContact.current.infoLine1" class="form-control form-control-lg form-control-solid" :placeholder="modalCreateContact.current.name">
                            </div>
                            <!--end::Form Group-->

                            <!--begin::Form Group-->
                            <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                <label class="fs-6 fw-bolder text-dark form-label">Affichage personnalisé - Ligne 2+</label>
                                <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                                <textarea v-model="modalCreateContact.current.infoLine2" class="form-control form-control-lg form-control-solid" :placeholder="modalCreateContact.current.email+' - '+modalCreateContact.current.phone"></textarea>
                            </div>
                            <!--end::Form Group-->
                        </div>
                    </div>

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-primary btn-active-light-primary" @click="this.modalCreateContactValidate()">
                        Ajouter
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <!-- MODAL CONFIRM DELETE CONTACT-->
  <div class="modal"
       tabindex="-1"
       :style="modalConfirmDelete.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalConfirmDeleteCancel">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <template v-if="this.getProposalsForContact(this.modalConfirmDelete.id).length === 0">
                        <div class="pb-5">
                          <h3 class="fw-bolder text-dark display-6">Supprimer le contact</h3>
                        </div>
                        <div class="pb-5">
                          <p>
                            Vous êtes sur le point de supprimer le contact <strong>{{ this.getContactById(this.modalConfirmDelete.id)?.name }}</strong>. Cette action est irréversible.
                          </p>
                        </div>
                      </template>
                      <template v-else>
                        <div class="pb-5">
                          <h3 class="fw-bolder text-dark display-6">Êtes-vous sûr ?</h3>
                        </div>

                        <p>
                          Si vous supprimez ce contact, <strong>les devis associés seront également supprimés</strong>.
                        </p>

                        <!--begin::Alert-->
                        <div class="alert bg-danger d-flex flex-column flex-sm-row p-5 mb-10">
                          <!--begin::Icon-->
                          <span class="text-white me-4 mb-5 mb-sm-0"><i class="bi fs-1 text-white bi-exclamation-triangle"></i></span>
                          <!--end::Icon-->

                          <!--begin::Wrapper-->
                          <div class="d-flex flex-column text-light pe-0 pe-sm-10">
                            <!--begin::Title-->
                            <h5 class="mb-1">{{ this.getProposalsForContact(this.modalConfirmDelete.id).length }} devis
                              <span v-if="this.getProposalsForContact(this.modalConfirmDelete.id).length === 1"> est associé </span><span v-else> sont associés </span>
                              à ce contact :</h5>
                            <ul>
                              <li v-for="proposal in this.getProposalsForContact(this.modalConfirmDelete.id)" :key="proposal.id">
                                Projet {{ proposal.project.name }} — {{ proposal?.name }}
                              </li>
                            </ul>
                          </div>
                          <!--end::Wrapper-->
                        </div>
                      </template>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="modalConfirmDeleteCancel" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="modalConfirmDeleteValidate" class="btn btn-lg btn-danger ms-2">
                        Supprimer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL EDIT CONTACT -->
  <div class="modal"
       tabindex="-1"
       :style="this.modalEditContact.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalEditContactCancel()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactEditModalForm"
                      class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Modifier le contact</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Nom(s)</label>
                        <input v-model="modalEditContact.current.name" type="text" class="form-control form-control-lg form-control-solid" placeholder="Jean & Laura Dupont">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Téléphone</label>
                        <input v-model="modalEditContact.current.phone" type="text" class="form-control form-control-lg form-control-solid" placeholder="+33 6 23 45 67 89">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div v-if="modalEditContact.current.phone" class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Téléphone <span class="text-muted">(2)</span></label>
                        <input v-model="modalEditContact.current.phone2" type="text" class="form-control form-control-lg form-control-solid" placeholder="01 23 45 67 89">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">E-mail</label>
                        <input v-model="modalEditContact.current.email" type="text" class="form-control form-control-lg form-control-solid" placeholder="jean.dupont@gmail.com">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Entreprise</label>
                        <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                        <input v-model="modalEditContact.current.company" type="text" class="form-control form-control-lg form-control-solid" placeholder="Sanzot SARL">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row"
                           v-for="typeOption in contact_modal_type_options"
                           :key="typeOption.id">
                        <!--begin:Option-->
                        <label class="d-flex flex-stack mb-6 cursor-pointer">
                          <span class="d-flex align-items-center me-2">
                            <span class="symbol symbol-50px me-6">
                              <span class="symbol-label" :class="typeOption.bg">
                                <i class="fs-1 bi" :class="typeOption.icon"></i>
                              </span>
                            </span>
                            <span class="d-flex flex-column">
                              <span class="fw-bolder fs-6">{{ typeOption.title }}</span>
                              <span class="fs-7 text-muted">{{ typeOption.subtitle }}</span>
                            </span>
                          </span>
                          <span class="form-check form-check-custom form-check-solid">
                            <input class="form-check-input" type="radio" v-model="modalEditContact.current.type"
                                   :value="typeOption.id">
                          </span>
                        </label>
                        <!--end::Option-->
                      </div>
                      <!--end::Form Group-->

                      <div v-if="modalEditContact.current.type === 'CUSTOMER_PRO' || modalEditContact.current.type === 'COMPANY'">
                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">SIRET</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                          <input v-model="modalEditContact.current.infoSiret" type="text" class="form-control form-control-lg form-control-solid" placeholder="339 863 417 00418">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">Corps d'état</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                          <input v-model="modalEditContact.current.infoCorps" type="text" class="form-control form-control-lg form-control-solid" placeholder="Électricité">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">Site web</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                          <input v-model="modalEditContact.current.infoWebsite" type="url" class="form-control form-control-lg form-control-solid" placeholder="https://www.kalm.ai">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">N° TVA</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                          <input v-model="modalEditContact.current.infoTva" type="text" class="form-control form-control-lg form-control-solid" placeholder="FR03339863417">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">Adresse</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                          <textarea v-model="modalEditContact.current.infoAddress" class="form-control form-control-lg form-control-solid" placeholder="15 rue du Petit Musc&#10;75004 Paris 4ème"></textarea>
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">Assurance</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Lien vers le fichier ou Numéro)</label>
                          <div class="input-group align-middle">
                            <input v-model="modalEditContact.current.infoInsurance" type="text" class="form-control form-control-lg form-control-solid" placeholder="https://drive.google.com/uazdAZOjazdlakzdLKAZlkaz">
                            <a v-if="modalEditContact.current.infoInsurance && modalEditContact.current.infoInsurance.startsWith('http')" :href="modalEditContact.current.infoInsurance || '#'" class="input-group-text border-0" :class="{'cursor-default': !modalEditContact.current.infoInsurance}" :target="modalEditContact.current.infoInsurance ? '_blank' : ''"><span class="bi bi-link"></span></a>
                          </div>
                        </div>
                        <!--end::Form Group-->

                          <!--begin::Form Group-->
                          <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                              <label class="fs-6 fw-bolder text-dark form-label">Commentaire</label>
                              <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                              <textarea v-model="modalEditContact.current.infoComment" class="form-control form-control-lg form-control-solid" placeholder="Électricité générale"></textarea>
                          </div>
                          <!--end::Form Group-->

                      </div>
                        <div class="mt-5">
                            <p class="mt-7 text-center">Remplacer l'affichage par défaut du contact par des infos personnalisées :</p>

                            <!--begin::Form Group-->
                            <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                <label class="fs-6 fw-bolder text-dark form-label">Affichage personnalisé - Ligne 1</label>
                                <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                                <input v-model="modalEditContact.current.infoLine1" class="form-control form-control-lg form-control-solid" :placeholder="modalEditContact.current.name">
                            </div>
                            <!--end::Form Group-->

                            <!--begin::Form Group-->
                            <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                <label class="fs-6 fw-bolder text-dark form-label">Affichage personnalisé - Ligne 2+</label>
                                <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                                <textarea v-model="modalEditContact.current.infoLine2" class="form-control form-control-lg form-control-solid" :placeholder="modalEditContact.current.email+' - '+modalEditContact.current.phone"></textarea>
                            </div>
                            <!--end::Form Group-->
                        </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light"
                              @click="modalEditContactCancel()">
                        Annuler
                      </button>
                      <button class="btn ms-2 btn-lg btn-primary btn-active-light-primary"
                              @click="modalEditContactValidate()">
                        Modifier
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import kalmStore from "@/store";
import {
ALL_CONTACTS_QUERY,
MUTATION_CONTACT_CREATE,
MUTATION_CONTACT_DELETE,
MUTATION_CONTACT_UPDATE,
} from '../../data/contacts_graphql';

function sortContacts(a,b) {
  return ('' + a?.company + a?.name).localeCompare(('' + b?.company + b?.name));
}

export default {
  name: "ContactsPage",
  apollo: {
    contacts: ALL_CONTACTS_QUERY,
  },
  components: {  },
  methods: {
    getProposalsForContact(contactId) {
      return this.getContactById(contactId)?.proposals || [];
    },
    setFilterMode(mode) {
      switch (mode) {
        case 'companies':
          this.filterMode = 'companies'
          break;
        case 'customers':
          this.filterMode = 'customers'
          break;
        case 'all':
        default:
          this.filterMode = 'all'
      }
    },
    getContactById(id) {
      return this.contacts.find(c => c.id === id);
    },
    modalEditContactShow(id) {
      if(id) {
        this.modalEditContact.show = true;
        this.modalEditContact.id = id;
        this.modalEditContact.current.name = this.getContactById(id).name;
        this.modalEditContact.current.email = this.getContactById(id).email;
        this.modalEditContact.current.phone = this.getContactById(id).phone;
        this.modalEditContact.current.phone2 = this.getContactById(id).phone2;
        this.modalEditContact.current.company = this.getContactById(id).company;
        this.modalEditContact.current.type = this.getContactById(id).type;
        this.modalEditContact.current.infoSiret = this.getContactById(id).infoSiret;
        this.modalEditContact.current.infoInsurance = this.getContactById(id).infoInsurance;
        this.modalEditContact.current.infoComment = this.getContactById(id).infoComment;
        this.modalEditContact.current.infoAddress = this.getContactById(id).infoAddress;
        this.modalEditContact.current.infoTva = this.getContactById(id).infoTva;
        this.modalEditContact.current.infoCorps = this.getContactById(id).infoCorps;
        this.modalEditContact.current.infoLine1 = this.getContactById(id).infoLine1;
        this.modalEditContact.current.infoLine2 = this.getContactById(id).infoLine2;
        this.modalEditContact.current.infoWebsite = this.getContactById(id).infoWebsite;
      }
    },
    modalEditContactCancel() {
      this.modalEditContact.show = false;
    },
    modalEditContactValidate() {
      this.modalEditContact.show = false;

      const contactId = this.modalEditContact.id;
      const newContact = {
        name: this.modalEditContact.current.name,
        email: this.modalEditContact.current.email,
        phone: this.modalEditContact.current.phone,
        phone2: this.modalEditContact.current.phone2,
        company: this.modalEditContact.current.company,
        type: this.modalEditContact.current.type,
        infoSiret: this.modalEditContact.current.infoSiret || "",
        infoInsurance: this.modalEditContact.current.infoInsurance || "",
        infoComment: this.modalEditContact.current.infoComment || "",
        infoAddress: this.modalEditContact.current.infoAddress || "",
        infoTva: this.modalEditContact.current.infoTva || "",
        infoCorps: this.modalEditContact.current.infoCorps || "",
          infoLine1: this.modalEditContact.current.infoLine1 || "",
          infoLine2: this.modalEditContact.current.infoLine2 || "",
        infoWebsite: this.modalEditContact.current.infoWebsite || "",
      }

      if (contactId) {
        this.$apollo.mutate(
            {
              mutation: MUTATION_CONTACT_UPDATE,
              variables: {
                contactId,
                newContact
              },
              update: (store, { data: { contactUpdate }  }) => {
                // Read the data from our cache for this query.
                let data = store.readQuery({query: ALL_CONTACTS_QUERY})

                // Add new contact from the mutation to cache
                data = {
                  ...data,
                  contacts: [
                    ...data.contacts.filter(c => c.id !== contactId),
                    contactUpdate.contact
                  ].sort(sortContacts),
                }

                // Write our data back to the cache.
                store.writeQuery({query: ALL_CONTACTS_QUERY, data})
              },
            }
        )
      }
    },
    modalCreateContactShow() {
      this.modalCreateContactCancel()
      this.modalCreateContact.show = true;
    },
    modalCreateContactCancel() {
      this.modalCreateContact.show = false;
      this.modalCreateContact.current.name = "";
      this.modalCreateContact.current.email = "";
      this.modalCreateContact.current.phone = "";
      this.modalCreateContact.current.phone2 = "";
      this.modalCreateContact.current.company = "";
      this.modalCreateContact.current.type = "";
      this.modalCreateContact.current.infoSiret = "";
      this.modalCreateContact.current.infoInsurance = "";
      this.modalCreateContact.current.infoComment = "";
      this.modalCreateContact.current.infoAddress = "";
      this.modalCreateContact.current.infoTva = "";
      this.modalCreateContact.current.infoCorps = "";
      this.modalCreateContact.current.infoWebsite = "";
        this.modalCreateContact.current.infoLine1 = "";
        this.modalCreateContact.current.infoLine2 = "";
    },
    modalCreateContactValidate() {
      this.modalCreateContact.show = false;

      const newContact = {
        name: this.modalCreateContact.current.name,
        email: this.modalCreateContact.current.email,
        phone: this.modalCreateContact.current.phone,
        phone2: this.modalCreateContact.current.phone2,
        company: this.modalCreateContact.current.company,
        type: this.modalCreateContact.current.type,
        infoSiret: this.modalCreateContact.current.infoSiret || "",
        infoInsurance: this.modalCreateContact.current.infoInsurance || "",
        infoComment: this.modalCreateContact.current.infoComment || "",
        infoAddress: this.modalCreateContact.current.infoAddress || "",
        infoTva: this.modalCreateContact.current.infoTva || "",
        infoCorps: this.modalCreateContact.current.infoCorps || "",
          infoWebsite: this.modalCreateContact.current.infoWebsite || "",
          infoLine1: this.modalCreateContact.current.infoLine1 || "",
          infoLine2: this.modalCreateContact.current.infoLine2 || "",
      }

      this.$apollo.mutate(
        {
          mutation: MUTATION_CONTACT_CREATE,
          variables: {
            newContact
          },
          update: (store, { data: { contactCreate }  }) => {
            // Read the data from our cache for this query.
            let data = store.readQuery({query: ALL_CONTACTS_QUERY})

            // Add new contact from the mutation to cache
            data = {
              ...data,
              contacts: [
                ...data.contacts, contactCreate.contact
              ].sort(sortContacts),
            }

            // Write our data back to the cache.
            store.writeQuery({query: ALL_CONTACTS_QUERY, data})
          },
        }
      )
    },
    modalConfirmDeleteShow(id) {
      this.modalConfirmDelete.id = id
      this.modalConfirmDelete.show = true;
    },
    modalConfirmDeleteValidate() {
      const contactId = this.modalConfirmDelete.id;

      if(contactId){
        this.$apollo.mutate(
            {
              mutation: MUTATION_CONTACT_DELETE,
              variables: {
                contactId
              },
              update: (store) => {
                // Read the data from our cache for this query.
                let data = store.readQuery({ query: ALL_CONTACTS_QUERY })

                // Add new contact from the mutation to cache
                data = {
                  ...data,
                  contacts: [
                    ...data.contacts.filter(c => c.id !== contactId)
                  ].sort(sortContacts),
                }
                // Write our data back to the cache.
                store.writeQuery({ query: ALL_CONTACTS_QUERY, data })
              },
            }
        )
      }

      this.modalConfirmDelete.id = "";
      this.modalConfirmDelete.show = false;
    },
    modalConfirmDeleteCancel() {
      this.modalConfirmDelete.show = false;
    },
  },
  computed: {
    isModalActive: function() {
      return kalmStore.state.display.profileToggled || this.modalCreateContact.show || this.modalEditContact.show
          || this.modalConfirmDelete.show || this.modalConfirmDelete.show;
    },
    contacts_count: function() {
      return this.getContactsFiltered.length;
    },
    getContactsFiltered: function() {
      let contacts = [...this.contacts].filter(c => c.type !== "INTERNAL");
      if(this.filterMode === 'customers') {
        contacts = contacts.filter(c => c.type === "CUSTOMER_PRO" || c.type === "CUSTOMER_INDIVIDUAL" );
      } else if(this.filterMode === 'companies') {
        contacts = contacts.filter(c => c.type === "COMPANY");
      }

      if (this.contact_search_filter === "") {
        return contacts.sort(sortContacts);
      }
      else {
        return contacts.filter(c =>
          (c?.name || "").toLowerCase().includes(this.contact_search_filter.toLowerCase())
          || (c?.phone || "").toLowerCase().includes(this.contact_search_filter.toLowerCase())
          || (c?.phone2 || "").toLowerCase().includes(this.contact_search_filter.toLowerCase())
          || (c?.email || "").toLowerCase().includes(this.contact_search_filter.toLowerCase())
          || (c?.company || "").toLowerCase().includes(this.contact_search_filter.toLowerCase())
          || (c?.infoCorps || "").toLowerCase().includes(this.contact_search_filter.toLowerCase())
            || (c?.infoComment || "").toLowerCase().includes(this.contact_search_filter.toLowerCase())
            || (c?.infoLine1 || "").toLowerCase().includes(this.contact_search_filter.toLowerCase())
            || (c?.infoLine2 || "").toLowerCase().includes(this.contact_search_filter.toLowerCase())
        ).sort(sortContacts)
      }
    },
  },
  data() {
    return {
      filterMode: 'all',
      modalEditContact: {
        show: false,
        id: "",
        current: {
          id: "",
          type: "",
          company: "",
          name: "",
          role: "",
          email: "",
          phone: "",
          phone2: "",
          infoWebsite: "",
          infoCorps: "",
          infoSiret: "",
          infoInsurance: "",
          infoAddress: "",
          infoTva: "",
          infoComment: "",
            infoLine1: "",
            infoLine2: "",

        },
      },
      modalCreateContact: {
        show: false,
        id: "",
        current: {
          id: "",
          type: "",
          company: "",
          name: "",
          role: "",
          email: "",
          phone: "",
          phone2: "",
          infoWebsite: "",
          infoCorps: "",
          infoSiret: "",
          infoInsurance: "",
          infoAddress: "",
          infoTva: "",
          infoComment: "",
            infoLine1: "",
            infoLine2: "",
        },
      },
      modalConfirmDelete: {
        show: false,
        id: "",
      },
      contact_modal_type_options: [
        {
          id: "CUSTOMER_INDIVIDUAL",
          title: "Client — Particulier",
          subtitle: "Un particulier, client ou prospect",
          icon: "bi-person",
          bg: "bg-light-success",
          checked: true
        },
        {
          id: "CUSTOMER_PRO",
          title: "Client — Pro",
          subtitle: "Un professionnel, client ou prospect",
          icon: "bi-person-check",
          bg: "bg-light-info"
        },
        {
          id: "COMPANY",
          title: "Entreprise",
          subtitle: "Professionnel - Prestataire ou fournisseur",
          icon: "bi-building",
          bg: "bg-light-warning"
        },
      ],
      contact_search_filter: "",
      contacts: [
        {
          id: "ct_01",
          contact_type: "client",
          company: "Kalm",
          name: "Bob - Kalm",
          role: "MOA",
          email: "bob@kalm.ai",
          phone: "+33 6 00 00 00 00",
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>