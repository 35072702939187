<template>
    <div>
        <div v-for="item in items" :key="item.id">
            <div class="mt-6 mb-4 d-flex align-items-center">
                <h2 class="me-6 cursor-pointer" :data-bs-toggle="'collapse'" :data-bs-target="'#collapse_' + item.id"
                    :aria-expanded="!isCollapsed[item.id]" :aria-controls="'collapse_' + item.id"
                    @click="toggleCollapse(item.id)">
                    <span>
                        <i v-if="!isCollapsed[item.id]" class="bi bi-caret-down-fill"></i>
                        <i v-else class="bi bi-caret-right-fill"></i>
                    </span>
                    {{ item.name }}
                </h2>
                <button class="btn btn-primary btn-sm" @click="showTaskForm(item)">+ Tâche</button>
            </div>
            <div v-for="task in item.tasks" :key="task.id" class="collapse show" :id="'collapse_' + item.id">
                <TaskCard :viewType="viewType" :task="task" @delete="deleteTask(task)" @duplicate="duplicateTask(task)" @edit="editTask(task)"></TaskCard>
            </div>
        </div>
    </div>
</template>

<script>
import TaskCard from './TaskCard.vue';
export default {
    props: {
        items: Array,
        viewType: String,
    },
    components: {
        TaskCard,
    },
    data() {
        return {
            isCollapsed: {},
        };
    },
    methods: {
        toggleCollapse(itemId) {
            this.isCollapsed = { ...this.isCollapsed, [itemId]: !this.isCollapsed[itemId] };
        },
        showTaskForm(item) {
            this.$emit("addTask", item);
        },
        deleteTask(task) {
            this.$emit('delete', task);
        },
        duplicateTask(task) {
            this.$emit('duplicate', task);
        },
        editTask(task) {
            this.$emit('edit', task);
        },
    },
};
</script>
