<template>
    <div class="row g-3 print-hidden">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between my-4">
                <h3 class="card-title align-items-start flex-column">
                    <span v-if="!assignedToCurrentUser" class="card-label fw-bolder text-dark fs-3">Todos</span>
                    <span v-if="assignedToCurrentUser" class="card-label fw-bolder text-dark fs-3">Mes Tâches</span>
                </h3>
                <div v-if="!assignedToCurrentUser" class="card-toolbar">
                    <ul class="nav nav-pills nav-pills-sm nav-light">
                        <li class="nav-item">
                            <a class="nav-link btn btn-primary fw-bolder me-2" @click="addTodo">Ajouter une Todo</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="mt-4">
                <draggable :list="getItems()" :disabled="!enabled || assignedToCurrentUser" item-key="id"
                    :component-data="{
                        tag: 'div',
                        type: 'transition-group',
                        name: !dragging ? 'flip-list' : null
                    }" @start="dragging = true" @end="dragging = false; onDropped()" v-bind="dragOptions">
                    <template #item="{ element }">
                        <div :class="{ 'not-draggable': !enabled || assignedToCurrentUser }">
                            <TodoList :todo="element" :projectId="projectId" :is-draggable="!assignedToCurrentUser"
                                :assignedToCurrentUser="assignedToCurrentUser" />
                        </div>
                    </template>
                </draggable>
            </div>
        </div>
        <TodoFormModal v-if="showTodoModal" @close="reset" :projectId="projectId" :last-order="getLastOrder()"
            :assignedToCurrentUser="assignedToCurrentUser" />
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import {
BULK_UPDATE_TODO_ORDER,
GET_TODOS
} from "../../data/todos_graphql";
import TodoFormModal from "../todo-form/TodoFormModal.vue";
import TodoList from "./components/TodoList.vue";
export default {
    name: "TodosPage",
    components: {
        TodoList,
        TodoFormModal,
        draggable,
    },
    async mounted() {
        this.projectId = this.$route.params.id;
        this.assignedToCurrentUser = this.$route.query.assignedToCurrentUser === "true";
    },
    data() {
        return {
            projectId: undefined,
            todosByProject: [],
            showTodoModal: false,
            dragging: false,
            enabled: true,
            localTodos: [],
            lastStringifiedTodos: "",
            assignedToCurrentUser: false,
        };
    },
    watch: {
        $route(to) {
            this.projectId = to.params.id;
        },
        '$route.query.assignedToCurrentUser'(newVal) {
            this.assignedToCurrentUser = newVal === "true";
        },
    },
    methods: {
        reset() {
            this.showTodoModal = false;
        },
        addTodo() {
            this.showTodoModal = true;
        },
        getLastOrder() {
            if (!this.todosByProject.length) {
                return 0;
            }
            return this.todosByProject[this.todosByProject.length - 1].order;
        },
        onDropped() {
            this.$apollo.mutate({
                mutation: BULK_UPDATE_TODO_ORDER,
                variables: {
                    todos: [...this.localTodos.map((todo, index) => (JSON.stringify({
                        id: todo.id,
                        order: index,
                    })))],
                },
            });
        },
        getItems() {
            // Stringified todos without the order and updatedAt fields
            const stringifiedTodos = JSON.stringify(this.todosByProject.map(todo => {
                // eslint-disable-next-line no-unused-vars
                const { order, updatedAt, ...rest } = todo;
                return rest;
            }));

            if (this.lastStringifiedTodos !== stringifiedTodos) {
                this.localTodos = [...this.todosByProject];
                this.lastStringifiedTodos = stringifiedTodos;
            }
            return this.localTodos
        },
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "todos",
                ghostClass: "ghost"
            };
        },
    },
    apollo: {
        todosByProject: {
            query: GET_TODOS,
            variables() {
                return {
                    projectId: this.projectId,
                    assignedToCurrentUser: this.assignedToCurrentUser
                }
            },
        }
    },
}
</script>