<template>
  <!--begin::Row-->
  <div class="row g-0 g-xl-5 g-xxl-8">
    <div class="col-xl-4">
      <ProgressCard
          card_type="warning"
          card_progress_percent="80"
          card_subtitle="SOUS-TITRE"
          card_text_line_1="Yeah 1"
          card_text_line_2="Yeah 2" :card_title="'Contact n°'+this.$route.params.id"></ProgressCard>
    </div>
  </div>
  <!--end::Row-->
  <!--begin::Row-->
  <div class="row g-0 g-xl-5 g-xxl-8">
    <div class="col-xl-4">
      <!--begin::Stats Widget 1-->
      <div class="card card-stretch mb-5 mb-xxl-8">
        <!--begin::Header-->
        <div class="card-header align-items-center border-0 mt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="fw-bolder text-dark fs-3">Sales Share</span>
            <span class="text-muted mt-2 fw-bold fs-6">890,344 Sales</span>
          </h3>
          <div class="card-toolbar">
            <!--begin::Dropdown-->
            <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
              <!--begin::Svg Icon | path: icons/duotone/Layout/Layout-4-blocks-2.svg-->
              <span class="svg-icon svg-icon-1">
															<!--suppress HtmlDeprecatedAttribute -->
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																	<rect x="5" y="5" width="5" height="5" rx="1" fill="#000000" />
																	<rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																	<rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																	<rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																</g>
															</svg>
														</span>
              <!--end::Svg Icon-->
            </button>
            <!--begin::Menu-->
            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
              <div class="menu-item px-3">
                <div class="menu-content fs-6 text-dark fw-bolder px-3 py-4">Manage</div>
              </div>
              <div class="separator mb-3 opacity-75"></div>
              <div class="menu-item px-3">
                <a href="#" class="menu-link px-3">Add User</a>
              </div>
              <div class="menu-item px-3">
                <a href="#" class="menu-link px-3">Add Role</a>
              </div>
              <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start" data-kt-menu-flip="left-start, top">
                <a href="#" class="menu-link px-3">
                  <span class="menu-title">Add Group</span>
                  <span class="menu-arrow"></span>
                </a>
                <div class="menu-sub menu-sub-dropdown w-200px py-4">
                  <div class="menu-item px-3">
                    <a href="#" class="menu-link px-3">Admin Group</a>
                  </div>
                  <div class="menu-item px-3">
                    <a href="#" class="menu-link px-3">Staff Group</a>
                  </div>
                  <div class="menu-item px-3">
                    <a href="#" class="menu-link px-3">Member Group</a>
                  </div>
                </div>
              </div>
              <div class="menu-item px-3">
                <a href="#" class="menu-link px-3">Reports</a>
              </div>
              <div class="separator mt-3 opacity-75"></div>
              <div class="menu-item px-3">
                <div class="menu-content px-3 py-3">
                  <a class="btn btn-primary fw-bold btn-sm px-4" href="#">Create New</a>
                </div>
              </div>
            </div>
            <!--end::Menu-->
            <!--end::Dropdown-->
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-12">
          <!--begin::Chart-->
          <div class="d-flex flex-center position-relative bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-center h-175px" style="background-image:url('assets/media/svg/illustrations/bg-1.svg')">
            <div class="fw-bolder fs-1 text-gray-800 position-absolute">8,345</div>
            <canvas id="kt_stats_widget_1_chart"></canvas>
          </div>
          <!--end::Chart-->
          <!--begin::Items-->
          <div class="d-flex justify-content-around pt-18">
            <!--begin::Item-->
            <div class="">
              <span class="fw-bolder text-gray-800">48% SNT</span>
              <span class="bg-info w-25px h-5px d-block rounded mt-1"></span>
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="">
              <span class="fw-bolder text-gray-800">20% REX</span>
              <span class="bg-primary w-25px h-5px d-block rounded mt-1"></span>
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="">
              <span class="fw-bolder text-gray-800">32% SAP</span>
              <span class="bg-warning w-25px h-5px d-block rounded mt-1"></span>
            </div>
            <!--end::Item-->
          </div>
          <!--end::Items-->
        </div>
        <!--end: Card Body-->
      </div>
      <!--end::Stats Widget 1-->
    </div>
    <div class="col-xl-8">
      <!--begin::Stats Widget 2-->
      <div class="card card-stretch mb-5 mb-xxl-8">
        <!--begin::Header-->
        <div class="card-header align-items-center border-0 mt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="fw-bolder text-dark fs-3">Milestones</span>
            <span class="text-muted mt-2 fw-bold fs-6">890,344 Sales</span>
          </h3>
          <div class="card-toolbar">
            <!--begin::Dropdown-->
            <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
              <!--begin::Svg Icon | path: icons/duotone/Layout/Layout-4-blocks-2.svg-->
              <span class="svg-icon svg-icon-1">
															<!--suppress HtmlDeprecatedAttribute -->
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																	<rect x="5" y="5" width="5" height="5" rx="1" fill="#000000" />
																	<rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																	<rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																	<rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																</g>
															</svg>
														</span>
              <!--end::Svg Icon-->
            </button>
            <!--begin::Form-->
            <div class="menu menu-sub menu-sub-dropdown menu-column w-300px w-lg-350px p-5" data-kt-menu="true">
              <!--begin::Input-->
              <div class="input-group input-group-solid mb-5">
                <div class="input-group-prepend">
																<span class="input-group-text">
																	<!--begin::Svg Icon | path: icons/duotone/General/Search.svg-->
																	<span class="svg-icon svg-icon-4">
																		<!--suppress HtmlDeprecatedAttribute -->
                                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																			<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																				<rect x="0" y="0" width="24" height="24" />
																				<path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
																				<path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero" />
																			</g>
																		</svg>
																	</span>
                                  <!--end::Svg Icon-->
																</span>
                </div>
                <input type="text" class="form-control ps-0" name="search" value="" placeholder="Filter reports" />
              </div>
              <!--end::Input-->
              <!--begin::Tabs-->
              <ul class="nav nav-line-tabs nav-line-tabs-2x border-light fw-bold mb-5">
                <li class="nav-item">
                  <a class="nav-link active" data-bs-toggle="tab" href="#kt_dropdown_2_tab_1">Today</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#kt_dropdown_2_tab_2">Last Week</a>
                </li>
              </ul>
              <!--end::Tabs-->
              <!--begin::Tab Content-->
              <div class="tab-content">
                <!--begin::Tab Pane-->
                <div class="tab-pane active" id="kt_dropdown_2_tab_1">
                  <ul class="menu menu-custom menu-column menu-rounded menu-title-gray-600 menu-icon-muted menu-hover-bg-light-primary menu-active-bg-light-primary fw-bold">
                    <li class="menu-item py-1">
                      <a href="#" class="menu-link px-3">
																			<span class="menu-icon">
																				<!--begin::Svg Icon | path: icons/duotone/Files/Media.svg-->
																				<span class="svg-icon svg-icon-1">
																					<!--suppress HtmlDeprecatedAttribute -->
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<polygon points="0 0 24 0 24 24 0 24" />
																							<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
																							<path d="M10.782158,15.8052934 L15.1856088,12.7952868 C15.4135806,12.6394552 15.4720618,12.3283211 15.3162302,12.1003494 C15.2814587,12.0494808 15.2375842,12.0054775 15.1868178,11.970557 L10.783367,8.94156929 C10.5558531,8.78507001 10.2445489,8.84263875 10.0880496,9.07015268 C10.0307022,9.15352258 10,9.25233045 10,9.35351969 L10,15.392514 C10,15.6686564 10.2238576,15.892514 10.5,15.892514 C10.6006894,15.892514 10.699033,15.8621141 10.782158,15.8052934 Z" fill="#000000" />
																						</g>
																					</svg>
																				</span>
                                        <!--end::Svg Icon-->
																			</span>
                        <span class="menu-title">Web &amp; App History</span>
                      </a>
                    </li>
                    <li class="menu-item py-1">
                      <a href="#" class="menu-link px-3">
																			<span class="menu-icon">
																				<!--begin::Svg Icon | path: icons/duotone/Communication/Mail-heart.svg-->
																				<span class="svg-icon svg-icon-1">
																					<!--suppress HtmlDeprecatedAttribute -->
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<path d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,13 C19,13.5522847 18.5522847,14 18,14 L6,14 C5.44771525,14 5,13.5522847 5,13 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M13.8,4 C13.1562,4 12.4033,4.72985286 12,5.2 C11.5967,4.72985286 10.8438,4 10.2,4 C9.0604,4 8.4,4.88887193 8.4,6.02016349 C8.4,7.27338783 9.6,8.6 12,10 C14.4,8.6 15.6,7.3 15.6,6.1 C15.6,4.96870845 14.9396,4 13.8,4 Z" fill="#000000" opacity="0.3" />
																						<path d="M3.79274528,6.57253826 L12,12.5 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 Z" fill="#000000" />
																					</svg>
																				</span>
                                        <!--end::Svg Icon-->
																			</span>
                        <span class="menu-title">Activity and Timeline</span>
                        <span class="menu-badge badge badge-light-danger fw-bold">new</span>
                      </a>
                    </li>
                    <li class="menu-item py-1">
                      <a href="#" class="menu-link px-3">
																			<span class="menu-icon">
																				<!--begin::Svg Icon | path: icons/duotone/Communication/Incoming-box.svg-->
																				<span class="svg-icon svg-icon-1">
																					<!--suppress HtmlDeprecatedAttribute -->
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<path d="M22,17 L22,21 C22,22.1045695 21.1045695,23 20,23 L4,23 C2.8954305,23 2,22.1045695 2,21 L2,17 L6.27924078,17 L6.82339262,18.6324555 C7.09562072,19.4491398 7.8598984,20 8.72075922,20 L15.381966,20 C16.1395101,20 16.8320364,19.5719952 17.1708204,18.8944272 L18.118034,17 L22,17 Z" fill="#000000" />
																						<path d="M2.5625,15 L5.92654389,9.01947752 C6.2807805,8.38972356 6.94714834,8 7.66969497,8 L16.330305,8 C17.0528517,8 17.7192195,8.38972356 18.0734561,9.01947752 L21.4375,15 L18.118034,15 C17.3604899,15 16.6679636,15.4280048 16.3291796,16.1055728 L15.381966,18 L8.72075922,18 L8.17660738,16.3675445 C7.90437928,15.5508602 7.1401016,15 6.27924078,15 L2.5625,15 Z" fill="#000000" opacity="0.3" />
																						<path d="M11.1288761,0.733697713 L11.1288761,2.69017121 L9.12120481,2.69017121 C8.84506244,2.69017121 8.62120481,2.91402884 8.62120481,3.19017121 L8.62120481,4.21346991 C8.62120481,4.48961229 8.84506244,4.71346991 9.12120481,4.71346991 L11.1288761,4.71346991 L11.1288761,6.66994341 C11.1288761,6.94608579 11.3527337,7.16994341 11.6288761,7.16994341 C11.7471877,7.16994341 11.8616664,7.12798964 11.951961,7.05154023 L15.4576222,4.08341738 C15.6683723,3.90498251 15.6945689,3.58948575 15.5161341,3.37873564 C15.4982803,3.35764848 15.4787093,3.33807751 15.4576222,3.32022374 L11.951961,0.352100892 C11.7412109,0.173666017 11.4257142,0.199862688 11.2472793,0.410612793 C11.1708299,0.500907473 11.1288761,0.615386087 11.1288761,0.733697713 Z" fill="#000000" fill-rule="nonzero" transform="translate(11.959697, 3.661508) rotate(-270.000000) translate(-11.959697, -3.661508)" />
																					</svg>
																				</span>
                                        <!--end::Svg Icon-->
																			</span>
                        <span class="menu-title">Business Features</span>
                      </a>
                    </li>
                    <li class="menu-item py-1">
                      <a href="#" class="menu-link px-3">
																			<span class="menu-icon">
																				<!--begin::Svg Icon | path: icons/duotone/Design/Flatten.svg-->
																				<span class="svg-icon svg-icon-1">
																					<!--suppress HtmlDeprecatedAttribute -->
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<circle fill="#000000" cx="9" cy="15" r="6" />
																						<path d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z" fill="#000000" opacity="0.3" />
																					</svg>
																				</span>
                                        <!--end::Svg Icon-->
																			</span>
                        <span class="menu-title">Accessibility Settings</span>
                      </a>
                    </li>
                    <li class="menu-item py-1">
                      <a href="#" class="menu-link px-3">
																			<span class="menu-icon">
																				<!--begin::Svg Icon | path: icons/duotone/Design/Saturation.svg-->
																				<span class="svg-icon svg-icon-1">
																					<!--suppress HtmlDeprecatedAttribute -->
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<path d="M7,14 C7,16.7614237 9.23857625,19 12,19 C14.7614237,19 17,16.7614237 17,14 C17,12.3742163 15.3702913,9.86852817 12,6.69922982 C8.62970872,9.86852817 7,12.3742163 7,14 Z M12,21 C8.13400675,21 5,17.8659932 5,14 C5,11.4226712 7.33333333,8.08933783 12,4 C16.6666667,8.08933783 19,11.4226712 19,14 C19,17.8659932 15.8659932,21 12,21 Z" fill="#000000" fill-rule="nonzero" />
																						<path d="M12,4 C16.6666667,8.08933783 19,11.4226712 19,14 C19,17.8659932 15.8659932,21 12,21 L12,4 Z" fill="#000000" />
																					</svg>
																				</span>
                                        <!--end::Svg Icon-->
																			</span>
                        <span class="menu-title">Data &amp; Personalisation</span>
                      </a>
                    </li>
                    <li class="menu-item py-1">
                      <a href="#" class="menu-link px-3">
																			<span class="menu-icon">
																				<!--begin::Svg Icon | path: icons/duotone/General/Notification2.svg-->
																				<span class="svg-icon svg-icon-1">
																					<!--suppress HtmlDeprecatedAttribute -->
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<rect x="0" y="0" width="24" height="24" />
																							<path d="M13.2070325,4 C13.0721672,4.47683179 13,4.97998812 13,5.5 C13,8.53756612 15.4624339,11 18.5,11 C19.0200119,11 19.5231682,10.9278328 20,10.7929675 L20,17 C20,18.6568542 18.6568542,20 17,20 L7,20 C5.34314575,20 4,18.6568542 4,17 L4,7 C4,5.34314575 5.34314575,4 7,4 L13.2070325,4 Z" fill="#000000" />
																							<circle fill="#000000" opacity="0.3" cx="18.5" cy="5.5" r="2.5" />
																						</g>
																					</svg>
																				</span>
                                        <!--end::Svg Icon-->
																			</span>
                        <span class="menu-title">General Preference</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <!--end::Tab Pane-->
                <!--begin::Tab Pane-->
                <div class="tab-pane" id="kt_dropdown_2_tab_2">
                  <ul class="menu menu-custom menu-column menu-rounded menu-title-gray-600 menu-icon-muted menu-hover-bg-light-primary menu-active-bg-light-primary fw-bold">
                    <li class="menu-item py-1">
                      <a href="#" class="menu-link active px-3">
																			<span class="menu-icon">
																				<!--begin::Svg Icon | path: icons/duotone/Communication/Mail-heart.svg-->
																				<span class="svg-icon svg-icon-1">
																					<!--suppress HtmlDeprecatedAttribute -->
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<path d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,13 C19,13.5522847 18.5522847,14 18,14 L6,14 C5.44771525,14 5,13.5522847 5,13 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M13.8,4 C13.1562,4 12.4033,4.72985286 12,5.2 C11.5967,4.72985286 10.8438,4 10.2,4 C9.0604,4 8.4,4.88887193 8.4,6.02016349 C8.4,7.27338783 9.6,8.6 12,10 C14.4,8.6 15.6,7.3 15.6,6.1 C15.6,4.96870845 14.9396,4 13.8,4 Z" fill="#000000" opacity="0.3" />
																						<path d="M3.79274528,6.57253826 L12,12.5 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 Z" fill="#000000" />
																					</svg>
																				</span>
                                        <!--end::Svg Icon-->
																			</span>
                        <span class="menu-title">Activity and Timeline</span>
                        <span class="menu-badge badge badge-danger fw-bold">new</span>
                      </a>
                    </li>
                    <li class="menu-item py-1">
                      <a href="#" class="menu-link px-3">
																			<span class="menu-icon">
																				<!--begin::Svg Icon | path: icons/duotone/Communication/Incoming-box.svg-->
																				<span class="svg-icon svg-icon-1">
																					<!--suppress HtmlDeprecatedAttribute -->
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<path d="M22,17 L22,21 C22,22.1045695 21.1045695,23 20,23 L4,23 C2.8954305,23 2,22.1045695 2,21 L2,17 L6.27924078,17 L6.82339262,18.6324555 C7.09562072,19.4491398 7.8598984,20 8.72075922,20 L15.381966,20 C16.1395101,20 16.8320364,19.5719952 17.1708204,18.8944272 L18.118034,17 L22,17 Z" fill="#000000" />
																						<path d="M2.5625,15 L5.92654389,9.01947752 C6.2807805,8.38972356 6.94714834,8 7.66969497,8 L16.330305,8 C17.0528517,8 17.7192195,8.38972356 18.0734561,9.01947752 L21.4375,15 L18.118034,15 C17.3604899,15 16.6679636,15.4280048 16.3291796,16.1055728 L15.381966,18 L8.72075922,18 L8.17660738,16.3675445 C7.90437928,15.5508602 7.1401016,15 6.27924078,15 L2.5625,15 Z" fill="#000000" opacity="0.3" />
																						<path d="M11.1288761,0.733697713 L11.1288761,2.69017121 L9.12120481,2.69017121 C8.84506244,2.69017121 8.62120481,2.91402884 8.62120481,3.19017121 L8.62120481,4.21346991 C8.62120481,4.48961229 8.84506244,4.71346991 9.12120481,4.71346991 L11.1288761,4.71346991 L11.1288761,6.66994341 C11.1288761,6.94608579 11.3527337,7.16994341 11.6288761,7.16994341 C11.7471877,7.16994341 11.8616664,7.12798964 11.951961,7.05154023 L15.4576222,4.08341738 C15.6683723,3.90498251 15.6945689,3.58948575 15.5161341,3.37873564 C15.4982803,3.35764848 15.4787093,3.33807751 15.4576222,3.32022374 L11.951961,0.352100892 C11.7412109,0.173666017 11.4257142,0.199862688 11.2472793,0.410612793 C11.1708299,0.500907473 11.1288761,0.615386087 11.1288761,0.733697713 Z" fill="#000000" fill-rule="nonzero" transform="translate(11.959697, 3.661508) rotate(-270.000000) translate(-11.959697, -3.661508)" />
																					</svg>
																				</span>
                                        <!--end::Svg Icon-->
																			</span>
                        <span class="menu-title">Business Features</span>
                      </a>
                    </li>
                    <li class="menu-item py-1">
                      <a href="#" class="menu-link px-3">
																			<span class="menu-icon">
																				<!--begin::Svg Icon | path: icons/duotone/Design/Flatten.svg-->
																				<span class="svg-icon svg-icon-1">
																					<!--suppress HtmlDeprecatedAttribute -->
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<circle fill="#000000" cx="9" cy="15" r="6" />
																						<path d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z" fill="#000000" opacity="0.3" />
																					</svg>
																				</span>
                                        <!--end::Svg Icon-->
																			</span>
                        <span class="menu-title">Accessibility Settings</span>
                      </a>
                    </li>
                    <li class="menu-item py-1">
                      <a href="#" class="menu-link px-3">
																			<span class="menu-icon">
																				<!--begin::Svg Icon | path: icons/duotone/Design/Saturation.svg-->
																				<span class="svg-icon svg-icon-1">
																					<!--suppress HtmlDeprecatedAttribute -->
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<path d="M7,14 C7,16.7614237 9.23857625,19 12,19 C14.7614237,19 17,16.7614237 17,14 C17,12.3742163 15.3702913,9.86852817 12,6.69922982 C8.62970872,9.86852817 7,12.3742163 7,14 Z M12,21 C8.13400675,21 5,17.8659932 5,14 C5,11.4226712 7.33333333,8.08933783 12,4 C16.6666667,8.08933783 19,11.4226712 19,14 C19,17.8659932 15.8659932,21 12,21 Z" fill="#000000" fill-rule="nonzero" />
																						<path d="M12,4 C16.6666667,8.08933783 19,11.4226712 19,14 C19,17.8659932 15.8659932,21 12,21 L12,4 Z" fill="#000000" />
																					</svg>
																				</span>
                                        <!--end::Svg Icon-->
																			</span>
                        <span class="menu-title">Data &amp; Personalisation</span>
                      </a>
                    </li>
                    <li class="menu-item py-1">
                      <a href="#" class="menu-link px-3">
																			<span class="menu-icon">
																				<!--begin::Svg Icon | path: icons/duotone/General/Notification2.svg-->
																				<span class="svg-icon svg-icon-1">
																					<!--suppress HtmlDeprecatedAttribute -->
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<rect x="0" y="0" width="24" height="24" />
																							<path d="M13.2070325,4 C13.0721672,4.47683179 13,4.97998812 13,5.5 C13,8.53756612 15.4624339,11 18.5,11 C19.0200119,11 19.5231682,10.9278328 20,10.7929675 L20,17 C20,18.6568542 18.6568542,20 17,20 L7,20 C5.34314575,20 4,18.6568542 4,17 L4,7 C4,5.34314575 5.34314575,4 7,4 L13.2070325,4 Z" fill="#000000" />
																							<circle fill="#000000" opacity="0.3" cx="18.5" cy="5.5" r="2.5" />
																						</g>
																					</svg>
																				</span>
                                        <!--end::Svg Icon-->
																			</span>
                        <span class="menu-title">General Preference</span>
                      </a>
                    </li>
                    <li class="menu-item py-1">
                      <a href="#" class="menu-link px-3">
																			<span class="menu-icon">
																				<!--begin::Svg Icon | path: icons/duotone/Files/Media.svg-->
																				<span class="svg-icon svg-icon-1">
																					<!--suppress HtmlDeprecatedAttribute -->
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<polygon points="0 0 24 0 24 24 0 24" />
																							<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
																							<path d="M10.782158,15.8052934 L15.1856088,12.7952868 C15.4135806,12.6394552 15.4720618,12.3283211 15.3162302,12.1003494 C15.2814587,12.0494808 15.2375842,12.0054775 15.1868178,11.970557 L10.783367,8.94156929 C10.5558531,8.78507001 10.2445489,8.84263875 10.0880496,9.07015268 C10.0307022,9.15352258 10,9.25233045 10,9.35351969 L10,15.392514 C10,15.6686564 10.2238576,15.892514 10.5,15.892514 C10.6006894,15.892514 10.699033,15.8621141 10.782158,15.8052934 Z" fill="#000000" />
																						</g>
																					</svg>
																				</span>
                                        <!--end::Svg Icon-->
																			</span>
                        <span class="menu-title">Web &amp; App History</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <!--end::Tab Pane-->
              </div>
              <!--end::Tab Content-->
            </div>
            <!--end::Form-->
            <!--end::Dropdown-->
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-0">
          <div class="d-flex flex-wrap flex-xxl-nowrap justify-content-center justify-content-md-start pt-4">
            <!--begin::Nav-->
            <div class="me-sm-10 me-0">
              <ul class="nav flex-column nav-pills nav-pills-custom">
                <li class="nav-item mb-3">
                  <a class="nav-link active w-225px h-70px" data-bs-toggle="pill" id="kt_stats_widget_2_tab_1" href="#kt_stats_widget_2_tab_1_content">
                    <div class="nav-icon me-3">
                      <img alt="" src="/assets/media/svg/logo/gray/aven.svg" class="default" />
                      <img alt="" src="/assets/media/svg/logo/colored/aven.svg" class="active" />
                    </div>
                    <div class="ps-1">
                      <span class="nav-text text-gray-600 fw-bolder fs-6">Man&amp;Flower SaaS</span>
                      <span class="text-muted fw-bold d-block pt-1">HR Solutions</span>
                    </div>
                  </a>
                </li>
                <li class="nav-item mb-3">
                  <a class="nav-link w-225px h-70px" data-bs-toggle="pill" id="kt_stats_widget_2_tab_2" href="#kt_stats_widget_2_tab_2_content">
                    <div class="nav-icon me-3">
                      <img alt="" src="/assets/media/svg/logo/gray/tower.svg" class="default" />
                      <img alt="" src="/assets/media/svg/logo/colored/tower.svg" class="active" />
                    </div>
                    <div class="ps-1">
                      <span class="nav-text text-gray-600 fw-bolder fs-6">Building Studio</span>
                      <span class="text-muted fw-bold d-block pt-1">HR Solutions</span>
                    </div>
                  </a>
                </li>
                <li class="nav-item mb-3">
                  <a class="nav-link w-225px h-70px" data-bs-toggle="pill" id="kt_stats_widget_2_tab_3" href="#kt_stats_widget_2_tab_3_content">
                    <div class="nav-icon me-3">
                      <img alt="" src="/assets/media/svg/logo/gray/fox-hub-2.svg" class="default" />
                      <img alt="" src="/assets/media/svg/logo/colored/fox-hub-2.svg" class="active" />
                    </div>
                    <div class="ps-1">
                      <span class="nav-text text-gray-600 fw-bolder fs-6">Foxy Solutions</span>
                      <span class="text-muted fw-bold d-block pt-1">HR Solutions</span>
                    </div>
                  </a>
                </li>
                <li class="nav-item mb-5">
                  <a class="nav-link w-225px h-70px" data-bs-toggle="pill" id="kt_stats_widget_2_tab_4" href="#kt_stats_widget_2_tab_4_content">
                    <div class="nav-icon me-3">
                      <img alt="" src="/assets/media/svg/logo/gray/kanba.svg" class="default" />
                      <img alt="" src="/assets/media/svg/logo/colored/kanba.svg" class="active" />
                    </div>
                    <div class="ps-1">
                      <span class="nav-text text-gray-600 fw-bolder fs-6">MyStreams</span>
                      <span class="text-muted fw-bold d-block pt-1">HR Solutions</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <!--end::Nav-->
            <!--begin::Tab Content-->
            <div class="tab-content flex-grow-1">
              <!--begin::Tab Pane 1-->
              <div class="tab-pane fade show active" id="kt_stats_widget_2_tab_1_content">
                <!--begin::Content-->
                <div class="d-flex justify-content-center mb-10">
                  <!--begin::Item-->
                  <div class="px-10">
                    <span class="text-muted fw-bold fs-7">Sale</span>
                    <span class="text-gray-800 fw-bolder fs-3 d-block">$650</span>
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="px-10">
                    <span class="text-muted fw-bold fs-7">Commission</span>
                    <span class="text-gray-800 fw-bolder fs-3 d-block">$2,040</span>
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="px-10">
                    <span class="text-muted fw-bold fs-7">Refers</span>
                    <span class="text-gray-800 fw-bolder fs-3 d-block">8,926</span>
                  </div>
                  <!--end::Item-->
                </div>
                <!--end::Content-->
                <!--begin::Chart-->
                <div id="kt_stats_widget_2_chart_1" style="height: 250px"></div>
                <!--end::Chart-->
              </div>
              <!--end::Tab Pane 1-->
              <!--begin::Tab Pane 2-->
              <div class="tab-pane fade" id="kt_stats_widget_2_tab_2_content">
                <!--begin::Content-->
                <div class="d-flex justify-content-center mb-10">
                  <!--begin::Item-->
                  <div class="px-10">
                    <span class="text-muted fw-bold fs-7">Sale</span>
                    <span class="text-gray-800 fw-bolder fs-3 d-block">$1250</span>
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="px-10">
                    <span class="text-muted fw-bold fs-7">Commission</span>
                    <span class="text-gray-800 fw-bolder fs-3 d-block">$5,000</span>
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="px-10">
                    <span class="text-muted fw-bold fs-7">Refers</span>
                    <span class="text-gray-800 fw-bolder fs-3 d-block">4,926</span>
                  </div>
                  <!--end::Item-->
                </div>
                <!--end::Content-->
                <!--begin::Chart-->
                <div id="kt_stats_widget_2_chart_2" style="height: 250px"></div>
                <!--end::Chart-->
              </div>
              <!--end::Tab Pane 2-->
              <!--begin::Tab Pane 3-->
              <div class="tab-pane fade" id="kt_stats_widget_2_tab_3_content">
                <!--begin::Content-->
                <div class="d-flex justify-content-center mb-10">
                  <!--begin::Item-->
                  <div class="px-10">
                    <span class="text-muted fw-bold fs-7">Sale</span>
                    <span class="text-gray-800 fw-bolder fs-3 d-block">$350</span>
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="px-10">
                    <span class="text-muted fw-bold fs-7">Comission</span>
                    <span class="text-gray-800 fw-bolder fs-3 d-block">$1,200</span>
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="px-10">
                    <span class="text-muted fw-bold fs-7">Refers</span>
                    <span class="text-gray-800 fw-bolder fs-3 d-block">5,500</span>
                  </div>
                  <!--end::Item-->
                </div>
                <!--end::Content-->
                <!--begin::Chart-->
                <div id="kt_stats_widget_2_chart_3" style="height: 250px"></div>
                <!--end::Chart-->
              </div>
              <!--end::Tab Pane 3-->
              <!--begin::Tab Pane 4-->
              <div class="tab-pane fade" id="kt_stats_widget_2_tab_4_content">
                <!--begin::Content-->
                <div class="d-flex justify-content-center mb-10">
                  <!--begin::Item-->
                  <div class="px-10">
                    <span class="text-muted fw-bold fs-7">Sale</span>
                    <span class="text-gray-800 fw-bolder fs-3 d-block">$450</span>
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="px-10">
                    <span class="text-muted fw-bold fs-7">Comission</span>
                    <span class="text-gray-800 fw-bolder fs-3 d-block">$6,500</span>
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="px-10">
                    <span class="text-muted fw-bold fs-7">Refers</span>
                    <span class="text-gray-800 fw-bolder fs-3 d-block">500</span>
                  </div>
                  <!--end::Item-->
                </div>
                <!--end::Content-->
                <!--begin::Chart-->
                <div id="kt_stats_widget_2_chart_4" style="height: 250px"></div>
                <!--end::Chart-->
              </div>
              <!--end::Tab Pane 4-->
            </div>
            <!--end::Tab Content-->
          </div>
        </div>
        <!--end: Card Body-->
      </div>
      <!--end::Stats Widget 2-->
    </div>
  </div>
  <!--end::Row-->
  <!--begin::Row-->
  <div class="row g-0 g-xl-5 g-xxl-8">
    <div class="col-xxl-4">
      <!--begin::Stats Widget 6-->
      <div class="card card-stretch mb-5 mb-xxl-8">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Top-->
          <div class="d-flex bg-light-primary card-rounded flex-grow-1">
            <!--begin::Section-->
            <div class="py-10 ps-7">
              <!--begin::Text-->
              <div class="">
                <span class="text-primary d-block mb-n1">Your Storage</span>
                <span class="font-weight-light fs-1 text-gray-800">GB
															<span class="fw-bolder fs-1 text-gray-800">7,562.00</span></span>
              </div>
              <!--end::Text-->
              <a href="#" class="btn btn-primary btn-sm fw-bolder fs-6 ps-4 mt-6" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app">Boost
                <!--begin::Svg Icon | path: icons/duotone/Navigation/Up-right.svg-->
                <span class="svg-icon svg-icon-3 me-0">
															<!--suppress HtmlDeprecatedAttribute -->
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																	<polygon points="0 0 24 0 24 24 0 24" />
																	<rect fill="#000000" opacity="0.5" transform="translate(11.646447, 12.853553) rotate(-315.000000) translate(-11.646447, -12.853553)" x="10.6464466" y="5.85355339" width="2" height="14" rx="1" />
																	<path d="M8.1109127,8.90380592 C7.55862795,8.90380592 7.1109127,8.45609067 7.1109127,7.90380592 C7.1109127,7.35152117 7.55862795,6.90380592 8.1109127,6.90380592 L16.5961941,6.90380592 C17.1315855,6.90380592 17.5719943,7.32548256 17.5952502,7.8603687 L17.9488036,15.9920967 C17.9727933,16.5438602 17.5449482,17.0106003 16.9931847,17.0345901 C16.4414212,17.0585798 15.974681,16.6307346 15.9506913,16.0789711 L15.6387276,8.90380592 L8.1109127,8.90380592 Z" fill="#000000" fill-rule="nonzero" />
																</g>
															</svg>
														</span>
                <!--end::Svg Icon--></a>
            </div>
            <!--end::Section-->
            <!--begin::Pic-->
            <div class="position-relative bgi-no-repeat bgi-size-contain bgi-position-y-bottom bgi-position-x-end mt-6 flex-grow-1" style="background-image:url('assets/media/misc/illustration-1.png')"></div>
            <!--end::Pic-->
          </div>
          <!--end::Top-->
          <!--begin::Stats-->
          <div class="pt-9">
            <!--begin::Row-->
            <div class="row">
              <div class="col me-n2 mb-5">
                <button type="button" class="btn btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active border-primary px-6 py-7 text-start w-100 min-w-150px">
                  <!--begin::Svg Icon | path: icons/duotone/Files/Media.svg-->
                  <span class="svg-icon svg-icon-2x ms-n1">
																	<!--suppress HtmlDeprecatedAttribute -->
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
																			<path d="M10.782158,15.8052934 L15.1856088,12.7952868 C15.4135806,12.6394552 15.4720618,12.3283211 15.3162302,12.1003494 C15.2814587,12.0494808 15.2375842,12.0054775 15.1868178,11.970557 L10.783367,8.94156929 C10.5558531,8.78507001 10.2445489,8.84263875 10.0880496,9.07015268 C10.0307022,9.15352258 10,9.25233045 10,9.35351969 L10,15.392514 C10,15.6686564 10.2238576,15.892514 10.5,15.892514 C10.6006894,15.892514 10.699033,15.8621141 10.782158,15.8052934 Z" fill="#000000" />
																		</g>
																	</svg>
																</span>
                  <!--end::Svg Icon-->
                  <span class="text-gray-800 fw-bolder fs-6 d-block pt-6">Upload File</span>
                </button>
              </div>
              <div class="col mb-5">
                <button type="button" class="btn btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active px-6 py-7 text-start w-100 min-w-150px">
                  <!--begin::Svg Icon | path: icons/duotone/General/Clipboard.svg-->
                  <span class="svg-icon svg-icon-2x ms-n1">
																	<!--suppress HtmlDeprecatedAttribute -->
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<rect x="0" y="0" width="24" height="24" />
																			<path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3" />
																			<path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000" />
																			<rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1" />
																			<rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1" />
																		</g>
																	</svg>
																</span>
                  <!--end::Svg Icon-->
                  <span class="text-gray-800 fw-bolder fs-6 d-block pt-6">Read Docs</span>
                </button>
              </div>
            </div>
            <!--end::Row-->
            <!--begin::Row-->
            <div class="row">
              <div class="col me-n2 mb-5">
                <button type="button" class="btn btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active px-6 py-7 text-start w-100 min-w-150px">
                  <!--begin::Svg Icon | path: icons/duotone/Files/File-plus.svg-->
                  <span class="svg-icon svg-icon-2x ms-n1">
																	<!--suppress HtmlDeprecatedAttribute -->
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
																			<path d="M11,14 L9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,12 L11,12 L11,10 C11,9.44771525 11.4477153,9 12,9 C12.5522847,9 13,9.44771525 13,10 L13,12 L15,12 C15.5522847,12 16,12.4477153 16,13 C16,13.5522847 15.5522847,14 15,14 L13,14 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,14 Z" fill="#000000" />
																		</g>
																	</svg>
																</span>
                  <!--end::Svg Icon-->
                  <span class="text-gray-800 fw-bolder fs-6 d-block pt-6">MRC 22 XML</span>
                </button>
              </div>
              <div class="col mb-5">
                <button type="button" class="btn btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active px-6 py-7 text-start w-100 min-w-150px">
                  <!--begin::Svg Icon | path: icons/duotone/General/Clipboard.svg-->
                  <span class="svg-icon svg-icon-2x ms-n1">
																	<!--suppress HtmlDeprecatedAttribute -->
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<rect x="0" y="0" width="24" height="24" />
																			<path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3" />
																			<path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000" />
																			<rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1" />
																			<rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1" />
																		</g>
																	</svg>
																</span>
                  <!--end::Svg Icon-->
                  <span class="text-gray-800 fw-bolder fs-6 d-block pt-6">CTI Tech Specs</span>
                </button>
              </div>
            </div>
            <!--end::Row-->
            <!--begin::Row-->
            <div class="row">
              <div class="col mb-5 me-n2">
                <button type="button" class="btn btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active px-6 py-7 text-start w-100 min-w-150px">
                  <!--begin::Svg Icon | path: icons/duotone/Files/Compiled-file.svg-->
                  <span class="svg-icon svg-icon-2x ms-n1">
																	<!--suppress HtmlDeprecatedAttribute -->
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
																			<rect fill="#000000" opacity="0.3" transform="translate(8.984240, 12.127098) rotate(-45.000000) translate(-8.984240, -12.127098)" x="7.41281179" y="10.5556689" width="3.14285714" height="3.14285714" rx="0.75" />
																			<rect fill="#000000" opacity="0.3" transform="translate(15.269955, 12.127098) rotate(-45.000000) translate(-15.269955, -12.127098)" x="13.6985261" y="10.5556689" width="3.14285714" height="3.14285714" rx="0.75" />
																			<rect fill="#000000" transform="translate(12.127098, 15.269955) rotate(-45.000000) translate(-12.127098, -15.269955)" x="10.5556689" y="13.6985261" width="3.14285714" height="3.14285714" rx="0.75" />
																			<rect fill="#000000" transform="translate(12.127098, 8.984240) rotate(-45.000000) translate(-12.127098, -8.984240)" x="10.5556689" y="7.41281179" width="3.14285714" height="3.14285714" rx="0.75" />
																		</g>
																	</svg>
																</span>
                  <!--end::Svg Icon-->
                  <span class="text-gray-800 fw-bolder fs-6 d-block pt-6">Check List</span>
                </button>
              </div>
              <div class="col mb-5">
                <button type="button" class="btn btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active px-6 py-7 text-start w-100 min-w-150px">
                  <!--begin::Svg Icon | path: icons/duotone/Files/File-cloud.svg-->
                  <span class="svg-icon svg-icon-2x ms-n1">
																	<!--suppress HtmlDeprecatedAttribute -->
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
																			<path d="M8.63657261,15.4632487 C7.65328954,14.8436137 7,13.7480988 7,12.5 C7,10.5670034 8.56700338,9 10.5,9 C12.263236,9 13.7219407,10.3038529 13.9645556,12 L15,12 C16.1045695,12 17,12.8954305 17,14 C17,15.1045695 16.1045695,16 15,16 L10,16 C9.47310652,16 8.99380073,15.7962529 8.63657261,15.4632487 Z" fill="#000000" />
																		</g>
																	</svg>
																</span>
                  <!--end::Svg Icon-->
                  <span class="text-gray-800 fw-bolder fs-6 d-block pt-6">Reports</span>
                </button>
              </div>
            </div>
            <!--end::Row-->
          </div>
          <!--end::Stats-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Stats Widget 6-->
    </div>
    <div class="col-xxl-8 gy-0 gy-xxl-8">
      <!--begin::Stats Widget 7-->
      <div class="card card-stretch mb-5 mb-xxl-8">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Title-->
          <div class="mb-10">
            <h3 class="fw-bold text-dark mb-5 fs-1">What A Pretty Form</h3>
            <span class="fw-bold fs-4 text-muted">This information will help us tailor your experience.</span>
          </div>
          <!--end::Title-->
          <!--begin::Section-->
          <div class="mb-10">
            <h3 class="fw-bold fs-3 text-dark mb-5">Select A Category</h3>
            <!--begin::Row-->
            <div class="row" data-kt-buttons="true">
              <input type="radio" class="btn-check" name="form-options" checked="checked" value="1" id="kt_form_options_1" />
              <label class="col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active active py-5 px-4 m-2 min-w-125px" for="kt_form_options_1">
                <!--begin::Svg Icon | path: icons/duotone/Files/Media.svg-->
                <span class="svg-icon svg-icon-2x me-1">
																<!--suppress HtmlDeprecatedAttribute -->
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																	<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																		<polygon points="0 0 24 0 24 24 0 24" />
																		<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
																		<path d="M10.782158,15.8052934 L15.1856088,12.7952868 C15.4135806,12.6394552 15.4720618,12.3283211 15.3162302,12.1003494 C15.2814587,12.0494808 15.2375842,12.0054775 15.1868178,11.970557 L10.783367,8.94156929 C10.5558531,8.78507001 10.2445489,8.84263875 10.0880496,9.07015268 C10.0307022,9.15352258 10,9.25233045 10,9.35351969 L10,15.392514 C10,15.6686564 10.2238576,15.892514 10.5,15.892514 C10.6006894,15.892514 10.699033,15.8621141 10.782158,15.8052934 Z" fill="#000000" />
																	</g>
																</svg>
															</span>
                <!--end::Svg Icon-->
                <span class="text-gray-800 fw-bold">Movie Creator</span>
              </label>
              <input type="radio" class="btn-check" name="form-options" value="2" id="kt_form_options_2" />
              <label class="col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-5 px-4 m-2 min-w-125px" for="kt_form_options_2">
                <!--begin::Svg Icon | path: icons/duotone/Devices/LTE2.svg-->
                <span class="svg-icon svg-icon-2x me-1">
																<!--suppress HtmlDeprecatedAttribute -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																	<path d="M16.4508979,17.4029496 L15.1784978,15.8599014 C16.324501,14.9149052 17,13.5137472 17,12 C17,10.4912085 16.3289582,9.09418404 15.1893841,8.14910121 L16.466112,6.60963188 C18.0590936,7.93073905 19,9.88958759 19,12 C19,14.1173586 18.0528606,16.0819686 16.4508979,17.4029496 Z M19.0211112,20.4681628 L17.7438102,18.929169 C19.7927036,17.2286725 21,14.7140097 21,12 C21,9.28974232 19.7960666,6.77820732 17.7520315,5.07766256 L19.031149,3.54017812 C21.5271817,5.61676443 23,8.68922234 23,12 C23,15.3153667 21.523074,18.3916375 19.0211112,20.4681628 Z M7.54910207,17.4029496 C5.94713944,16.0819686 5,14.1173586 5,12 C5,9.88958759 5.94090645,7.93073905 7.53388797,6.60963188 L8.81061588,8.14910121 C7.67104182,9.09418404 7,10.4912085 7,12 C7,13.5137472 7.67549895,14.9149052 8.82150222,15.8599014 L7.54910207,17.4029496 Z M4.9788888,20.4681628 C2.47692603,18.3916375 1,15.3153667 1,12 C1,8.68922234 2.47281829,5.61676443 4.96885102,3.54017812 L6.24796852,5.07766256 C4.20393339,6.77820732 3,9.28974232 3,12 C3,14.7140097 4.20729644,17.2286725 6.25618985,18.929169 L4.9788888,20.4681628 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
																	<path d="M11,14.2919782 C10.1170476,13.9061998 9.5,13.0251595 9.5,12 C9.5,10.6192881 10.6192881,9.5 12,9.5 C13.3807119,9.5 14.5,10.6192881 14.5,12 C14.5,13.0251595 13.8829524,13.9061998 13,14.2919782 L13,20 C13,20.5522847 12.5522847,21 12,21 C11.4477153,21 11,20.5522847 11,20 L11,14.2919782 Z" fill="#000000" />
																</svg>
															</span>
                <!--end::Svg Icon-->
                <span class="text-gray-800 fw-bold">Networking</span>
              </label>
              <input type="radio" class="btn-check" name="form-options" value="3" id="kt_form_options_3" />
              <label class="col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-5 px-4 m-2 min-w-125px" for="kt_form_options_3">
                <!--begin::Svg Icon | path: icons/duotone/Shopping/Cart3.svg-->
                <span class="svg-icon svg-icon-2x me-1">
																<!--suppress HtmlDeprecatedAttribute -->
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																	<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																		<rect x="0" y="0" width="24" height="24" />
																		<path d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
																		<path d="M3.5,9 L20.5,9 C21.0522847,9 21.5,9.44771525 21.5,10 C21.5,10.132026 21.4738562,10.2627452 21.4230769,10.3846154 L17.7692308,19.1538462 C17.3034221,20.271787 16.2111026,21 15,21 L9,21 C7.78889745,21 6.6965779,20.271787 6.23076923,19.1538462 L2.57692308,10.3846154 C2.36450587,9.87481408 2.60558331,9.28934029 3.11538462,9.07692308 C3.23725479,9.02614384 3.36797398,9 3.5,9 Z M12,17 C13.1045695,17 14,16.1045695 14,15 C14,13.8954305 13.1045695,13 12,13 C10.8954305,13 10,13.8954305 10,15 C10,16.1045695 10.8954305,17 12,17 Z" fill="#000000" />
																	</g>
																</svg>
															</span>
                <!--end::Svg Icon-->
                <span class="text-gray-800 fw-bold">eCommerce</span>
              </label>
              <input type="radio" class="btn-check" name="form-options" value="4" id="kt_form_options_4" />
              <label class="col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-5 px-4 m-2 min-w-125px" for="kt_form_options_4">
                <!--begin::Svg Icon | path: icons/duotone/Media/Equalizer.svg-->
                <span class="svg-icon svg-icon-2x me-1">
																<!--suppress HtmlDeprecatedAttribute -->
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																	<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																		<rect x="0" y="0" width="24" height="24" />
																		<rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5" />
																		<rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5" />
																		<rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5" />
																		<rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5" />
																	</g>
																</svg>
															</span>
                <!--end::Svg Icon-->
                <span class="text-gray-800 fw-bold">Data Analysis</span>
              </label>
            </div>
            <!--end::Row-->
          </div>
          <!--end::Section-->
          <!--begin::Form Group-->
          <div class="mb-10">
            <div class="d-flex justify-content-between mb-5">
              <label class="fw-bold fs-3 text-dark">Select An Option</label>
              <span class="fw-bold fs-6 text-muted">Optional</span>
            </div>
            <select class="form-select form-select-lg form-select-solid fw-bold fs-6 text-gray-600 h-60px" data-control="select2" data-placeholder="Choose one" data-hide-search="true">
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
          <!--end::Form Group-->
          <!--begin::Section-->
          <div class="mb-10">
            <h3 class="fw-bold fs-3 text-dark mb-5">How Big Is Your Fleet?</h3>
            <!--begin::Row-->
            <div class="row" data-kt-buttons="true">
              <input type="radio" class="btn-check" name="form-fleet" value="1" id="kt_form_fleet_1" />
              <label class="col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-5 px-4 m-2 min-w-90px" for="kt_form_fleet_1">
                <span class="text-gray-800 fw-bold">1-10</span>
              </label>
              <input type="radio" class="btn-check" name="form-fleet" checked="checked" value="2" id="kt_form_fleet_2" />
              <label class="col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active active py-5 px-4 m-2 min-w-90px" for="kt_form_fleet_2">
                <span class="text-gray-800 fw-bold">10-50</span>
              </label>
              <input type="radio" class="btn-check" name="form-fleet" value="3" id="kt_form_fleet_3" />
              <label class="col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-5 px-4 m-2 min-w-90px" for="kt_form_fleet_3">
                <span class="text-gray-800 fw-bold">50-100</span>
              </label>
              <input type="radio" class="btn-check" name="form-fleet" value="4" id="kt_form_fleet_4" />
              <label class="col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-5 px-4 m-2 min-w-90px" for="kt_form_fleet_4">
                <span class="text-gray-800 fw-bold">100-500</span>
              </label>
              <input type="radio" class="btn-check" name="form-fleet" value="5" id="kt_form_fleet_5" />
              <label class="col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-5 px-4 m-2 min-w-90px" for="kt_form_fleet_5">
                <span class="text-gray-800 fw-bold">500+</span>
              </label>
            </div>
            <!--end::Row-->
          </div>
          <!--end::Section-->
          <div class="mb-10">
            <a href="#" class="btn btn-primary px-11 py-4 fw-bolder fs-3">Create Fleet</a>
          </div>
        </div>
        <!--end: Card Body-->
      </div>
      <!--end::Stats Widget 7-->
    </div>
  </div>
  <!--end::Row-->
  <!--begin::Row-->
  <div class="row g-0 g-xl-5 g-xxl-8">
    <div class="col-xl-4">
      <!--begin::Stats Widget 8-->
      <div class="card card-stretch-50 mb-5 mb-xxl-8">
        <!--begin::Body-->
        <div class="card-body">
          <div id="kt_stats_widget_8_carousel" class="carousel carousel-custom slide" data-bs-ride="carousel" data-bs-interval="8000">
            <!--begin::Heading-->
            <div class="d-flex flex-stack flex-wrap">
              <!--begin::Label-->
              <span class="fs-6 text-muted fw-bolder pe-2">Projects</span>
              <!--end::Label-->
              <!--begin::Carousel Indicators-->
              <ol class="p-0 m-0 carousel-indicators carousel-indicators-dots">
                <li data-bs-target="#kt_stats_widget_8_carousel" data-bs-slide-to="0" class="ms-1 active"></li>
                <li data-bs-target="#kt_stats_widget_8_carousel" data-bs-slide-to="1" class="ms-1"></li>
                <li data-bs-target="#kt_stats_widget_8_carousel" data-bs-slide-to="2" class="ms-1"></li>
              </ol>
              <!--end::Carousel Indicators-->
            </div>
            <!--end::Heading-->
            <!--begin::Carousel-->
            <div class="carousel-inner pt-8">
              <!--begin::Item-->
              <div class="carousel-item active">
                <!--begin::Section-->
                <div class="d-flex flex-column justify-content-between h-100">
                  <!--begin::Title-->
                  <h3 class="fs-3 text-gray-800 text-hover-primary fw-bolder cursor-pointer">Admin Launch Day</h3>
                  <!--end::Title-->
                  <!--begin::Text-->
                  <p class="text-gray-600 fw-bold pt-3 mb-0">To start a blog, think of a topic about and first brainstorm ways to write details</p>
                  <!--end::Text-->
                </div>
                <!--end::Section-->
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="carousel-item">
                <!--begin::Section-->
                <div class="d-flex flex-column justify-content-between h-100">
                  <!--begin::Title-->
                  <h3 class="fs-3 text-gray-800 text-hover-primary fw-bolder cursor-pointer">Reached 50,000 Sales</h3>
                  <!--end::Title-->
                  <!--begin::Text-->
                  <p class="text-gray-600 fw-bold pt-3 mb-0">To start a blog, think of a topic about and first brainstorm ways to write details</p>
                  <!--end::Text-->
                </div>
                <!--end::Section-->
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="carousel-item">
                <!--begin::Section-->
                <div class="d-flex flex-column justify-content-between h-100">
                  <!--begin::Title-->
                  <h3 class="fs-3 text-gray-800 text-hover-primary fw-bolder cursor-pointer">Reached 50,000 Sales</h3>
                  <!--end::Title-->
                  <!--begin::Text-->
                  <p class="text-gray-600 fw-bold pt-3 mb-0">To start a blog, think of a topic about and first brainstorm ways to write details</p>
                  <!--end::Text-->
                </div>
                <!--end::Section-->
              </div>
              <!--end::Item-->
            </div>
            <!--end::Carousel-->
          </div>
        </div>
        <!--end::Body-->
        <!--begin::Footer-->
        <div class="card-footer border-0 pt-0 pb-10">
          <!--begin::Text-->
          <div class="d-flex text-muted fw-bold fs-6 pb-4">
            <span class="flex-grow-1">Progress</span>
            <span class="">78%</span>
          </div>
          <!--end::Text-->
          <!--begin::Progress-->
          <div class="progress h-6px bg-light-danger">
            <div class="progress-bar bg-danger" role="progressbar" style="width: 70%" aria-valuenow="24" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <!--end::Progress-->
        </div>
        <!--end::Footer-->
      </div>
      <!--end::Stats Widget 8-->
      <!--begin::Stats Widget 9-->
      <div class="card card-stretch-50 mb-5 mb-xxl-8">
        <!--begin::Body-->
        <div class="card-body">
          <div id="kt_stats_widget_9_carousel" class="carousel carousel-custom slide" data-bs-ride="carousel" data-bs-interval="8000">
            <!--begin::Top-->
            <div class="d-flex flex-stack flex-wrap">
              <!--begin::Label-->
              <span class="text-muted fw-bolder pe-2">Today’s Schedule</span>
              <!--end::Label-->
              <!--begin::Carousel Indicators-->
              <ol class="p-0 m-0 carousel-indicators carousel-indicators-dots">
                <li data-bs-target="#kt_stats_widget_9_carousel" data-bs-slide-to="0" class="ms-1 active"></li>
                <li data-bs-target="#kt_stats_widget_9_carousel" data-bs-slide-to="1" class="ms-1"></li>
                <li data-bs-target="#kt_stats_widget_9_carousel" data-bs-slide-to="2" class="ms-1"></li>
              </ol>
              <!--end::Carousel Indicators-->
            </div>
            <!--end::Top-->
            <!--begin::Carousel-->
            <div class="carousel-inner pt-9">
              <!--begin::Item-->
              <div class="carousel-item active">
                <!--begin::Section-->
                <div class="flex-grow-1">
                  <!--begin::Title-->
                  <h3 class="fs-3 text-gray-800 text-hover-primary fw-bolder cursor-pointer">UI/UX Design Updates</h3>
                  <!--end::Title-->
                  <!--begin::Text-->
                  <p class="text-primary fs-1 fw-bolder pt-3 mb-0">11:15AM - 12:30PM</p>
                  <!--end::Text-->
                </div>
                <!--end::Section-->
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="carousel-item">
                <!--begin::Section-->
                <div class="flex-grow-1">
                  <!--begin::Title-->
                  <h3 class="fs-3 text-gray-800 text-hover-primary fw-bolder cursor-pointer">UI/UX Design Updates</h3>
                  <!--end::Title-->
                  <!--begin::Text-->
                  <p class="text-primary fs-1 fw-bolder pt-3 mb-0">16:15AM - 11:20PM</p>
                  <!--end::Text-->
                </div>
                <!--end::Section-->
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="carousel-item">
                <!--begin::Section-->
                <div class="flex-grow-1">
                  <!--begin::Title-->
                  <h3 class="fs-3 text-gray-800 text-hover-primary fw-bolder cursor-pointer">UI/UX Design Updates</h3>
                  <!--end::Title-->
                  <!--begin::Text-->
                  <p class="text-primary fs-1 fw-bolder pt-3 mb-0">13:15AM - 14:30PM</p>
                  <!--end::Text-->
                </div>
                <!--end::Section-->
              </div>
              <!--end::Item-->
            </div>
            <!--end::Carousel-->
          </div>
        </div>
        <!--end::Body-->
        <!--begin::Footer-->
        <div class="card-footer border-0 d-flex flex-stack pt-0 pb-10">
          <!--begin::Label-->
          <span class="text-muted fs-6 fw-bold pe-2">256 R St. Manhattan NY..</span>
          <!--end::Label-->
          <a href="#" class="btn btn-sm btn-primary fw-bolder px-6" data-bs-toggle="modal" data-bs-target="#kt_modal_select_location">Map</a>
        </div>
        <!--end::Footer-->
      </div>
      <!--end::Stats Widget 9-->
    </div>
    <div class="col-xl-8">
      <!--begin::Table Widget 2-->
      <div class="card card-stretch">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder text-dark fs-3">Achievement</span>
            <span class="text-muted mt-2 fw-bold fs-6">890,344 Sales</span>
          </h3>
          <div class="card-toolbar">
            <ul class="nav nav-pills nav-pills-sm nav-light">
              <li class="nav-item">
                <a class="nav-link btn btn-active-light btn-color-muted py-2 px-4 active fw-bolder me-2" data-bs-toggle="tab" href="#kt_tab_pane_2_1">Day</a>
              </li>
              <li class="nav-item">
                <a class="nav-link btn btn-active-light btn-color-muted py-2 px-4 fw-bolder me-2" data-bs-toggle="tab" href="#kt_tab_pane_2_2">Week</a>
              </li>
              <li class="nav-item">
                <a class="nav-link btn btn-active-light btn-color-muted py-2 px-4 fw-bolder" data-bs-toggle="tab" href="#kt_tab_pane_2_3">Month</a>
              </li>
            </ul>
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-3 pb-0 mt-n3">
          <div class="tab-content mt-4" id="myTabTables2">
            <!--begin::Tap pane-->
            <div class="tab-pane fade show active" id="kt_tab_pane_2_1" role="tabpanel" aria-labelledby="kt_tab_pane_2_1">
              <!--begin::Table-->
              <div class="table-responsive">
                <table class="table table-borderless align-middle">
                  <thead>
                  <tr>
                    <th class="p-0 w-50px"></th>
                    <th class="p-0 min-w-150px"></th>
                    <th class="p-0 min-w-120px"></th>
                    <th class="p-0 min-w-70px"></th>
                    <th class="p-0 min-w-70px"></th>
                    <th class="p-0 min-w-50px"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="px-0 py-3">
                      <div class="symbol symbol-55px mt-1 me-5">
																				<span class="symbol-label bg-light-primary align-items-end">
																					<img alt="Logo" src="/assets/media/svg/avatars/001-boy.svg" class="mh-40px" />
																				</span>
                      </div>
                    </td>
                    <td class="px-0">
                      <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Brad Simmons</a>
                      <span class="text-muted fw-bold d-block mt-1">HTML, CSS Coding</span>
                    </td>
                    <td></td>
                    <td class="text-end">
                      <span class="text-gray-800 fw-bolder d-block fs-6">$1,200,000</span>
                      <span class="text-muted fw-bold d-block mt-1 fs-7">Paid</span>
                    </td>
                    <td class="text-end">
                      <span class="fw-bolder text-primary">+28%</span>
                    </td>
                    <td class="text-end pe-0">
                      <a href="#" class="btn btn-icon btn-bg-light btn-active-primary btn-sm">
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-right.svg-->
                        <span class="svg-icon svg-icon-4">
																					<!--suppress HtmlDeprecatedAttribute -->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<polygon points="0 0 24 0 24 24 0 24" />
																							<rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1" />
																							<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																						</g>
																					</svg>
																				</span>
                        <!--end::Svg Icon-->
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 py-3">
                      <div class="symbol symbol-55px mt-1">
																				<span class="symbol-label bg-light-danger align-items-end">
																					<img alt="Logo" src="/assets/media/svg/avatars/018-girl-9.svg" class="mh-40px" />
																				</span>
                      </div>
                    </td>
                    <td class="px-0">
                      <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Jessie Clarcson</a>
                      <span class="text-muted fw-bold d-block mt-1">Most Successful</span>
                    </td>
                    <td></td>
                    <td class="text-end">
                      <span class="text-gray-800 fw-bolder d-block fs-6">$1,200,000</span>
                      <span class="text-muted fw-bold d-block mt-1 fs-7">Paid</span>
                    </td>
                    <td class="text-end">
                      <span class="fw-bolder text-danger">+52%</span>
                    </td>
                    <td class="text-end pe-0">
                      <a href="#" class="btn btn-icon btn-bg-light btn-active-primary btn-sm">
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-right.svg-->
                        <span class="svg-icon svg-icon-4">
																					<!--suppress HtmlDeprecatedAttribute -->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<polygon points="0 0 24 0 24 24 0 24" />
																							<rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1" />
																							<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																						</g>
																					</svg>
																				</span>
                        <!--end::Svg Icon-->
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 py-3">
                      <div class="symbol symbol-55px mt-1">
																				<span class="symbol-label bg-light-warning align-items-end">
																					<img alt="Logo" src="/assets/media/svg/avatars/047-girl-25.svg" class="mh-40px" />
																				</span>
                      </div>
                    </td>
                    <td class="px-0">
                      <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Lebron Wayde</a>
                      <span class="text-muted fw-bold d-block mt-1">Awesome Users</span>
                    </td>
                    <td></td>
                    <td class="text-end">
                      <span class="text-gray-800 fw-bolder d-block fs-6">$3,400,000</span>
                      <span class="text-muted fw-bold d-block mt-1 fs-7">Paid</span>
                    </td>
                    <td class="text-end">
                      <span class="fw-bolder text-warning">+34%</span>
                    </td>
                    <td class="text-end pe-0">
                      <a href="#" class="btn btn-icon btn-bg-light btn-active-primary btn-sm">
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-right.svg-->
                        <span class="svg-icon svg-icon-4">
																					<!--suppress HtmlDeprecatedAttribute -->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<polygon points="0 0 24 0 24 24 0 24" />
																							<rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1" />
																							<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																						</g>
																					</svg>
																				</span>
                        <!--end::Svg Icon-->
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 py-3">
                      <div class="symbol symbol-55px mt-1">
																				<span class="symbol-label bg-light-success align-items-end">
																					<img alt="Logo" src="/assets/media/svg/avatars/043-boy-18.svg" class="mh-40px" />
																				</span>
                      </div>
                    </td>
                    <td class="px-0">
                      <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Kevin Leonard</a>
                      <span class="text-muted fw-bold d-block mt-1">Awesome Userss</span>
                    </td>
                    <td></td>
                    <td class="text-end">
                      <span class="text-gray-800 fw-bolder d-block fs-6">$35,600,000</span>
                      <span class="text-muted fw-bold d-block mt-1 fs-7">Paid</span>
                    </td>
                    <td class="text-end">
                      <span class="fw-bolder text-success">+230%</span>
                    </td>
                    <td class="text-end pe-0">
                      <a href="#" class="btn btn-icon btn-bg-light btn-active-primary btn-sm">
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-right.svg-->
                        <span class="svg-icon svg-icon-4">
																					<!--suppress HtmlDeprecatedAttribute -->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<polygon points="0 0 24 0 24 24 0 24" />
																							<rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1" />
																							<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																						</g>
																					</svg>
																				</span>
                        <!--end::Svg Icon-->
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 py-3">
                      <div class="symbol symbol-55px mt-1">
																				<span class="symbol-label bg-light-info align-items-end">
																					<img alt="Logo" src="/assets/media/svg/avatars/024-boy-9.svg" class="mh-40px" />
																				</span>
                      </div>
                    </td>
                    <td class="px-0">
                      <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Randy Trent</a>
                      <span class="text-muted fw-bold d-block mt-1">Business Analyst</span>
                    </td>
                    <td></td>
                    <td class="text-end">
                      <span class="text-gray-800 fw-bolder d-block fs-6">$45,200,000</span>
                      <span class="text-muted fw-bold d-block mt-1 fs-7">Paid</span>
                    </td>
                    <td class="text-end">
                      <span class="fw-bolder text-info">+340%</span>
                    </td>
                    <td class="text-end pe-0">
                      <a href="#" class="btn btn-icon btn-bg-light btn-active-primary btn-sm">
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-right.svg-->
                        <span class="svg-icon svg-icon-4">
																					<!--suppress HtmlDeprecatedAttribute -->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<polygon points="0 0 24 0 24 24 0 24" />
																							<rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1" />
																							<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																						</g>
																					</svg>
																				</span>
                        <!--end::Svg Icon-->
                      </a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!--end::Table-->
            </div>
            <!--end::Tap pane-->
            <!--begin::Tap pane-->
            <div class="tab-pane fade" id="kt_tab_pane_2_2" role="tabpanel" aria-labelledby="kt_tab_pane_2_2">
              <!--begin::Table-->
              <div class="table-responsive">
                <table class="table table-borderless align-middle">
                  <thead>
                  <tr>
                    <th class="p-0 w-50px"></th>
                    <th class="p-0 min-w-150px"></th>
                    <th class="p-0 min-w-120px"></th>
                    <th class="p-0 min-w-70px"></th>
                    <th class="p-0 min-w-70px"></th>
                    <th class="p-0 min-w-50px"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="p-0 py-3">
                      <div class="symbol symbol-55px mt-1 me-5">
																				<span class="symbol-label bg-light-warning align-items-end">
																					<img alt="Logo" src="/assets/media/svg/avatars/047-girl-25.svg" class="mh-40px" />
																				</span>
                      </div>
                    </td>
                    <td class="px-0">
                      <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Lebron Wayde</a>
                      <span class="text-muted fw-bold d-block mt-1">Awesome Users</span>
                    </td>
                    <td></td>
                    <td class="text-end">
                      <span class="text-gray-800 fw-bolder d-block fs-6">$3,400,000</span>
                      <span class="text-muted fw-bold d-block mt-1 fs-7">Paid</span>
                    </td>
                    <td class="text-end">
                      <span class="fw-bolder text-warning">+34%</span>
                    </td>
                    <td class="text-end pe-0">
                      <a href="#" class="btn btn-icon btn-bg-light btn-active-primary btn-sm">
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-right.svg-->
                        <span class="svg-icon svg-icon-4">
																					<!--suppress HtmlDeprecatedAttribute -->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<polygon points="0 0 24 0 24 24 0 24" />
																							<rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1" />
																							<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																						</g>
																					</svg>
																				</span>
                        <!--end::Svg Icon-->
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-0 py-3">
                      <div class="symbol symbol-55px mt-1">
																				<span class="symbol-label bg-light-success align-items-end">
																					<img alt="Logo" src="/assets/media/svg/avatars/043-boy-18.svg" class="mh-40px" />
																				</span>
                      </div>
                    </td>
                    <td class="px-0">
                      <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Kevin Leonard</a>
                      <span class="text-muted fw-bold d-block mt-1">Awesome Userss</span>
                    </td>
                    <td></td>
                    <td class="text-end">
                      <span class="text-gray-800 fw-bolder d-block fs-6">$35,600,000</span>
                      <span class="text-muted fw-bold d-block mt-1 fs-7">Paid</span>
                    </td>
                    <td class="text-end">
                      <span class="fw-bolder text-success">+230%</span>
                    </td>
                    <td class="text-end pe-0">
                      <a href="#" class="btn btn-icon btn-bg-light btn-active-primary btn-sm">
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-right.svg-->
                        <span class="svg-icon svg-icon-4">
																					<!--suppress HtmlDeprecatedAttribute -->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<polygon points="0 0 24 0 24 24 0 24" />
																							<rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1" />
																							<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																						</g>
																					</svg>
																				</span>
                        <!--end::Svg Icon-->
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-0 py-3">
                      <div class="symbol symbol-55px mt-1">
																				<span class="symbol-label bg-light-info align-items-end">
																					<img alt="Logo" src="/assets/media/svg/avatars/024-boy-9.svg" class="mh-40px" />
																				</span>
                      </div>
                    </td>
                    <td class="px-0">
                      <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Randy Trent</a>
                      <span class="text-muted fw-bold d-block mt-1">Business Analyst</span>
                    </td>
                    <td></td>
                    <td class="text-end">
                      <span class="text-gray-800 fw-bolder d-block fs-6">$45,200,000</span>
                      <span class="text-muted fw-bold d-block mt-1 fs-7">Paid</span>
                    </td>
                    <td class="text-end">
                      <span class="fw-bolder text-info">+340%</span>
                    </td>
                    <td class="text-end pe-0">
                      <a href="#" class="btn btn-icon btn-bg-light btn-active-primary btn-sm">
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-right.svg-->
                        <span class="svg-icon svg-icon-4">
																					<!--suppress HtmlDeprecatedAttribute -->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<polygon points="0 0 24 0 24 24 0 24" />
																							<rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1" />
																							<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																						</g>
																					</svg>
																				</span>
                        <!--end::Svg Icon-->
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-0 py-3">
                      <div class="symbol symbol-55px me-5 mt-1">
																				<span class="symbol-label bg-light-primary align-items-end">
																					<img alt="Logo" src="/assets/media/svg/avatars/001-boy.svg" class="mh-40px" />
																				</span>
                      </div>
                    </td>
                    <td class="px-0">
                      <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Brad Simmons</a>
                      <span class="text-muted fw-bold d-block mt-1">HTML, CSS Coding</span>
                    </td>
                    <td></td>
                    <td class="text-end">
                      <span class="text-gray-800 fw-bolder d-block fs-6">$1,200,000</span>
                      <span class="text-muted fw-bold d-block mt-1 fs-7">Paid</span>
                    </td>
                    <td class="text-end">
                      <span class="fw-bolder text-primary">+28%</span>
                    </td>
                    <td class="text-end pe-0">
                      <a href="#" class="btn btn-icon btn-bg-light btn-active-primary btn-sm">
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-right.svg-->
                        <span class="svg-icon svg-icon-4">
																					<!--suppress HtmlDeprecatedAttribute -->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<polygon points="0 0 24 0 24 24 0 24" />
																							<rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1" />
																							<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																						</g>
																					</svg>
																				</span>
                        <!--end::Svg Icon-->
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-0 py-3">
                      <div class="symbol symbol-55px mt-1">
																				<span class="symbol-label bg-light-danger align-items-end">
																					<img alt="Logo" src="/assets/media/svg/avatars/018-girl-9.svg" class="mh-40px" />
																				</span>
                      </div>
                    </td>
                    <td class="px-0">
                      <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Jessie Clarcson</a>
                      <span class="text-muted fw-bold d-block mt-1">Most Successful</span>
                    </td>
                    <td></td>
                    <td class="text-end">
                      <span class="text-gray-800 fw-bolder d-block fs-6">$1,200,000</span>
                      <span class="text-muted fw-bold d-block mt-1 fs-7">Paid</span>
                    </td>
                    <td class="text-end">
                      <span class="fw-bolder text-danger">+52%</span>
                    </td>
                    <td class="text-end pe-0">
                      <a href="#" class="btn btn-icon btn-bg-light btn-active-primary btn-sm">
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-right.svg-->
                        <span class="svg-icon svg-icon-4">
																					<!--suppress HtmlDeprecatedAttribute -->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<polygon points="0 0 24 0 24 24 0 24" />
																							<rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1" />
																							<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																						</g>
																					</svg>
																				</span>
                        <!--end::Svg Icon-->
                      </a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!--end::Table-->
            </div>
            <!--end::Tap pane-->
            <!--begin::Tap pane-->
            <div class="tab-pane fade" id="kt_tab_pane_2_3" role="tabpanel" aria-labelledby="kt_tab_pane_2_3">
              <!--begin::Table-->
              <div class="table-responsive">
                <table class="table table-borderless align-middle">
                  <thead>
                  <tr>
                    <th class="p-0 w-50px"></th>
                    <th class="p-0 min-w-150px"></th>
                    <th class="p-0 min-w-120px"></th>
                    <th class="p-0 min-w-70px"></th>
                    <th class="p-0 min-w-70px"></th>
                    <th class="p-0 min-w-50px"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="p-0 pb-3 pt-1">
                      <div class="symbol symbol-55px mt-3 me-5">
																				<span class="symbol-label bg-light-danger align-items-end">
																					<img alt="Logo" src="/assets/media/svg/avatars/018-girl-9.svg" class="mh-40px" />
																				</span>
                      </div>
                    </td>
                    <td class="px-0">
                      <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Jessie Clarcson</a>
                      <span class="text-muted fw-bold d-block mt-1">Most Successful</span>
                    </td>
                    <td></td>
                    <td class="text-end">
                      <span class="text-gray-800 fw-bolder d-block fs-6">$1,200,000</span>
                      <span class="text-muted fw-bold d-block mt-1 fs-7">Paid</span>
                    </td>
                    <td class="text-end">
                      <span class="fw-bolder text-danger">+52%</span>
                    </td>
                    <td class="text-end pe-0">
                      <a href="#" class="btn btn-icon btn-bg-light btn-active-primary btn-sm">
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-right.svg-->
                        <span class="svg-icon svg-icon-4">
																					<!--suppress HtmlDeprecatedAttribute -->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<polygon points="0 0 24 0 24 24 0 24" />
																							<rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1" />
																							<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																						</g>
																					</svg>
																				</span>
                        <!--end::Svg Icon-->
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-0 pb-3 pt-1">
                      <div class="symbol symbol-55px mt-3">
																				<span class="symbol-label bg-light-warning align-items-end">
																					<img alt="Logo" src="/assets/media/svg/avatars/047-girl-25.svg" class="mh-40px" />
																				</span>
                      </div>
                    </td>
                    <td class="px-0">
                      <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Lebron Wayde</a>
                      <span class="text-muted fw-bold d-block mt-1">Awesome Users</span>
                    </td>
                    <td></td>
                    <td class="text-end">
                      <span class="text-gray-800 fw-bolder d-block fs-6">$3,400,000</span>
                      <span class="text-muted fw-bold d-block mt-1 fs-7">Paid</span>
                    </td>
                    <td class="text-end">
                      <span class="fw-bolder text-warning">+34%</span>
                    </td>
                    <td class="text-end pe-0">
                      <a href="#" class="btn btn-icon btn-bg-light btn-active-primary btn-sm">
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-right.svg-->
                        <span class="svg-icon svg-icon-4">
																					<!--suppress HtmlDeprecatedAttribute -->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<polygon points="0 0 24 0 24 24 0 24" />
																							<rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1" />
																							<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																						</g>
																					</svg>
																				</span>
                        <!--end::Svg Icon-->
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-0 pb-3 pt-1">
                      <div class="symbol symbol-55px mt-3">
																				<span class="symbol-label bg-light-success align-items-end">
																					<img alt="Logo" src="/assets/media/svg/avatars/043-boy-18.svg" class="mh-40px" />
																				</span>
                      </div>
                    </td>
                    <td class="px-0">
                      <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Kevin Leonard</a>
                      <span class="text-muted fw-bold d-block mt-1">Awesome Userss</span>
                    </td>
                    <td></td>
                    <td class="text-end">
                      <span class="text-gray-800 fw-bolder d-block fs-6">$35,600,000</span>
                      <span class="text-muted fw-bold d-block mt-1 fs-7">Paid</span>
                    </td>
                    <td class="text-end">
                      <span class="fw-bolder text-success">+230%</span>
                    </td>
                    <td class="text-end pe-0">
                      <a href="#" class="btn btn-icon btn-bg-light btn-active-primary btn-sm">
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-right.svg-->
                        <span class="svg-icon svg-icon-4">
																					<!--suppress HtmlDeprecatedAttribute -->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<polygon points="0 0 24 0 24 24 0 24" />
																							<rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1" />
																							<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																						</g>
																					</svg>
																				</span>
                        <!--end::Svg Icon-->
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-0 pb-3 pt-1">
                      <div class="symbol symbol-55px me-5 mt-3">
																				<span class="symbol-label bg-light-primary align-items-end">
																					<img alt="Logo" src="/assets/media/svg/avatars/001-boy.svg" class="mh-40px" />
																				</span>
                      </div>
                    </td>
                    <td class="px-0">
                      <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Brad Simmons</a>
                      <span class="text-muted fw-bold d-block mt-1">HTML, CSS Coding</span>
                    </td>
                    <td></td>
                    <td class="text-end">
                      <span class="text-gray-800 fw-bolder d-block fs-6">$1,200,000</span>
                      <span class="text-muted fw-bold d-block mt-1 fs-7">Paid</span>
                    </td>
                    <td class="text-end">
                      <span class="fw-bolder text-primary">+28%</span>
                    </td>
                    <td class="text-end pe-0">
                      <a href="#" class="btn btn-icon btn-bg-light btn-active-primary btn-sm">
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-right.svg-->
                        <span class="svg-icon svg-icon-4">
																					<!--suppress HtmlDeprecatedAttribute -->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<polygon points="0 0 24 0 24 24 0 24" />
																							<rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1" />
																							<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																						</g>
																					</svg>
																				</span>
                        <!--end::Svg Icon-->
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-0 pb-3 pt-1">
                      <div class="symbol symbol-55px mt-3">
																				<span class="symbol-label bg-light-info align-items-end">
																					<img alt="Logo" src="/assets/media/svg/avatars/024-boy-9.svg" class="mh-40px" />
																				</span>
                      </div>
                    </td>
                    <td class="px-0">
                      <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">Randy Trent</a>
                      <span class="text-muted fw-bold d-block mt-1">Business Analyst</span>
                    </td>
                    <td></td>
                    <td class="text-end">
                      <span class="text-gray-800 fw-bolder d-block fs-6">$45,200,000</span>
                      <span class="text-muted fw-bold d-block mt-1 fs-7">Paid</span>
                    </td>
                    <td class="text-end">
                      <span class="fw-bolder text-info">+340%</span>
                    </td>
                    <td class="text-end pe-0">
                      <a href="#" class="btn btn-icon btn-bg-light btn-active-primary btn-sm">
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-right.svg-->
                        <span class="svg-icon svg-icon-4">
																					<!--suppress HtmlDeprecatedAttribute -->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<polygon points="0 0 24 0 24 24 0 24" />
																							<rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1" />
																							<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																						</g>
																					</svg>
																				</span>
                        <!--end::Svg Icon-->
                      </a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!--end::Table-->
            </div>
            <!--end::Tap pane-->
          </div>
        </div>
        <!--end::Body-->
      </div>
      <!--end::Table Widget 2-->
    </div>
  </div>
  <!--end::Row-->
  <!--begin::Modals-->
  <!--begin::Modal - Create App-->
  <div class="modal fade" id="kt_modal_create_app" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered mw-1000px">
      <div class="modal-content">
        <div class="container px-10 py-10">
          <div class="modal-header py-2 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" data-bs-dismiss="modal">
              <!--begin::Svg Icon | path: icons/duotone/Navigation/Close.svg-->
              <span class="svg-icon svg-icon-2">
															<!--suppress HtmlDeprecatedAttribute -->
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
																	<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
																	<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
																</g>
															</svg>
														</span>
              <!--end::Svg Icon-->
            </div>
            <!--end::Close-->
          </div>
          <div class="modal-body">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-xl-row flex-row-fluid" id="kt_modal_create_app_stepper">
              <!--begin::Aside-->
              <div class="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
                <!--begin::Nav-->
                <div class="stepper-nav d-flex flex-column pt-5">
                  <!--begin::Step 1-->
                  <div class="stepper-item current" data-kt-stepper-element="nav">
                    <div class="stepper-wrapper">
                      <div class="stepper-icon">
                        <i class="stepper-check fas fa-check"></i>
                        <span class="stepper-number">1</span>
                      </div>
                      <div class="stepper-label">
                        <h3 class="stepper-title">App Basics</h3>
                        <div class="stepper-desc">Name your App</div>
                      </div>
                    </div>
                  </div>
                  <!--end::Step 1-->
                  <!--begin::Step 2-->
                  <div class="stepper-item" data-kt-stepper-element="nav">
                    <div class="stepper-wrapper">
                      <div class="stepper-icon">
                        <i class="stepper-check fas fa-check"></i>
                        <span class="stepper-number">2</span>
                      </div>
                      <div class="stepper-label">
                        <h3 class="stepper-title">App Framework</h3>
                        <div class="stepper-desc">Define your app framework</div>
                      </div>
                    </div>
                  </div>
                  <!--end::Step 2-->
                  <!--begin::Step 3-->
                  <div class="stepper-item" data-kt-stepper-element="nav">
                    <div class="stepper-wrapper">
                      <div class="stepper-icon">
                        <i class="stepper-check fas fa-check"></i>
                        <span class="stepper-number">3</span>
                      </div>
                      <div class="stepper-label">
                        <h3 class="stepper-title">App Database</h3>
                        <div class="stepper-desc">Select the app database type</div>
                      </div>
                    </div>
                  </div>
                  <!--end::Step 3-->
                  <!--begin::Step 4-->
                  <div class="stepper-item" data-kt-stepper-element="nav">
                    <div class="stepper-wrapper">
                      <div class="stepper-icon">
                        <i class="stepper-check fas fa-check"></i>
                        <span class="stepper-number">4</span>
                      </div>
                      <div class="stepper-label">
                        <h3 class="stepper-title">App Storage</h3>
                        <div class="stepper-desc">Select the app storage type</div>
                      </div>
                    </div>
                  </div>
                  <!--end::Step 4-->
                  <!--begin::Step 5-->
                  <div class="stepper-item" data-kt-stepper-element="nav">
                    <div class="stepper-wrapper">
                      <div class="stepper-icon">
                        <i class="stepper-check fas fa-check"></i>
                        <span class="stepper-number">5</span>
                      </div>
                      <div class="stepper-label">
                        <h3 class="stepper-title">Completed!</h3>
                        <div class="stepper-desc">Review and Submit</div>
                      </div>
                    </div>
                  </div>
                  <!--end::Step 5-->
                </div>
                <!--end::Nav-->
              </div>
              <!--begin::Aside-->
              <!--begin::Content-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form class="pb-5 w-100 w-md-400px w-xl-500px" novalidate="novalidate" id="kt_modal_create_app_form">
                  <!--begin::Step 1-->
                  <div class="pb-5 current" data-kt-stepper-element="content">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5 pb-lg-10">
                        <h3 class="fw-bolder text-dark display-6">App Basics</h3>
                      </div>
                      <!--begin::Heading-->
                      <!--begin::Form Group-->
                      <div class="fv-row mb-12">
                        <label class="fs-6 fw-bolder text-dark form-label">Your App Name</label>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="appname" placeholder="" value="" />
                      </div>
                      <!--end::Form Group-->
                      <!--begin::Form Group-->
                      <div class="fv-row">
                        <!--begin:Option-->
                        <label class="d-flex flex-stack mb-6 cursor-pointer">
																				<span class="d-flex align-items-center me-2">
																					<span class="symbol symbol-50px me-6">
																						<span class="symbol-label bg-light-primary">
																							<!--begin::Svg Icon | path: icons/duotone/Home/Globe.svg-->
																							<span class="svg-icon svg-icon-1 svg-icon-primary">
																								<!--suppress HtmlDeprecatedAttribute -->
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																										<rect x="0" y="0" width="24" height="24" />
																										<path d="M13,18.9450712 L13,20 L14,20 C15.1045695,20 16,20.8954305 16,22 L8,22 C8,20.8954305 8.8954305,20 10,20 L11,20 L11,18.9448245 C9.02872877,18.7261967 7.20827378,17.866394 5.79372555,16.5182701 L4.73856106,17.6741866 C4.36621808,18.0820826 3.73370941,18.110904 3.32581341,17.7385611 C2.9179174,17.3662181 2.88909597,16.7337094 3.26143894,16.3258134 L5.04940685,14.367122 C5.46150313,13.9156769 6.17860937,13.9363085 6.56406875,14.4106998 C7.88623094,16.037907 9.86320756,17 12,17 C15.8659932,17 19,13.8659932 19,10 C19,7.73468744 17.9175842,5.65198725 16.1214335,4.34123851 C15.6753081,4.01567657 15.5775721,3.39010038 15.903134,2.94397499 C16.228696,2.49784959 16.8542722,2.4001136 17.3003976,2.72567554 C19.6071362,4.40902808 21,7.08906798 21,10 C21,14.6325537 17.4999505,18.4476269 13,18.9450712 Z" fill="#000000" fill-rule="nonzero" />
																										<circle fill="#000000" opacity="0.3" cx="12" cy="10" r="6" />
																									</g>
																								</svg>
																							</span>
                                              <!--end::Svg Icon-->
																						</span>
																					</span>
																					<span class="d-flex flex-column">
																						<span class="fw-bolder fs-6">Quick Online Courses</span>
																						<span class="fs-7 text-muted">Creating a clear text structure is just one SEO</span>
																					</span>
																				</span>
                          <span class="form-check form-check-custom form-check-solid">
																					<input class="form-check-input" type="radio" checked="checked" name="app_option_1" value="1" />
																				</span>
                        </label>
                        <!--end::Option-->
                        <!--begin:Option-->
                        <label class="d-flex flex-stack mb-6 cursor-pointer">
																				<span class="d-flex align-items-center me-2">
																					<span class="symbol symbol-50px me-6">
																						<span class="symbol-label bg-light-danger">
																							<!--begin::Svg Icon | path: icons/duotone/Layout/Layout-4-blocks-2.svg-->
																							<span class="svg-icon svg-icon-1 svg-icon-danger">
																								<!--suppress HtmlDeprecatedAttribute -->
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																										<rect x="5" y="5" width="5" height="5" rx="1" fill="#000000" />
																										<rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																										<rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																										<rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																									</g>
																								</svg>
																							</span>
                                              <!--end::Svg Icon-->
																						</span>
																					</span>
																					<span class="d-flex flex-column">
																						<span class="fw-bolder fs-6">Face to Face Discussions</span>
																						<span class="fs-7 text-muted">Creating a clear text structure is just one aspect</span>
																					</span>
																				</span>
                          <span class="form-check form-check-custom form-check-solid">
																					<input class="form-check-input" type="radio" name="app_option_1" value="1" />
																				</span>
                        </label>
                        <!--end::Option-->
                        <!--begin:Option-->
                        <label class="d-flex flex-stack mb-6 cursor-pointer">
																				<span class="d-flex align-items-center me-2">
																					<span class="symbol symbol-50px me-6">
																						<span class="symbol-label bg-light-success">
																							<!--begin::Svg Icon | path: icons/duotone/Devices/Watch1.svg-->
																							<span class="svg-icon svg-icon-1 svg-icon-success">
																								<!--suppress HtmlDeprecatedAttribute -->
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																									<path d="M9,8 C8.44771525,8 8,8.44771525 8,9 L8,15 C8,15.5522847 8.44771525,16 9,16 L15,16 C15.5522847,16 16,15.5522847 16,15 L16,9 C16,8.44771525 15.5522847,8 15,8 L9,8 Z M9,6 L15,6 C16.6568542,6 18,7.34314575 18,9 L18,15 C18,16.6568542 16.6568542,18 15,18 L9,18 C7.34314575,18 6,16.6568542 6,15 L6,9 C6,7.34314575 7.34314575,6 9,6 Z" fill="#000000" fill-rule="nonzero" />
																									<path d="M9,8 C8.44771525,8 8,8.44771525 8,9 L8,15 C8,15.5522847 8.44771525,16 9,16 L15,16 C15.5522847,16 16,15.5522847 16,15 L16,9 C16,8.44771525 15.5522847,8 15,8 L9,8 Z" fill="#000000" opacity="0.3" />
																									<path d="M9,18 L15,18 L15,20.5 C15,21.3284271 14.3284271,22 13.5,22 L10.5,22 C9.67157288,22 9,21.3284271 9,20.5 L9,18 Z" fill="#000000" opacity="0.3" />
																									<path d="M10.5,2 L13.5,2 C14.3284271,2 15,2.67157288 15,3.5 L15,6 L9,6 L9,3.5 C9,2.67157288 9.67157288,2 10.5,2 Z" fill="#000000" opacity="0.3" />
																								</svg>
																							</span>
                                              <!--end::Svg Icon-->
																						</span>
																					</span>
																					<span class="d-flex flex-column">
																						<span class="fw-bolder fs-6">Full Intro Training</span>
																						<span class="fs-7 text-muted">Creating a clear text structure copywriting</span>
																					</span>
																				</span>
                          <span class="form-check form-check-custom form-check-solid">
																					<input class="form-check-input" type="radio" name="app_option_1" value="1" />
																				</span>
                        </label>
                        <!--end::Option-->
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step 1-->
                  <!--begin::Step 2-->
                  <div class="pb-5" data-kt-stepper-element="content">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-10 pb-lg-15">
                        <h3 class="fw-bolder text-dark display-6">App Framework</h3>
                      </div>
                      <!--end::Heading-->
                      <!--begin::Form Group-->
                      <div class="fv-row">
                        <label class="fs-6 fw-bolder text-dark mb-7">Select your app framework</label>
                        <!--begin:Option-->
                        <label class="d-flex flex-stack cursor-pointer mb-6">
																				<span class="d-flex align-items-center me-2">
																					<span class="symbol symbol-50px me-6">
																						<span class="symbol-label bg-light-warning">
																							<i class="fab fa-html5 text-warning fs-2x"></i>
																						</span>
																					</span>
																					<span class="d-flex flex-column">
																						<span class="fw-bolder fs-6">HTML5</span>
																						<span class="fs-7 text-muted">Base Web Projec</span>
																					</span>
																				</span>
                          <span class="form-check form-check-custom form-check-solid">
																					<input class="form-check-input" type="radio" checked="checked" name="app_option_2" value="1" />
																				</span>
                        </label>
                        <!--end::Option-->
                        <!--begin:Option-->
                        <label class="d-flex flex-stack cursor-pointer mb-6">
																				<span class="d-flex align-items-center me-2">
																					<span class="symbol symbol-50px me-6">
																						<span class="symbol-label bg-light-success">
																							<i class="fab fa-react text-success fs-2x"></i>
																						</span>
																					</span>
																					<span class="d-flex flex-column">
																						<span class="fw-bolder fs-6">ReactJS</span>
																						<span class="fs-7 text-muted">Robust and flexible app framework</span>
																					</span>
																				</span>
                          <span class="form-check form-check-custom form-check-solid">
																					<input class="form-check-input" type="radio" name="app_option_2" value="1" />
																				</span>
                        </label>
                        <!--end::Option-->
                        <!--begin:Option-->
                        <label class="d-flex flex-stack cursor-pointer mb-6">
																				<span class="d-flex align-items-center me-2">
																					<span class="symbol symbol-50px me-6">
																						<span class="symbol-label bg-light-danger">
																							<i class="fab fa-angular text-danger fs-2x"></i>
																						</span>
																					</span>
																					<span class="d-flex flex-column">
																						<span class="fw-bolder fs-6">Angular</span>
																						<span class="fs-7 text-muted">Powerful data mangement</span>
																					</span>
																				</span>
                          <span class="form-check form-check-custom form-check-solid">
																					<input class="form-check-input" type="radio" name="app_option_2" value="1" />
																				</span>
                        </label>
                        <!--end::Option-->
                        <!--begin:Option-->
                        <label class="d-flex flex-stack cursor-pointer mb-6">
																				<span class="d-flex align-items-center me-2">
																					<span class="symbol symbol-50px me-6">
																						<span class="symbol-label bg-light-primary">
																							<i class="fab fa-vuejs text-primary fs-2x"></i>
																						</span>
																					</span>
																					<span class="d-flex flex-column">
																						<span class="fw-bolder fs-6">Vue</span>
																						<span class="fs-7 text-muted">Lightweight and responsive framework</span>
																					</span>
																				</span>
                          <span class="form-check form-check-custom form-check-solid">
																					<input class="form-check-input" type="radio" name="app_option_2" value="1" />
																				</span>
                        </label>
                        <!--end::Option-->
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step 2-->
                  <!--begin::Step 3-->
                  <div class="pb-5" data-kt-stepper-element="content">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-10 pb-lg-15">
                        <h3 class="fw-bolder text-dark display-6">App Database</h3>
                      </div>
                      <!--begin::Heading-->
                      <!--begin::Form Group-->
                      <div class="fv-row mb-12">
                        <label class="fs-6 fw-bolder text-dark form-label">App Databse Name Name</label>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="dbname" placeholder="" value="db_name" />
                      </div>
                      <!--end::Form Group-->
                      <!--begin::Form Group-->
                      <div class="fv-row">
                        <label class="fs-6 fw-bolder text-dark mb-7">Select your app database solution</label>
                        <!--begin:Option-->
                        <label class="d-flex flex-stack cursor-pointer mb-6">
																				<span class="d-flex align-items-center me-2">
																					<span class="symbol symbol-50px me-6">
																						<span class="symbol-label bg-light-success">
																							<i class="fas fa-database text-success fs-2x"></i>
																						</span>
																					</span>
																					<span class="d-flex flex-column">
																						<span class="fw-bolder fs-6">MySQL</span>
																						<span class="fs-7 text-muted">Basic MySQL database</span>
																					</span>
																				</span>
                          <span class="form-check form-check-custom form-check-solid">
																					<input class="form-check-input" type="radio" name="app_option_3" checked="checked" value="1" />
																				</span>
                        </label>
                        <!--end::Option-->
                        <!--begin:Option-->
                        <label class="d-flex flex-stack cursor-pointer mb-6">
																				<span class="d-flex align-items-center me-2">
																					<span class="symbol symbol-50px me-6">
																						<span class="symbol-label bg-light-danger">
																							<i class="fab fa-google text-danger fs-2x"></i>
																						</span>
																					</span>
																					<span class="d-flex flex-column">
																						<span class="fw-bolder fs-6">Firebase</span>
																						<span class="fs-7 text-muted">Google based app data management</span>
																					</span>
																				</span>
                          <span class="form-check form-check-custom form-check-solid">
																					<input class="form-check-input" type="radio" name="app_option_3" value="1" />
																				</span>
                        </label>
                        <!--end::Option-->
                        <!--begin:Option-->
                        <label class="d-flex flex-stack cursor-pointer mb-6">
																				<span class="d-flex align-items-center me-2">
																					<span class="symbol symbol-50px me-6">
																						<span class="symbol-label bg-light-warning">
																							<i class="fab fa-amazon text-warning fs-2x"></i>
																						</span>
																					</span>
																					<span class="d-flex flex-column">
																						<span class="fw-bolder fs-6">DynamoDB</span>
																						<span class="fs-7 text-muted">Amazon Fast NoSQL Database</span>
																					</span>
																				</span>
                          <span class="form-check form-check-custom form-check-solid">
																					<input class="form-check-input" type="radio" name="app_option_3" value="1" />
																				</span>
                        </label>
                        <!--end::Option-->
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step 3-->
                  <!--begin::Step 4-->
                  <div class="pb-5" data-kt-stepper-element="content">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-10 pb-lg-15">
                        <h3 class="fw-bolder text-dark display-6">App Storage</h3>
                      </div>
                      <!--begin::Heading-->
                      <!--begin::Form Group-->
                      <div class="fv-row">
                        <label class="fs-6 fw-bolder text-dark mb-7">Select your app storage solution</label>
                        <!--begin:Option-->
                        <label class="d-flex flex-stack cursor-pointer mb-6">
																				<span class="d-flex align-items-center me-2">
																					<span class="symbol symbol-50px me-6">
																						<span class="symbol-label bg-light-primary">
																							<i class="fab fa-linux text-primary fs-2x"></i>
																						</span>
																					</span>
																					<span class="d-flex flex-column">
																						<span class="fw-bolder fs-6">Basic Server</span>
																						<span class="fs-7 text-muted">Linux based server storage</span>
																					</span>
																				</span>
                          <span class="form-check form-check-custom form-check-solid">
																					<input class="form-check-input" type="radio" checked="checked" name="app_option_4" value="1" />
																				</span>
                        </label>
                        <!--end::Option-->
                        <!--begin:Option-->
                        <label class="d-flex flex-stack cursor-pointer mb-6">
																				<span class="d-flex align-items-center me-2">
																					<span class="symbol symbol-50px me-6">
																						<span class="symbol-label bg-light-warning">
																							<i class="fab fa-aws text-warning fs-2x"></i>
																						</span>
																					</span>
																					<span class="d-flex flex-column">
																						<span class="fw-bolder fs-6">AWS</span>
																						<span class="fs-7 text-muted">Amazon Web Services</span>
																					</span>
																				</span>
                          <span class="form-check form-check-custom form-check-solid">
																					<input class="form-check-input" type="radio" name="app_option_4" value="1" />
																				</span>
                        </label>
                        <!--end::Option-->
                        <!--begin:Option-->
                        <label class="d-flex flex-stack cursor-pointer mb-6">
																				<span class="d-flex align-items-center me-2">
																					<span class="symbol symbol-50px me-6">
																						<span class="symbol-label bg-light-success">
																							<i class="fab fa-google text-success fs-2x"></i>
																						</span>
																					</span>
																					<span class="d-flex flex-column">
																						<span class="fw-bolder fs-6">Google</span>
																						<span class="fs-7 text-muted">Google Cloud Storage</span>
																					</span>
																				</span>
                          <span class="form-check form-check-custom form-check-solid">
																					<input class="form-check-input" type="radio" name="app_option_4" value="1" />
																				</span>
                        </label>
                        <!--end::Option-->
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step 4-->
                  <!--begin::Step 5-->
                  <div class="pb-5" data-kt-stepper-element="content">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-10 pb-lg-15">
                        <h3 class="fw-bolder text-dark display-6">Complete</h3>
                        <div class="text-muted fw-bold fs-3">Review your setup to kickstart your app!</div>
                      </div>
                      <!--end::Heading-->
                      <!--begin::Section-->
                      <h4 class="fw-bolder mb-3">App Basics</h4>
                      <div class="text-gray-600 fw-bold lh-lg mb-8">
                        <div>App Name</div>
                        <div>Face to Face Discussions</div>
                      </div>
                      <!--end::Section-->
                      <!--begin::Section-->
                      <h4 class="fw-bolder mb-3">App Framework</h4>
                      <div class="text-gray-600 fw-bold lh-lg mb-8">
                        <div>HTML5</div>
                      </div>
                      <!--end::Section-->
                      <!--begin::Section-->
                      <h4 class="fw-bolder mb-3">App Database</h4>
                      <div class="text-gray-600 fw-bold lh-lg mb-8">
                        <div>dn_name</div>
                        <div>Firebase</div>
                      </div>
                      <!--end::Section-->
                      <!--begin::Section-->
                      <h4 class="fw-bolder mb-3">App Storage</h4>
                      <div class="text-gray-600 fw-bold lh-lg mb-8">
                        <div>Basic Server</div>
                      </div>
                      <!--end::Section-->
                    </div>
                  </div>
                  <!--end::Step 5-->
                  <!--begin::Actions-->
                  <div class="d-flex justify-content-between pt-10">
                    <div class="mr-2">
                      <button type="button" class="btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3" data-kt-stepper-action="previous">
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Left-2.svg-->
                        <span class="svg-icon svg-icon-3 me-1">
																			<!--suppress HtmlDeprecatedAttribute -->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																				<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																					<polygon points="0 0 24 0 24 24 0 24" />
																					<rect fill="#000000" opacity="0.3" transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)" x="14" y="7" width="2" height="10" rx="1" />
																					<path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)" />
																				</g>
																			</svg>
																		</span>
                        <!--end::Svg Icon-->Previous</button>
                    </div>
                    <div>
                      <button type="button" class="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3" data-kt-stepper-action="submit">Submit
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Right-2.svg-->
                        <span class="svg-icon svg-icon-3 ms-2">
																			<!--suppress HtmlDeprecatedAttribute -->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																				<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																					<polygon points="0 0 24 0 24 24 0 24" />
																					<rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
																					<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																				</g>
																			</svg>
																		</span>
                        <!--end::Svg Icon--></button>
                      <button type="button" class="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3" data-kt-stepper-action="next">Next Step
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Right-2.svg-->
                        <span class="svg-icon svg-icon-3 ms-1">
																			<!--suppress HtmlDeprecatedAttribute -->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																				<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																					<polygon points="0 0 24 0 24 24 0 24" />
																					<rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
																					<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																				</g>
																			</svg>
																		</span>
                        <!--end::Svg Icon--></button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Modal - Create App-->
  <!--begin::Modal - Select Location-->
  <div class="modal fade" id="kt_modal_select_location" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Select Location</h5>
          <!--begin::Close-->
          <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal">
            <!--begin::Svg Icon | path: icons/duotone/Navigation/Close.svg-->
            <span class="svg-icon svg-icon-2x">
														<!--suppress HtmlDeprecatedAttribute -->
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
															<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
																<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
																<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
															</g>
														</svg>
													</span>
            <!--end::Svg Icon-->
          </div>
          <!--end::Close-->
        </div>
        <div class="modal-body">
          <div id="kt_modal_select_location_map" class="map h-450px"></div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light-primary" data-bs-dismiss="modal">Cancel</button>
          <button id="submit" type="button" class="btn btn-primary" data-bs-dismiss="modal">Apply</button>
        </div>
      </div>
    </div>
  </div>
  <!--end::Modal - Select Location-->
  <!--end::Modals-->
</template>

<script>
import ProgressCard from "@/views/_core/components/ProgressCard";

export default {
  name: "ContactDetail",
  components: { ProgressCard }
}
</script>

<style scoped>

</style>