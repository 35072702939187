import gql from 'graphql-tag'

export const MUTATION_PROJECT_CREATE_LOCATION = gql`
    mutation (
        $projectId: UUID!,
        $newLocation: LocationInput!
    )
    {
        projectLocationCreate(
            projectId: $projectId,
            newLocation: $newLocation,
        ) {
            project {
                locations {
                    id,
                    name
                }
            }
        }
    }
`

export const MUTATION_PROJECT_DELETE_LOCATION = gql`
    mutation (
        $projectId: UUID!,
        $locationId: UUID!,
    )
    {
        projectLocationDelete(
            projectId: $projectId,
            locationId: $locationId,
        ) {
            project {
                locations {
                    id,
                    name
                }
            }
        }
    }
`

export const MUTATION_PROJECT_UPDATE_LOCATION = gql`
    mutation (
        $projectId: UUID!,
        $locationId: UUID!,
        $newLocation: LocationInput!,
    )
    {
        projectLocationUpdate(
            projectId: $projectId,
            locationId: $locationId,
            newLocation: $newLocation,
        ) {
            project {
                locations {
                    id,
                    name
                }
            }
        }
    }
`