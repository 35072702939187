<template>
  <node-view-wrapper :id="'lot-'+this.$props.node.attrs?.id" :ref="'lot-'+this.$props.node.attrs?.id"
                     class="vue-component print-lot"
                     :class="{'print-hidden' : this.$props.node.attrs?.hideInPrint,
                     'ms-10': this.$props.node.attrs?.indent > 0,
                     'ms-5': this.$props.node.attrs?.indent === 0,
                     }">
      <div class="d-flex align-items-baseline justify-content-between no-page-break-after">
          <h3 contenteditable="false" class="mt-5 print-lot-title" :style="computedStyleForTitle">{{ this.getLotFullName }}</h3>
          <p v-if="showBudgetModule" class="font-monospace text-muted ps-2 mt-5 mb-0" :class="{'print-hidden': !printValueLot}">{{this.$props.node.attrs?.calculatedSum.toLocaleString("fr-FR", {style: "currency", currency: "EUR"})}}</p>
      </div>
      <node-view-content class="no-page-break-before doc-lot-content"></node-view-content>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent } from '@tiptap/vue-3'
import {CURRENT_USER_QUERY, GET_PROJECT_BY_ID} from "@/graphql/graphql";

export default {
  name: "LotComponent",
  components: {
    NodeViewWrapper, NodeViewContent
  },
    apollo: {
        currentUser: {
            query: CURRENT_USER_QUERY
        },
        project: {
            query: GET_PROJECT_BY_ID,
            variables() {
                return {
                    id: this.currentProject,
                }
            }
        }
    },
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
      currentUser: {
          org: {
              persoColorMain: "#FF0000",
              persoFontBody: "Poppins",
              persoFontTitle: "DM Serif Display",
          }
      },
      project: null,
  }),
  computed: {
      getLotFullName(){
          return this.project?.lots.find(l => l.id === this.$props.node.attrs?.id)?.fullName || ""
      },
      isChildLot() {
          return this.project?.lots.find(l => l.id === this.$props.node.attrs?.id)?.number.includes(".") || false
      },
      computedStyleForTitle() {
          return {
              color: this.currentUser.org.persoColorMain,
              fontFamily: this.currentUser.org.persoFontBody,
          }
      },
      currentProject() {
          return this.$store.getters.currentProjectFocus;
      },
      showBudgetModule() {
          return this.$store.getters.budgetModule;
      },
      printValueLot() {
          return this.$store.getters.printValueLot
      },
  },
  methods: {

  },
}
</script>

<style scoped>
  @media print {
        .doc-lot-content > div {
            break-before: avoid;
        }
        .print-lot {
          padding-left: 0 !important;
          padding-top: 20px !important;
        }
        .print-lot-title {
          font-size: 22px;
          break-after: avoid !important;
        }
      .no-page-break-after {
          page-break-after: avoid !important;
          break-after: avoid !important;
      }
      .no-page-break-before {
          page-break-before: avoid !important;
          break-before: avoid !important;
      }
      .no-page-break-inside {
          page-break-inside: avoid !important;
          break-inside: avoid !important;
      }
      .force-page-break-before {
          page-break-before: always !important;
      }
  }
</style>