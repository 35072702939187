<template>
  <node-view-wrapper class="vue-component">
    <div @mouseover="this.hover = true"
         @mouseleave="this.hover = false">
      <div class="row g-5 mt-3 mb-3 ms-8 print-border">
        <div class="col-xl-12 mt-0 ps-0">
          <div class="card lot-component-shadow">
            <!--begin::Body-->
            <div class="p-0 card-body row">
              <!--begin::Section-->
              <div class="print-margins col-12 col-md-10 col-xl-11 pb-6 pt-8 ps-0 pe-10 d-flex align-items-center me-60px">

                <!-- LEFT HOVER ACTIONS -->
                <div class="ouvrage-left-actions d-flex align-content-center">
                  <div role="button" tabindex="0"
                       @click="plusAction()"
                       contenteditable="false"
                       title="Ajouter une ligne"
                       :style="'visibility: ' + (this.hover ? 'visible' : 'hidden')"
                       class="ouvrage-actions-left ouvrage-action-plus align-middle">
                    <svg viewBox="0 0 16 16"
                         style="width: 15px; height: 100%; display: block;">
                      <path
                          d="M7.977 14.963c.407 0 .747-.324.747-.723V8.72h5.362c.399 0 .74-.34.74-.747a.746.746 0 00-.74-.738H8.724V1.706c0-.398-.34-.722-.747-.722a.732.732 0 00-.739.722v5.529h-5.37a.746.746 0 00-.74.738c0 .407.341.747.74.747h5.37v5.52c0 .399.332.723.739.723z"></path>
                    </svg>
                  </div>
                  <div
                      role="button" tabindex="0"
                      contenteditable="false"
                      draggable="true"
                      data-drag-handle
                      title="Déplacer le bloc texte"
                      class="ouvrage-actions-left drag-handle"
                      :style="'visibility: ' + (this.hover ? 'visible' : 'hidden')"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                         style="width: 15px; height: 100%; display: block;">
                      <path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                    </svg>
                  </div>
                </div>

                <!-- CONTENT AND RIGHT BUTTON ACTIONS -->
                <div class="row">
                  <!--CONTENT-->
                  <div class="w-100">
                    <div>
                      <node-view-content id="lotText-node-content" class="content p-0 no-page-break-before" :style="isDescriptionDisplayModeCollapsed ? 'text-overflow: ellipsis; overflow: hidden; height: 20px; max-height: 20px;' : ''"/>
                    </div>
                  </div>
                  <!--RIGHT BUTTON ACTIONS-->
                  <div class="ouvrage-actions position-absolute end-0 top-0 row-cols-2 w-90px h-100 align-content-center justify-content-center">
                      <button v-if="!isDescriptionDisplayModeCollapsed"
                              title="Ajouter à une bibliothèque"
                              class="btn btn-icon btn-sm btn-outline-secondary"
                              @click="this.modalAddTextToBiblioShow()">
                          <i class="fa fa-light fa-plus-square"></i>
                      </button>
                    <button class="btn btn-icon btn-sm btn-outline-secondary"
                            title="Dupliquer le bloc texte"
                            @click="duplicateNode()">
                      <i class="fa fa-light fa-copy"></i>
                    </button>
                    <button class="btn btn-icon btn-sm btn-outline-secondary"
                            title="Supprimer le bloc texte"
                            @click="deleteNode()">
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
      </div>
    </div>
      <!-- MODAL - Add text node to biblio -->
      <div class="modal"
           tabindex="-1"
           :style="this.modals.addTextToBiblio.show ? 'display: block; z-index: 9999 !important;' : 'display: none;'">
          <div class="modal-dialog modal-dialog-centered mw-600px">
              <div class="modal-content mx-5">
                  <div class="container px-10 py-10">
                      <div class="modal-header p-0 d-flex justify-content-end border-0">
                          <!--begin::Close-->
                          <div class="btn btn-icon btn-sm btn-light-primary"
                               @click="this.modalAddTextToBiblioCancel()">
                              <i class="bi bi-x-lg"></i>
                          </div>
                          <!--end::Close-->
                      </div>

                      <div class="modal-body p-0">
                          <!--begin::Stepper-->
                          <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                              <!--begin::Content-->
                              <div class="d-flex flex-row-fluid justify-content-center">
                                  <div class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                                      <!--begin::Step-->
                                      <div class="pb-5 completed">
                                          <div class="w-100">
                                              <div class="pb-5">
                                                  <h3 class="fw-bolder text-dark display-6">Ajouter le bloc texte dans une
                                                      bibliothèque</h3>
                                              </div>
                                              <div class="row">

                                                  <!--begin::Biblio Selection-->
                                                  <div class="w-100">
                                                      <label class="fs-6 me-1 fw-bolder text-muted form-label">Bibliothèque</label>
                                                      <select v-model="this.modals.addTextToBiblio.biblioId"
                                                              class="form-select form-select-solid">
                                                          <option :value="null" disabled>Choisissez une bibliothèque
                                                              d'ouvrages...
                                                          </option>
                                                          <option
                                                              v-for="biblio in this?.biblios?.filter(b => b.type === 'PRIVATE')"
                                                              :key="biblio.id" :value="biblio.id">{{ biblio.name }}
                                                          </option>
                                                      </select>
                                                  </div>
                                                  <!--end::Biblio Selection-->

                                              </div>
                                          </div>
                                      </div>
                                      <!--end::Step-->

                                      <!--begin::Actions-->
                                      <div class="d-flex justify-content-end">
                                          <div>
                                              <button class="btn btn-lg btn-light"
                                                      @click="this.modalAddTextToBiblioCancel()">
                                                  Annuler
                                              </button>
                                              <button class="btn btn-lg btn-primary ms-2"
                                                      @click="this.modalAddTextToBiblioValidate()"
                                                      :disabled="!this.modals.addTextToBiblio.biblioId"
                                              >
                                                  Ajouter
                                              </button>
                                          </div>
                                      </div>
                                      <!--end::Actions-->
                                  </div>
                              </div>
                              <!--end::Content-->
                          </div>
                          <!--end::Stepper-->
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewContent, NodeViewWrapper } from '@tiptap/vue-3'
import kalmStore from "@/store";
import {ALL_BIBLIO_QUERY, BIBLIO_ADD_TEXT_MUTATION} from "@/graphql/graphql";
import {toast} from "vue3-toastify";

export default {
  name: "LotTextComponent",
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },
    apollo: {
        biblios: {
            query: ALL_BIBLIO_QUERY,
        },
    },
  data() {
    return {
        biblios: [],
      hover: false,
        modals: {
            addTextToBiblio: {
                show: false,
                biblioId: null,
                ouvrage: null,
            }
        }
    }
  },
  computed: {
    isDescriptionDisplayModeCollapsed: {
      get() {
        return kalmStore.state.display.descriptionMode === "collapsed";
      },
      set(newValue) {
        const mode = newValue ? 'collapsed' : 'full' ;
        kalmStore.commit('updateDescriptionDisplayMode', mode)
      }
    },
  },
  methods: {
    insertAbove() {
      const { editor, getPos } = this

      editor.commands.insertContentAt(getPos(), '<lot-text>Nouveau bloc de texte</lot-text>')
      editor.commands.focus();
    },
    insertBelow() {
      const { editor, getPos, node } = this

      const end = getPos() + node.nodeSize

      editor.commands.insertContentAt(end, '<lot-text>Nouveau bloc de texte</lot-text>')
      editor.commands.focus();
    },
    deleteNode(){
      const { editor, getPos, node } = this;

      const from = getPos();
      const to = from + node.nodeSize;

      editor.commands.deleteRange({ from, to });
      editor.commands.focus();
    },
    duplicateNode(){
      const { editor, getPos, node } = this;

      const end = getPos() + node.nodeSize;

      const innerContent = editor.view.nodeDOM(getPos()).querySelector("#lotText-node-content").innerHTML;

      editor.commands.insertContentAt(end, `<lot-text>${innerContent}</lot-text>`);
      editor.commands.focus();
    },
    plusAction() {
      const { editor, getPos, node } = this;

      const end = getPos() + node.nodeSize;

      editor.chain().insertContentAt(end, {type: "lotParagraph", content: []}).focus().run()
    },
      modalAddTextToBiblioShow() {
          this.modalAddTextToBiblioCancel()
          //kalmStore.commit("toggleBlur", true);
          this.modals.addTextToBiblio.show = true;
          this.modals.addTextToBiblio.textNode = JSON.stringify(this.node);
      },
      modalAddTextToBiblioCancel() {
          //kalmStore.commit("toggleBlur", false);
          this.modals.addTextToBiblio.show = false;
          this.modals.addTextToBiblio.biblioId = null;
          this.modals.addTextToBiblio.textNode = null;
      },
      modalAddTextToBiblioValidate() {
          this.$apollo.mutate({
              mutation: BIBLIO_ADD_TEXT_MUTATION,
              variables: {
                  id: this.modals.addTextToBiblio.biblioId,
                  textNode: this.modals.addTextToBiblio.textNode,
              },
          }).then(() => {
              toast.success('Bloc texte ajouté à la bibliothèque');
              this.modalAddTextToBiblioCancel();
          }).catch(() => {
              toast.error('Erreur lors de l\'ajout du bloc texte à la bibliothèque');
          })
      }
  },
}
</script>

<!--suppress CssInvalidHtmlTagReference -->
<style scoped>

.lot-component-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

.ouvrage-left-actions {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  right: 40px;
}

.ProseMirror-focused:focus {
  outline: none !important;
}
.ProseMirror:focus {
  outline: none !important;
}
.ProseMirror-focused:focus-visible {
  outline: none !important;
}
.ProseMirror:focus-visible {
  outline: none !important;
}

@media print {
  .lot-component-shadow {
    box-shadow: none;
  }

  div .print-margins {
    margin-left: 0 !important;
    padding-left: 15px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .print-border {
    border-left: 1px solid lightgray;
    padding-left: 10px !important;
    margin-bottom: 20px !important;
    margin-top: 15px !important;
    margin-left: 5px !important;
  }

  .ouvrage-left-actions {
    display: none !important;
    visibility: hidden;
  }

  .print-info {
    display: block;
  }

  lotText-node-content > p {
    margin-bottom: 0 !important;
  }
}

</style>

<style>
@media print {
    .lot-ouvrage-content > h4 {
        break-after: avoid;
        break-before: avoid;
    }
    .no-page-break-after {
        page-break-after: avoid !important;
        break-after: avoid !important;
    }
    .no-page-break-before {
        page-break-before: avoid !important;
        break-before: avoid !important;
    }
    .no-page-break-inside {
        page-break-inside: avoid !important;
        break-inside: avoid !important;
    }
    .force-page-break-before {
        page-break-before: always !important;
    }
}
</style>