<template>
    <div class="mt-3 w-100">
        <label class="form-label">Présence</label>
        <div class="w-100 d-flex">
            <template v-for="(option, index) in this.options" :key="option.id">
                <input v-model="this.value" type="radio" class="btn-check" :id="'switch-attendance-'+this.contactId+option.id" :value="option.id">
                <label class="flex-grow-1 btn btn-light"
                       :class="index === 0 ? 'rounded-end-0' : index === this.options.length - 1 ? 'rounded-start-0' : 'rounded-0'"
                       :for="'switch-attendance-'+this.contactId+option.id">{{ option.name }}</label>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "PresenceButton",
    props: {
        modelValue: String,
        contactId: {
            type: String,
            default: "",
        },
    },
    data: () => ({
        options: [
            { id: "P", name: "Présent" },
            { id: "ABS", name: "Absent" },
            { id: "E", name: "Excusé" },
            { id: "R", name: "Représenté" },
        ]
    }),
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    }
}
</script>