<template>
    <IconButton iconName="pencil-square"></IconButton>
</template>

<script>
import IconButton from './IconButton.vue';
export default {
    name: "EditButton",
    components: {
        IconButton
    }
};
</script>