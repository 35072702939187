<template>
    <KalmModal :modalTitle="getTitle()" @close="close">
        <template v-slot:body>
            <form @submit.prevent="createFolder">
                <div class="mb-3">
                    <label for="folder-name" class="col-form-label">Nom</label>
                    <input v-model="folderName" type="text" class="form-control" id="folder-name">
                </div>
            </form>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close">Annuler</button>
            <LoadingButton v-if="isSaving"></LoadingButton>
            <button v-if="!isSaving" type="button" class="btn btn-primary" @click="createFolder"
                :disabled="!canSave()">Sauvegarder</button>
        </template>
    </KalmModal>
</template>

<script>
import KalmModal from '@/views/_core/components/KalmModal.vue';
import LoadingButton from '@/views/_core/components/LoadingButton.vue';
import {
CREATE_DIRECTORY,
FILE_UPDATE,
GET_FILES
} from "../../../data/files_graphql";
export default {
    name: "FolderModal",
    components: {
        KalmModal,
        LoadingButton
    },
    props: {
        folder: Object,
        projectId: {
            type: String,
        },
        parentId: {
            type: String,
        },
    },
    data() {
        return {
            folderName: "",
            isSaving: false,
        };
    },
    created() {
        this.initializeData();
    },
    methods: {
        initializeData() {
            if (this.folder?.id) {
                this.folderName = this.folder.name;
            }
        },
        getTitle() {
            if (this.folder?.id) {
                return 'Renommer'
            }
            return "Créer un dossier"
        },
        canSave() {
            return this.folderName.trim() !== "";
        },
        async createFolder() {
            if (this.canSave()) {
                this.isSaving = true;
                if (this.folder?.id) {
                    await this.$apollo.mutate({
                        mutation: FILE_UPDATE,
                        variables: {
                            fileId: this.folder.id,
                            fileName: this.folderName,
                        },
                        refetchQueries: [
                            {
                                query: GET_FILES,
                                variables: {
                                    projectId: this.projectId,
                                    parentId: this.parentId,
                                }
                            },
                        ],
                    });
                } else {
                    try {
                        await this.$apollo.mutate({
                            mutation: CREATE_DIRECTORY,
                            variables: {
                                name: this.folderName,
                                projectId: this.projectId,
                                parentId: this.parentId,
                            },
                            refetchQueries: [
                                {
                                    query: GET_FILES,
                                    variables: {
                                        projectId: this.projectId,
                                        parentId: this.parentId,
                                    }
                                },
                            ],
                        });
                    } catch (error) {
                        console.error('Directory creation error:', error);
                    }
                }

                this.isSaving = false;
                this.close();
            }
        },
        close() {
            this.$emit("close");
            this.folderName = "";
        },
    },
};
</script>