<template>
    <div class="row g-5 pb-3 p-10">
        <div class="d-flex">
            <h4 class="me-auto">Dossiers</h4>
            <button class="btn btn-primary" @click="onCreateFolderClick">+ Créer</button>
        </div>
        <div v-if="folders.length" class="table-responsive col-12">
            <table class="table table-borderless align-middle">
                <thead>
                    <tr>
                        <th class="px-2 py-0 w-50px"></th>
                        <th class="px-2 py-0 w-100"></th>
                        <th class="px-2 py-0 min-w-100px"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="folder in folders" :key="folder.id" @click="openFolder(folder)" class="bg-hover-light">
                        <td class="px-0 py-3 rounded-start p-5">
                            <div class="symbol symbol-55px ms-5 mt-1 me-5">
                                <span class="symbol-label bg-light-primary align-middle align-items-end">
                                    <i class="bi bi-folder fs-1 align-middle align-self-center"></i>
                                </span>
                            </div>
                        </td>
                        <td class="px-0">
                            <p class="text-gray-800 fw-bolder fs-6 my-1">{{ folder.name }}</p>
                            <span class="text-muted fw-bold d-block mt-1">{{ folder.url }}</span>
                        </td>
                        <td class="px-0 rounded-end">
                            <EditButton @click="onEditFolderClick(folder)"></EditButton>
                            <DeleteButton :disabled="!!folder.type" @click="onDeleteFolderClick(folder)">
                            </DeleteButton>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            <p class="text-center text-muted my-8">
                <span class="bi bi-folder me-2"></span>Aucun dossier. Cliquez sur créer pour en ajouter un<span
                    class="ms-1 bi bi-arrow-up-right"></span>
            </p>
        </div>
    </div>
</template>

<script>
import DeleteButton from "@/views/_core/components/DeleteButton.vue";
import EditButton from "@/views/_core/components/EditButton.vue";
export default {
    name: "FolderList",
    props: {
        folders: {
            type: Array,
            require: true,
        },
        projectId: {
            type: String,
        },
        parentId: {
            type: String,
        },
    },
    components: {
        DeleteButton,
        EditButton,
    },
    methods: {
        onCreateFolderClick() {
            this.$emit("onCreate");
        },
        onEditFolderClick(folder) {
            this.$emit("onEdit", folder);
        },
        onDeleteFolderClick(folder) {
            this.$emit("onDelete", folder);
        },
        openFolder(folder) {
            this.$router.push({
                name: this.projectId ? 'project.documents.folder' : 'documents.folder',
                params: {
                    id: this.projectId,
                    parentId: folder.id,
                },
            });
        },
    }
}
</script>