import gql from 'graphql-tag';

export const GET_COMMENTS = gql`
  query CommentsByTask($taskId: UUID!) {
    commentsByTask(taskId: $taskId) {
        id
        content
        createdAt
        updatedAt
        author {
            firstName
            lastName
            id
            photo
        }
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation CommentCreate($content: String!, $taskId: UUID!) {
    commentCreate(
        content: $content
        taskId: $taskId
    ) {
        comment {
            id
            content
            createdAt
            updatedAt
            author {
                firstName
                lastName
                id
                photo
            }
        }
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation CommentUpdate($id: UUID!, $content: String!, $updatedAt: DateTime) {
    commentUpdate(
        content: $content
        updatedAt: $updatedAt
        id: $id
    ) {
        comment {
            id
            content
            createdAt
            updatedAt
            author {
                firstName
                lastName
                id
                photo
            }
        }
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation CommentDelete($id: UUID!) {
    commentDelete(id: $id) {
        deleted
    }
  }
`;