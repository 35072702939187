<template>
  <node-view-wrapper class="vue-component" style="break-inside: avoid">
<!--    <h3 contenteditable="false" class="mt-5 print-lot-title" :style="computedStyleForTitle">{{this.$props.node.attrs.fullname}}</h3>-->
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper } from '@tiptap/vue-3'
import {CURRENT_USER_QUERY} from "@/graphql/graphql";

export default {
  name: "LotHeadingComponent",
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY
    }
  },
  data: () => ({
    currentUser: {
      org: {
        persoFontBody: "Poppins",
        persoFontTitle: "DM Serif Display",
      }
    }
  }),
  components: {
    NodeViewWrapper,
  },
  computed: {
    computedStyleForTitle() {
      return {
        color: this.currentUser.org.persoColorMain,
        fontFamily: this.currentUser.org.persoFontBody,
      }
    }
  },
  methods: {}
}
</script>

<style scoped>
@media print {
  .print-lot-title {
    font-size: 22px;
    break-after: avoid;
      page-break-after: avoid;
  }
}
</style>

<style>

.ProseMirror-focused:focus {
  outline: none !important;
}
.ProseMirror:focus {
  outline: none !important;
}
.ProseMirror-focused:focus-visible {
  outline: none !important;
}
.ProseMirror:focus-visible {
  outline: none !important;
}

/*.vue-component {
  background: #FAF594;
  border: 3px solid #0D0D0D;
  border-radius: 0.5rem;
  margin: 1rem 0;
  position: relative;
}

.label {
  margin-left: 1rem;
  background-color: #0D0D0D;
  font-size: 0.6rem;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  position: absolute;
  top: 0;
  padding: 0.25rem 0.75rem;
  border-radius: 0 0 0.5rem 0.5rem;
}

.content {
  margin: 2.5rem 1rem 1rem;
  padding: 0.5rem;
  border: 2px dashed #0D0D0D20;
  border-radius: 0.5rem;
}*/
</style>