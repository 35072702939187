<template>
    <div>
        <label class="col-form-label">Présence des intervenants</label>
        <div v-for="contactAttendance in value" :key="contactAttendance.contactId">
            <p class="text-center pb-0 mb-0"><span class="bi bi-person text-muted me-2"></span>{{ getName(contactAttendance.contactId) }}</p>
            <div class="pt-0 mt-0 mb-3">
                <PresenceButton v-model="contactAttendance.attendanceStatus" :contactId="contactAttendance.contactId"></PresenceButton>
            </div>
<!--            <div class="form-check form-switch mb-6">
                <label class="form-check-label" :for="`switch-present-${contactAttendance.contactId}`">Présent</label>
                <input class="form-check-input" type="checkbox" role="switch"
                    :id="`switch-present-${contactAttendance.contactId}`" v-model="contactAttendance.isPresent">
            </div>-->
            <div class="form-check form-switch mb-6">
                <label class="form-check-label" :for="`switch-convoquee-${contactAttendance.contactId}`">Convoquer à la prochaine réunion</label>
                <input class="form-check-input" type="checkbox" role="switch"
                    :id="`switch-convoquee-${contactAttendance.contactId}`" v-model="contactAttendance.isConvoque">
            </div>
        </div>
    </div>
</template>

<script>
import { PROJECT_CONTACTS } from '../../data/contacts_graphql';
import PresenceButton from "@/views/reports/presentation/report-form/components/PresenceButton.vue";

export default {
    name: "ContactsAttendance",
    components: {
        PresenceButton
    },
    props: {
        projectId: String,
        modelValue: Array,
    },
    data() {
        return {
            contacts: [],
            contactsAttendance: [],
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        },
    },
    apollo: {
        contacts: {
            query: PROJECT_CONTACTS,
            fetchPolicy: 'cache-and-network',
            variables() {
                return {
                    projectId: this.projectId,
                };
            },
            skip() {
                return !this.projectId;
            },
        },
    },
    watch: {
        contacts: {
            handler(newContacts) {
                const contactsAttendance = newContacts.map(contact => ({
                    contactId: contact.id,
                    attendanceStatus: this.getAttendanceStatusForContact(contact.id) ?? 'E',
                    isConvoque: this.isConvoqueInModel(contact.id),
                }));
                this.value = contactsAttendance;
            },
        },
    },
    methods: {
        getName(contactId) {
            const contact = this.contacts.filter((contact) => contact.id === contactId);
            if (contact.length) {
                if(contact[0].company && contact[0].name)
                    return `${contact[0].name} (${contact[0].company})`
                else
                    return contact[0]?.company || (contact[0].firstName + (contact[0].firstName && contact[0].name) ? ' ' : '' + contact[0]?.name)
            }
        },
        getAttendanceStatusForContact(contactId) {
            return this.value.find(contact => contact.contactId === contactId)?.attendanceStatus;
        },
        isConvoqueInModel(contactId) {
            return this.value.some(contact => contact.contactId === contactId && contact.isConvoque);
        },
    },
};
</script>
