import { createStore } from 'vuex'

import { displayModule } from "@/store/modules/display";
import { descriptionModule } from "@/store/modules/description";
import { tenderModule } from "@/store/modules/tender";

// Create a new store instance.
const kalmStore = createStore({
    modules: {
        display: displayModule,
        description: descriptionModule,
        tender: tenderModule,
    }
})

export default kalmStore