<template>
    <div>
        <label class="form-label" for="content">Nom</label>
        <input v-model="title" type="text" class="form-control" id="name" />
        <div class="float-end mt-2 pt-1">
            <button v-if="!isSaving" @click="save" :disabled="!canSave()" type="button"
                class="btn btn-primary btn-sm">Ajouter</button>

            <LoadingButton v-if="isSaving"></LoadingButton>
        </div>
    </div>
</template>

<script>
import LoadingButton from '@/views/_core/components/LoadingButton.vue';
import { CREATE_TODO, GET_TODOS, UPDATE_TODO } from '../../data/todos_graphql';

export default {
    name: "TodoForm",
    props: {
        todo: Object,
        projectId: {
            type: String,
            required: false,
        },
        lastOrder: Number,
        assignedToCurrentUser: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            title: "",
            isSaving: false,
        };
    },
    created() {
        this.initializeData();
    },
    methods: {
        initializeData() {
            if (this.todo) {
                this.title = this.todo.title;
            }
        },
        canSave() {
            return this.title?.length;
        },
        async save() {
            this.isSaving = true;
            if (this.todo) {
                try {
                    await this.$apollo.mutate({
                        mutation: UPDATE_TODO,
                        variables: {
                            id: this.todo.id,
                            title: this.title,
                        },
                        refetchQueries: [
                            {
                                query: GET_TODOS,
                                variables: {
                                    projectId: this.projectId,
                                    assignedToCurrentUser: this.assignedToCurrentUser,
                                }
                            },
                        ],
                    });
                }
                catch (error) {
                    console.error('Update todo error:', error);
                }
            }
            else {
                try {
                    await this.$apollo.mutate({
                        mutation: CREATE_TODO,
                        variables: {
                            title: this.title,
                            projectId: this.projectId,
                            order: (this.lastOrder || 0) + 1,
                        },
                        refetchQueries: [
                            {
                                query: GET_TODOS,
                                variables: {
                                    projectId: this.projectId,
                                    assignedToCurrentUser: this.assignedToCurrentUser,
                                }
                            },
                        ],
                    });
                    this.title = "";
                }
                catch (error) {
                    console.error('Create todo error:', error);
                }
            }
            this.isSaving = false;
            this.$emit("close");
        },
    },
    components: { LoadingButton }
}
</script>