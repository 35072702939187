<template>
    <div v-show="this.loadingBlur" class="spinner-border spinner-border-sm loading-spinner" style="filter: none;"></div>
    <div class="h-100" :class="{'blur-content': this.isModalActive, 'loading-blur': this.loadingBlur}">
        <div>
            <ProjectBreadcrumbComponent title="Tâches" current_route="project.tasks"
                                        :project_id="$route.params.id"
                                        :options="[
                                          { name: 'Tâches', id: 'project.tasks' },
                                          { name: 'Compte-rendus', id: 'project.monitoring_new' },
                                          { name: 'Avancement', type: 'link', url: this.project.betaSpreadsheet, disabled: !this.project.betaSpreadsheet },
                                          ]"></ProjectBreadcrumbComponent>
        </div>

        <button class="btn btn-primary btn-sm" @click="createReport">
            Nouveau compte-rendu
        </button>

        <div class="row g-5 mt-2 mb-5">
            <div class="col-12 print-hidden">
                <div class="card">
                    <div class="card-header border-0 pt-5">
                        <h3 class="card-title align-items-start flex-column">
                            <span class="card-label fw-bolder text-dark fs-3">Informations importantes</span>
                        </h3>
                        <div class="d-flex align-middle">
                            <button class="btn btn-primary py-2 my-auto btn-active-accent align-content-center" @click="this.updateProjectProperties()">Enregistrer
                                <span v-if="this.editProject.buttonProjectPropertiesLoading" class="ms-1 spinner-border spinner-border-sm" role="status">
                                  <span class="sr-only">Loading...</span>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="card-body w-100 row g-0 pt-2">
                        <div class="col-12">
                            <div class="form-group w-100 h-100 pb-3 mb-3">
                                <textarea style="height:100%;" v-model="this.projectProperties.importantInfo" class="form-control form-control-lg form-control-solid w-100" type="text"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="d-flex flex-column align-items-center justify-content-between">
            <div class="card-toolbar align-self-center d-flex flex-wrap w-100">
                <div class="ms-1 ms-lg-auto d-flex ms-xs-auto ms-auto me-auto me-lg-0">
                    <ul class="nav nav-pills nav-light">
                        <li class="nav-item m-0">
                            <button
                                class="btn-active-primary btn btn-color-muted rounded-end-0 border border-1 border-dashed border-primary py-2"
                                :class="{ 'active': showByLot, 'border-hover-secondary': !showByLot}"
                                @click="showByLotClick">
                                Par lot
                            </button>
                        </li>
                        <li class="nav-item m-0">
                            <button
                                class="btn-active-primary btn btn-color-muted rounded-start-0 border border-1 border-dashed border-primary py-2"
                                :class="{ 'active': showByLocation, 'border-hover-secondary': !showByLocation }"
                                @click="showByLocationClick">
                                Par emplacement
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="ms-auto d-flex">
                    <button class="btn btn-primary me-2" @click="this.modalSort.show = !this.modalSort.show"><span class="bi bi-sort-alpha-down"></span></button>
                    <input class="form-control form-control-solid bg-white ms-3" v-model="this.taskResearchFilter" placeholder="Rechercher">
                </div>
            </div>




        </div>

        <div>
            <CollapsibleSection viewType="lot" :items="generalTasks" :key="recompute" @delete="deleteTaskConfirm" @duplicate="duplicateTask"
                                @addTask="(item) => createTask(item, 'lot')" @edit="editTask">
            </CollapsibleSection>
        </div>
        <div v-if="showByLot">
            <CollapsibleSection viewType="lot" :items="groupedLots" :key="recompute" @delete="deleteTaskConfirm" @duplicate="duplicateTask"
                                @addTask="(item) => createTask(item, 'lot')" @edit="editTask">
            </CollapsibleSection>
        </div>
        <div v-if="showByLot && !groupedLots?.length">
            <TasksEmptyState @createTask="createTask"></TasksEmptyState>
        </div>
        <div v-if="showByLocation">
            <CollapsibleSection viewType="location" :items="groupedLocations" :key="recompute" @delete="deleteTaskConfirm" @duplicate="duplicateTask"
                                @addTask="(item) => createTask(item, 'location')" @edit="editTask">
            </CollapsibleSection>
        </div>
        <div v-if="showByLocation && !groupedLocations?.length">
            <TasksEmptyState @createTask="createTask"></TasksEmptyState>
        </div>
        <div class="col-12 mt-10 print-hidden">
            <!--begin::Card-->
            <div class="card card-stretch h-100 mh-100 p-2 flex-grow-1">
                <!--begin::Body-->
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                      <span class="card-label fw-bolder text-dark fs-3">
                          <i class="bi fs-3 me-2 bi-gear-wide-connected"></i>Synchronisation descriptif &lt;&gt; chantier
                      </span>
                    </h3>
                </div>
                <div class="card-body pt-3 mt-n3 align-content-center d-flex">
                    <div class="align-self-center my-auto">
                        <label class="me-2">Importer mon descriptif dans l'app de suivi de chantier :</label>
                        <button class="btn btn-light btn-active-accent fw-bolder"
                                @click="this.modalUploadTaskShow()">Lancer l'import
                            <span v-if="this.modalUploadTask.loading" class="ms-2 spinner-border spinner-border-sm"></span>
                        </button>
                    </div>
                </div>
                <!--end::Body-->
            </div>
            <!--end::Card-->
        </div>
    </div>
    <div>
        <div class="modal" tabindex="-1" @keydown.esc="this.modalSortClose()"
             :style="this.modalSort.show ? 'display: block;' : 'display: none;'">
            <div class="modal-dialog modal-dialog-centered mw-600px">
                <div class="modal-content mx-5">
                    <div class="container px-10 py-10">
                        <div class="modal-header p-0 d-flex justify-content-end border-0">
                            <!--begin::Close-->
                            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalSortClose()">
                                <i class="bi bi-x-lg"></i>
                            </div>
                            <!--end::Close-->
                        </div>

                        <div class="modal-body p-0">
                            <!--begin::Stepper-->
                            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                                <!--begin::Content-->
                                <div class="d-flex flex-row-fluid justify-content-center">
                                    <!--begin::Form-->
                                    <div class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                                        <!--begin::Step-->
                                        <div class="pb-5 completed">
                                            <div class="w-100">
                                                <!--begin::Heading-->
                                                <div class="pb-5">
                                                    <h3 class="fw-bolder text-dark display-6">Tri des tâches</h3>
                                                </div>
                                                <!--end::Heading-->

                                                <div class="mt-2">
                                                    <label class="form-label">Trier par :</label>
                                                    <select class="form-select" v-model="this.modalSort.sort1">
                                                        <option value="">Tri par défaut</option>
                                                        <option value="status__order">Statut (croissant)</option>
                                                        <option value="-status__order">Statut (décroissant)</option>
                                                        <option value="start_date">Date de début (croissante)</option>
                                                        <option value="-start_date">Date de début (décroissante)</option>
                                                        <option value="end_date">Date de fin (croissante)</option>
                                                        <option value="-end_date">Date de fin (décroissante)</option>
                                                        <option value="assigned_company__name">Entreprise (croissante)</option>
                                                        <option value="-assigned_company__name">Entreprise (décroissante)</option>
                                                        <option value="updated_date">Dernière modif (croissante)</option>
                                                        <option value="-updated_date">Dernière modif (décroissante)</option>
                                                        <option value="created_date">Date de création (croissante)</option>
                                                        <option value="-created_date">Date de création (décroissante)</option>
                                                        <option value="numero">Numéro de l'ouvrage (croissant)</option>
                                                        <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
                                                        <option value="order">Ordre personnalisé (croissant)</option>
                                                        <option value="-order">Ordre personnalisé (décroissant)</option>
                                                    </select>
                                                </div>

                                                <div class="mt-2">
                                                    <label class="form-label">Puis par :</label>
                                                    <select class="form-select" v-model="this.modalSort.sort2">
                                                        <option value="">Sélectionnez un tri</option>
                                                        <option value="status__order">Statut (croissant)</option>
                                                        <option value="-status__order">Statut (décroissant)</option>
                                                        <option value="start_date">Date de début (croissante)</option>
                                                        <option value="-start_date">Date de début (décroissante)</option>
                                                        <option value="end_date">Date de fin (croissante)</option>
                                                        <option value="-end_date">Date de fin (décroissante)</option>
                                                        <option value="assigned_company__name">Entreprise (croissante)</option>
                                                        <option value="-assigned_company__name">Entreprise (décroissante)</option>
                                                        <option value="numero">Numéro de l'ouvrage (croissant)</option>
                                                        <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
                                                        <option value="order">Ordre personnalisé (croissant)</option>
                                                        <option value="-order">Ordre personnalisé (décroissant)</option>
                                                    </select>
                                                </div>

                                                <div class="mt-2">
                                                    <label class="form-label">Puis par :</label>
                                                    <select class="form-select" v-model="this.modalSort.sort3">
                                                        <option value="">Sélectionnez un tri</option>
                                                        <option value="status__order">Statut (croissant)</option>
                                                        <option value="-status__order">Statut (décroissant)</option>
                                                        <option value="start_date">Date de début (croissante)</option>
                                                        <option value="-start_date">Date de début (décroissante)</option>
                                                        <option value="end_date">Date de fin (croissante)</option>
                                                        <option value="-end_date">Date de fin (décroissante)</option>
                                                        <option value="assigned_company__name">Entreprise (croissante)</option>
                                                        <option value="-assigned_company__name">Entreprise (décroissante)</option>
                                                        <option value="numero">Numéro de l'ouvrage (croissant)</option>
                                                        <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
                                                        <option value="order">Ordre personnalisé (croissant)</option>
                                                        <option value="-order">Ordre personnalisé (décroissant)</option>
                                                    </select>
                                                </div>

                                                <div class="mt-2">
                                                    <label class="form-label">Puis par :</label>
                                                    <select class="form-select" v-model="this.modalSort.sort4">
                                                        <option value="">Sélectionnez un tri</option>
                                                        <option value="status__order">Statut (croissant)</option>
                                                        <option value="-status__order">Statut (décroissant)</option>
                                                        <option value="start_date">Date de début (croissante)</option>
                                                        <option value="-start_date">Date de début (décroissante)</option>
                                                        <option value="end_date">Date de fin (croissante)</option>
                                                        <option value="-end_date">Date de fin (décroissante)</option>
                                                        <option value="assigned_company__name">Entreprise (croissante)</option>
                                                        <option value="-assigned_company__name">Entreprise (décroissante)</option>
                                                        <option value="numero">Numéro de l'ouvrage (croissant)</option>
                                                        <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
                                                        <option value="order">Ordre personnalisé (croissant)</option>
                                                        <option value="-order">Ordre personnalisé (décroissant)</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <!--end::Actions-->
                                    </div>
                                    <!--end::Form-->
                                </div>
                                <!--end::Content-->
                            </div>
                            <!--end::Stepper-->

                            <!--begin::Actions-->
                            <div class="d-flex justify-content-end">
                                <div>
                                    <button class="btn btn-lg btn-light"
                                            @click="modalSortClose()">
                                        Annuler
                                    </button>
                                    <button class="btn btn-lg btn-primary ms-2"
                                            @click="modalSortValidate()"
                                    >
                                        Enregistrer
                                    </button>
                                </div>
                            </div>
                            <!--end::Actions-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteTaskConfirmModal v-if="showDeleteConfirmModal" @close="closeDeleteTaskConfirm" @validate="validateDeleteTaskConfirm" :task-id="selectedTask.id"
                                :project-id="currentProjectId">
        </DeleteTaskConfirmModal>
        <TaskForm v-if="showTaskFormModal" :task="selectedTask" :initial-location-id="selectedLocationId"
                  :initial-lot-id="selectedLotId" :initial-project-id="currentProjectId"
                  @close="closeTaskFormModal" @refetchTasks="refetchTasks"></TaskForm>
        <ReportForm v-if="showReportFormModal" :initial-project-id="currentProjectId" :initialReportNumber="getMaxReportNumber ? getMaxReportNumber+1 : getMaxReportNumber"
                    :taskSortOrder="this.taskSortOrder"
                    @close="closeReportFormModal"></ReportForm>
        <!-- MODAL - CONFIRM UPLOAD TASKS-->
        <div class="modal" tabindex="-1" @keydown.esc="this.modalUploadTaskClose()"
             :style="this.modalUploadTask.show ? 'display: block;' : 'display: none;'">
            <div class="modal-dialog modal-dialog-centered mw-600px">
                <div class="modal-content mx-5">
                    <div class="container px-10 py-10">
                        <div class="modal-header p-0 d-flex justify-content-end border-0">
                            <!--begin::Close-->
                            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalUploadTaskClose()">
                                <i class="bi bi-x-lg"></i>
                            </div>
                            <!--end::Close-->
                        </div>

                        <div class="modal-body p-0">
                            <!--begin::Stepper-->
                            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                                <!--begin::Content-->
                                <div class="d-flex flex-row-fluid justify-content-center">
                                    <!--begin::Form-->
                                    <div ref="contactEditModalForm"
                                         class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                                        <!--begin::Step-->
                                        <div class="pb-5 completed">
                                            <div class="w-100">
                                                <!--begin::Heading-->
                                                <div class="pb-5">
                                                    <h3 class="fw-bolder text-dark display-6">Convertir les ouvrages du descriptif</h3>
                                                    <p class="text-muted mb-1">Choisissez les lots à inclure, tous les ouvrages de ces lots seront transformés en tâches.</p>
                                                    <p class="text-muted"><span class="bi bi-info-circle me-1"></span>Astuce : Assignez une entreprise à un lot depuis le cockpit, pour que les tâches lui soient automatiquement attribuées !</p>
                                                </div>
                                                <!--end::Heading-->

                                                <!--begin::Form Group Options-->
                                                <div class="text-end text-muted">
                                                    <a class="cursor-pointer" @click="modalImportTasksQuickSelect(this.modalUploadTask.lots, 'all')">Tout sélectionner</a>
                                                    /
                                                    <a class="cursor-pointer" @click="modalImportTasksQuickSelect(this.modalUploadTask.lots, 'none')">Aucun</a>
                                                </div>
                                                <div v-for="lot in this.currentProject.lots" :key="lot.id">
                                                    <label class="d-flex flex-stack mb-6 cursor-pointer">
                                                      <span class="d-flex align-items-center me-2">
                                                        <span class="d-flex flex-column">
                                                          <span class="fs-6" :for="lot.id">{{ lot.fullName }}</span>
                                                        </span>
                                                      </span>
                                                                                    <span class="form-check form-check-custom form-check-solid">
                                                        <input class="form-check-input" type="checkbox" :value="lot.id" :id="lot.id" v-model="this.modalUploadTask.lots">
                                                      </span>
                                                    </label>
                                                </div>
                                                <!--end::Form Group Options-->
                                            </div>
                                        </div>
                                        <!--end::Step-->

                                        <!--begin::Actions-->
                                        <div class="d-flex justify-content-end">
                                            <div>
                                                <button class="btn btn-lg btn-light"
                                                        @click="modalUploadTaskClose()">
                                                    Annuler
                                                </button>
                                                <button class="btn btn-lg btn-primary ms-2"
                                                        @click="modalUploadTaskValidate()"
                                                >
                                                    Importer
                                                </button>
                                            </div>
                                        </div>
                                        <!--end::Actions-->
                                    </div>
                                    <!--end::Form-->
                                </div>
                                <!--end::Content-->
                            </div>
                            <!--end::Stepper-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProjectBreadcrumbComponent from "@/views/project/ProjectBreadcrumbComponent.vue";
import ReportForm from "@/views/reports/presentation/report-form/ReportForm.vue";
import {
    CREATE_TASK,
    GET_TASKS
} from "../../data/tasks_graphql";
import TaskForm from "../task-form/TaskForm.vue";
import CollapsibleSection from "./components/CollapsibleSection.vue";
import DeleteTaskConfirmModal from "./components/DeleteTaskConfirmModal.vue";
import TasksEmptyState from "./components/TasksEmptyState.vue";
import {ALL_STATUSES_QUERY} from "@/views/status/data/status_graphql";
import {CURRENT_USER_QUERY, GET_PROJECT_BY_ID, MUTATION_PROJECT_UPLOAD_TASKS} from "@/graphql/graphql";
import kalmStore from "@/store";
import {GET_REPORTS} from "@/views/reports/data/reports_graphql";
import {toast} from "vue3-toastify";

export default {
    name: "TasksPage",
    components: {
        ProjectBreadcrumbComponent,
        CollapsibleSection,
        TasksEmptyState,
        DeleteTaskConfirmModal,
        TaskForm,
        ReportForm,
    },
    apollo: {
        currentUser: {
            query: CURRENT_USER_QUERY,
            result({data}){
                this.modalSort.sort1 = data.currentUser.org.persoTaskSort1
                this.modalSort.sort2 = data.currentUser.org.persoTaskSort2
                this.modalSort.sort3 = data.currentUser.org.persoTaskSort3
                this.modalSort.sort4 = data.currentUser.org.persoTaskSort4
            }
        },
        project: {
            query: GET_PROJECT_BY_ID,
            variables() {
                return {
                    id: this.currentProjectId,
                }
            },
            skip() {
                return !(this.currentProjectId
                    && this.currentProjectId !== "0"
                    && this.currentProjectId !== 0)
            }
        },
        statuses: {
            query: ALL_STATUSES_QUERY,
        },
        tasks: {
            query: GET_TASKS,
            variables() {
                return {
                    projectId: this.currentProjectId,
                    taskSortOrder: this.taskSortOrder
                }
            },
            skip() {
                return !this.currentProjectId
            },
        },
        reports: {
            query: GET_REPORTS,
            fetchPolicy: 'cache-and-network',
            variables() {
                return {
                    projectId: this.currentProjectId
                }
            },
            skip() {
                return !this.currentProjectId
            },
        }
    },
    data() {
        return {
            recompute: 0,
            initialLoading: true,
            project: {},
            tasks: [],
            modalSort: {
                show: false,
                sort1: null,
                sort2: null,
                sort3: null,
                sort4: null,
            },
            modalUploadTask: {
                show: false,
                loading: false,
            },
            editProject: {
                buttonProjectPropertiesLoading: false,
                importantInfo: "",
            },
            taskResearchFilter: "",
            showByLot: true,
            showByLocation: false,
            showDeleteConfirmModal: false,
            showTaskFormModal: false,
            showReportFormModal: false,
            selectedTask: undefined,
            selectedLocationId: undefined,
            selectedLotId: undefined,
        };
    },
    computed: {
        taskSortOrder() {
            return [this.modalSort.sort1, this.modalSort.sort2, this.modalSort.sort3, this.modalSort.sort4].filter(s => s);
        },
        loadingBlur() {
            return this.initialLoading && this.$apollo.queries.tasks.loading && this.$apollo.queries.project.loading;
        },
        getMaxReportNumber() {
            try {
                return this.reports.reduce((max, report) => report?.reportNumber > max ? report?.reportNumber : max, 0);
            }
            catch {
                return null
            }
        },
        currentProjectId() {
            return kalmStore.getters.currentProjectFocus;
        },
        isModalActive() {
            return this.showDeleteConfirmModal | this.showTaskFormModal | this.showReportFormModal || this.modalSort.show;
        },
        generalTasks() {
            const groupedLots = {};

            function researchFilter(task={}, taskResearchFilter="") {
                return (task?.title || "").toLowerCase().includes(taskResearchFilter.toLowerCase())
                    || (task?.description || "").toLowerCase().includes(taskResearchFilter.toLowerCase())
                    || (task?.numero || "").toLowerCase().includes(taskResearchFilter.toLowerCase())
            }

            if (this.tasks && this.tasks.length > 0) {
                groupedLots[0] = {
                    id: null,
                    name: "Tâches générales",
                    tasks: [],
                };

                this.tasks
                    .filter(t => !t?.lot)
                    .filter(t => researchFilter(t, this.taskResearchFilter)).forEach((task) => {
                    groupedLots[0].tasks.push(task);
                });
            }

            return Object.values(groupedLots).sort((a, b) => a.number.localeCompare(b.number, undefined, {numeric: true}));

        },
        groupedLots() {
            const groupedLots = {};

            function researchFilter(task={}, taskResearchFilter="") {
                return (task?.title || "").toLowerCase().includes(taskResearchFilter.toLowerCase())
                    || (task?.description || "").toLowerCase().includes(taskResearchFilter.toLowerCase())
                    || (task?.numero || "").toLowerCase().includes(taskResearchFilter.toLowerCase())
                    || (task?.lot?.fullName || "").toLowerCase().includes(taskResearchFilter.toLowerCase())
            }

            if (this.tasks && this.tasks.length > 0) {
                this.tasks
                    .filter(t => t?.lot)
                    .filter(t => researchFilter(t, this.taskResearchFilter)).forEach((task) => {
                    const lotId = task.lot.id;

                    if (!groupedLots[lotId]) {
                        groupedLots[lotId] = {
                            id: lotId,
                            name: task.lot.fullName,
                            number: task.lot.number,
                            tasks: [],
                        };
                    }
                    groupedLots[lotId].tasks.push(task);
                });
            }

            return Object.values(groupedLots).sort((a, b) => a.number.localeCompare(b.number, undefined, {numeric: true}));
        },
        groupedLocations() {
            const groupedLocations = {};

            if (this.tasks && this.tasks.length > 0) {
                this.tasks.forEach((task) => {
                    task.locations.forEach((location) => {
                        const locationId = location.id;

                        if (!groupedLocations[locationId]) {
                            groupedLocations[locationId] = {
                                id: locationId,
                                name: location.name,
                                tasks: [],
                            };
                        }
                        groupedLocations[locationId].tasks.push(task);
                    });
                });
            }

            // Convert the groupedLocations object to an array
            return Object.values(groupedLocations).sort((a, b) => a.name.localeCompare(b.name));
        },
        currentProject(){
            return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus)
        },
        projectProperties: {
            get() {
                return {
                    importantInfo: this.editProject.importantInfo || this.currentProject.importantInfo,
                }
            },
            set(newValue) {
                this.editProject = newValue;
            }
        },
    },
    watch: {
        $route(to) {
            this.projectId = to.params.id;
        },
    },
    methods: {
        refetchTasks() {
            console.log("refetch")
            this.$apollo.queries.tasks.refetch()
        },
        modalSortClose(){
            this.modalSort.show = false;
        },
        modalSortValidate(){
            console.log("shuffle", this.taskSortOrder)
            this.$apollo.queries.tasks.refetch();
            this.modalSortClose()
        },
        modalImportTasksQuickSelect(lots, mode){
            if(mode === 'all') {
                lots.length = 0;
                this.currentProject.lots.forEach(l => {
                    lots.push(l.id)
                })
            }
            else {
                lots.length = 0
            }
        },
        modalUploadTaskShow() {
            this.modalUploadTask.show = true;
            this.modalUploadTask.lots = [];
        },
        modalUploadTaskValidate() {
            this.modalUploadTaskClose();
            this.uploadTasks();
        },
        modalUploadTaskClose() {
            this.modalUploadTask.show = false;
        },
        uploadTasks() {
            this.modalUploadTask.loading = true;
            this.$apollo.mutate(
                {
                    mutation: MUTATION_PROJECT_UPLOAD_TASKS,
                    variables: {
                        projectId: this.currentProjectId,
                        mode: "TaskChantier",
                        lots: this.modalUploadTask.lots,
                    },
                    skip() {
                        return !(this.currentProjectId
                            && this.currentProjectId !== "0"
                            && this.currentProjectId !== 0)
                    },
                }
            ).then(() => {
                this.modalUploadTask.loading = false;
                toast.success("Les tâches ont été importées avec succès !")
                this.refetchTasks();
            }).catch(() => {
                this.modalUploadTask.loading = false;
                toast.error("Une erreur est survenue lors de l'import des tâches.")
            })
        },
        duplicateTask(duplicateFrom) {
            try {
                this.$apollo.mutate({
                    mutation: CREATE_TASK,
                    variables: {
                        input: {
                            projectId: this.currentProjectId,
                        },
                        duplicateFrom: duplicateFrom.id,
                    },
                    update: () => {
                        this.refetchTasks()
                    }
                })
            } catch (error) {
                console.error('Duplicate task error:', error);
            }
            //this.refetchTasks()
        },
        showByLotClick() {
            this.showByLocation = false;
            this.showByLot = true;
        },
        showByLocationClick() {
            this.showByLocation = true;
            this.showByLot = false;
        },
        editTask(task) {
            this.selectedTask = task;
            this.showTaskFormModal = true;
        },
        createTask(item, itemType) {
            this.selectedLocationId = undefined;
            this.selectedLotId = undefined;

            // noinspection EqualityComparisonWithCoercionJS
            if (itemType == 'location') {
                this.selectedLocationId = item.id;
            } else {
                // noinspection EqualityComparisonWithCoercionJS
                if (itemType == 'lot') {
                    this.selectedLotId = item.id;
                }
            }
            this.selectedTask = undefined;
            this.showTaskFormModal = true;
        },
        closeTaskFormModal() {
            this.$apollo.queries.tasks.refetch()
            this.showTaskFormModal = false;
        },
        createReport() {
            this.showReportFormModal = true;
        },
        closeReportFormModal() {
            this.showReportFormModal = false;
        },
        deleteTaskConfirm(task) {
            this.selectedTask = task;
            this.showDeleteConfirmModal = true;
        },
        closeDeleteTaskConfirm() {
            this.selectedTask = undefined;
            this.showDeleteConfirmModal = false;
        },
        validateDeleteTaskConfirm(){
            this.refetchTasks();
        },
        updateProjectProperties() {
            this.editProject.buttonProjectPropertiesLoading = true;

            setTimeout(() => {
                this.editProject.buttonProjectPropertiesLoading = false;
            }, 350);

            const apolloClient = this.$apollo;
            const newProperties = {
                importantInfo: this.projectProperties.importantInfo,
            }

            kalmStore.dispatch("updateProjectProperties", { apolloClient, newProperties })
        },
    },
}
</script>

<style scoped>
    .loading-blur {
        filter: blur(5px);
    }

    .loading-spinner {
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }

</style>