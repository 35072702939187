<template>
<!--begin::Main-->
  <!--begin::Root-->
    <div v-if="this.updateExists" class="d-flex justify-content-center align-items-baseline bg-primary text-white w-100 p-2">
        <p class="text-center align-baseline">✨ Une version plus récente de Kalm est disponible !</p>
        <button class="btn btn-sm btn-light btn-active-accent ms-4" @click="this.refreshApp()">Actualiser la page</button>
    </div>
  <div class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="page d-flex flex-row flex-column-fluid">
      <!--begin::Aside-->
      <MainMenu></MainMenu>
      <!--end::Aside-->
      <!--begin::Wrapper-->
        <div class="wrapper print-no-padding d-flex flex-column flex-row-fluid" :class="{'blur-content': expiredSessionModalToggled}" id="kt_wrapper">
        <!--begin::Header-->
        <MainHeader></MainHeader>
        <!--end::Header-->
        <!--begin::Main-->
        <div class="d-flex flex-column flex-column-fluid">
          <!--begin::Content-->
          <div class="content fs-6 d-flex flex-column-fluid" id="kt_content">
            <!--begin::Container-->
            <div class="container-fluid mx-0">
              <router-view></router-view>
            </div>
            <!--end::Container-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Main-->
      </div>
        <template v-if="expiredSessionModalToggled">
            <!-- MODAL - Force Save -->
            <div class="modal print-hidden" :class="{'show': expiredSessionModalToggled, 'd-block': expiredSessionModalToggled}">
                <div class="modal-dialog modal-dialog-centered mw-600px">
                    <div class="modal-content mx-5">
                        <div class="container px-10 py-10">
                            <div class="modal-header p-0 d-flex justify-content-end border-0">
                                <!--begin::Close-->
                                <div class="btn btn-icon btn-sm btn-light-primary" @click="this.$store.commit('toggleExpiredSessionModal', {mode: 'close'})">
                                    <i class="bi bi-x-lg"></i>
                                </div>
                                <!--end::Close-->
                            </div>

                            <div class="modal-body p-0">
                                <!--begin::Content-->
                                <div class="d-flex flex-row-fluid justify-content-center">
                                    <!--begin::Form-->
                                    <div class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                                        <!--begin::Step-->
                                        <div class="pb-5 completed">
                                            <div class="w-100">
                                                <!--begin::Heading-->
                                                <div class="pb-5">
                                                    <h3 class="fw-bolder text-dark display-6">Votre session vient d'expirer</h3>
                                                    <p class="">Cliquez sur "Me reconnecter", entrez vos identifiants puis revenez sur cette page.</p>
                                                </div>
                                                <!--end::Heading-->
                                            </div>
                                        </div>
                                        <!--end::Step-->

                                        <!--begin::Actions-->
                                        <div class="d-flex justify-content-end">
                                            <div>
                                                <button class="btn btn-lg btn-light"
                                                        @click="this.$store.commit('toggleExpiredSessionModal', {mode: 'toggleLoginWindow'})">
                                                    1 - Me reconnecter
                                                </button>
                                                <button class="btn btn-lg btn-light ms-2"
                                                        @click="this.$store.commit('toggleExpiredSessionModal', {mode: 'close'})"
                                                >
                                                    2 - C'est bon
                                                </button>
                                            </div>
                                        </div>
                                        <!--end::Actions-->
                                    </div>
                                    <!--end::Form-->
                                </div>
                                <!--end::Content-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <!--end::Wrapper-->
    </div>
    <!--end::Page-->
  </div>
  <!--end::Root-->
</template>

<script>
import MainMenu from "@/views/_core/MainMenu"
import MainHeader from "@/views/_core/MainHeader";

export default {
  name: "App",
    data() {
      return {
        refreshing: false,
        registration: null,
        updateExists: false,
      };
    },
    created() {
        // Listen for swUpdated event and display refresh snackbar as required.
        document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
        console.log('event listener swUpdated added')
        // Refresh all open app tabs when a new service worker is installed.
        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            });
            console.log('event listener controllerchange added')
        }
    },
    components: {
    MainHeader,
    MainMenu,
  },
    computed: {
      expiredSessionModalToggled() {
          return this.$store.state.display.expiredSessionModalToggled
      }
    },
    methods: {
        showRefreshUI (e) {
            this.registration = e.detail;
            this.updateExists = true;
        },
        refreshApp() {
            console.log('refresh app button clicked')
            // Protect against missing registration.waiting.
            if (!this.registration || !this.registration.waiting) { console.log("prevent update", !this.registration, !this.registration.waiting); return; }
            this.registration.waiting.postMessage('skipWaiting');
        },
    }
}
</script>

<style scoped>
@media print {
  .print-no-padding {
    padding-left: 0 !important;
  }
}
</style>

<style lang="scss">
  @use '@/sass/style.scss';

  .blur-content{
    filter: blur(5px);
  }

  * {
      -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
      color-adjust: exact !important;                 /* Firefox 48 – 96 */
      print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
  }

</style>