<template>
    <div class="row g-5 pb-3 p-10">
        <input ref="fileInput" type="file" style="display: none" @change="handleFileChange" multiple />
        <div class="d-flex">
            <h4 class="me-auto">Fichiers</h4>
            <button class="btn btn-primary" @click="this.openFileExplorer()">+ Ajouter</button>
        </div>
        <div v-if="files.length" class="table-responsive col-12">
            <table class="table table-borderless align-middle">
                <thead>
                    <tr>
                        <th class="px-2 py-0 w-50px min-w-100px"></th>
                        <th class="px-2 py-0 w-100"></th>
                        <th class="px-2 py-0 min-w-250px"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="file in files" :key="file.id" class="bg-hover-light cursor-default">
                        <td class="px-0 py-3 rounded-start p-5">
                            <div v-if="getFileIconClass(file) === 'bi bi-file-image'" class="symbol symbol-55px ms-5 mt-1 me-5">
                                <img :src="getFileUrl(file)" class="img-fluid img-thumbnail mw-100 mh-100 object-fit-cover" alt="file" />
                            </div>
                            <div v-else class="symbol symbol-55px ms-5 mt-1 me-5">
                                <span class="symbol-label bg-light-primary align-middle align-items-end">
                                    <i class="fs-1 align-middle align-self-center" :class="getFileIconClass(file)"></i>
                                </span>
                            </div>
                        </td>
                        <td class="px-0">
                            <p class="text-gray-800 fw-bolder fs-6 my-1">{{ file.name }}</p>
                            <span class="text-muted fw-bold d-block mt-1">Créé le {{ new
                                Date(file.creationDate).toLocaleString() }}</span>
                            <TaskTagList v-if="file.tasks?.length" :tasks="file.tasks" class="mt-2"></TaskTagList>
                        </td>
                        <td class="px-0 rounded-end">
                            <EditButton @click="onEditFileClick(file)"></EditButton>
                            <IconButton v-if="projectId" iconName="list-task" @click="onAssignTaskClick(file)"></IconButton>
                            <DeleteButton @click="onDeleteFileClick(file)"></DeleteButton>
                            <IconButton iconName="download" @click="downloadFile(file)"></IconButton>
                            <IconButton iconName="arrow-up-right" @click="openFileInNewTab(file)"></IconButton>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="py-10">
            <p class="text-center text-muted my-12">
                <span class="bi bi-file-earmark me-2"></span>Aucun fichier dans ce dossier. Cliquez sur ajouter<span
                    class="ms-1 bi bi-arrow-up-right"></span>
            </p>
        </div>
    </div>
</template>

<script>
import DeleteButton from "@/views/_core/components/DeleteButton.vue";
import EditButton from "@/views/_core/components/EditButton.vue";
import IconButton from "@/views/_core/components/IconButton.vue";
import TaskTagList from "@/views/tasks/presentation/task-tag-list/TaskTagList.vue";
import {
FILE_UPLOAD,
GET_FILES
} from "../../../data/files_graphql";

export default {
    name: "FolderList",
    props: {
        files: {
            type: Array,
            require: true,
        },
        projectId: {
            type: String,
        },
        parentId: {
            type: String,
        },
    },
    components: {
        DeleteButton,
        EditButton,
        TaskTagList,
        IconButton,
    },
    methods: {
        getFileUrl(file) {
            if (file.url.startsWith("http")) {
                return file.url;
            }
            return process.env.VUE_APP_URL_BACK + file.url
        },
        getFileIconClass(file) {
            const extension = file.url.split(".").pop();
            const iconMapping = {
                jpg: "bi bi-file-image",
                jpeg: "bi bi-file-image",
                png: "bi bi-file-image",
                svg: "bi bi-file-image",
                pdf: "bi bi-file-pdf",
                doc: "bi bi-file-word",
                docx: "bi bi-file-word",
                xls: "bi bi-file-excel",
                xlsx: "bi bi-file-excel",
                default: "bi bi-file-earmark", // Default icon for other file types
            };
            return iconMapping[extension.toLowerCase()] || iconMapping.default;
        },
        openFileInNewTab(file) {
            window.open(this.getFileUrl(file), '_blank')
        },
        downloadFile(file) {
            fetch(this.getFileUrl(file))
                .then(response => response.blob())
                .then(blob => {
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = file?.name || "download";
                    link.click();
                })
                .catch(console.error);
        },
        onEditFileClick(file) {
            this.$emit("onEdit", file);
        },
        onAssignTaskClick(file) {
            this.$emit("onAssignTask", file);
        },
        onDeleteFileClick(folder) {
            this.$emit("onDelete", folder);
        },
        openFileExplorer() {
            // Trigger the click event on the hidden file input
            this.$refs.fileInput.click();
        },
        async handleFileChange(event) {
            const selectedFiles = event.target.files;
            for (const file of selectedFiles) {
                try {
                    await this.$apollo.mutate({
                        mutation: FILE_UPLOAD,
                        variables: {
                            file,
                            projectId: this.projectId,
                            parentId: this.parentId,
                        },
                        context: {
                            hasUpload: true,
                        },
                        refetchQueries: [
                            {
                                query: GET_FILES,
                                variables: {
                                    projectId: this.projectId,
                                    parentId: this.parentId,
                                },
                            },
                        ],
                    });
                } catch (error) {
                    console.error('File upload error:', error);
                }
            }
        },
    }
}
</script>