<template>
    <KalmModal modalTitle="Editer le commentaire" @close="close">
        <template v-slot:body>
            <CommentForm :comment="comment" :taskId="taskId" @save="close"></CommentForm>
        </template>
    </KalmModal>
</template>

<script>
import KalmModal from '@/views/_core/components/KalmModal.vue';
import CommentForm from './CommentForm.vue';
export default {
    name: "CommentFormModal",
    props: {
        comment: Object,
        taskId: {
            type: String,
            required: true,
        },
    },
    components: {
        KalmModal,
        CommentForm,
    },
    methods: {
        close() {
            this.$emit("close");
        },
    },
}
</script>