<template>
    <div class="row g-3 print-hidden">
        <!-- REPORTS LIST -->
        <div class="col-12">
            <div class="card card-stretch">
                <!--begin::Header-->
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder text-dark fs-3">Comptes-rendus</span>
                    </h3>
                    <div class="card-toolbar">
                        <ul class="nav nav-pills nav-pills-sm nav-light">
                            <li class="nav-item">
                                <a class="nav-link btn btn-primary fw-bolder me-2" @click="this.$router.push({name: 'project.tasks', params: {id: this.$route.params.id}})">Créer un compte-rendu</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-3 mt-n3">
                    <div class="tab-content mt-4" id="myTabTables2">
                        <!--begin::Table-->
                        <div class="table-responsive">
                            <div v-if="this.reports.length">
                                <table class="table table-borderless align-middle">
                                    <thead>
                                    <tr>
                                        <th class="px-2 py-0 w-50px"></th>
                                        <th class="px-2 py-0 w-100"></th>
                                        <th></th>
                                        <th class="px-2 py-0 min-w-100px"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="report in this.reports" :key="report.id" class="bg-hover-light" @click="openReport(report)">
                                            <td class="px-0 py-3 rounded-start p-5">
                                                <div class="symbol symbol-55px ms-5 mt-1 me-5">
                                                    <span class="symbol-label bg-light-primary align-middle align-items-end">
                                                      <i class="bi bi-file-check-fill fs-1 align-middle align-self-center"></i>
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="px-0">
                                                <p v-if="report.reportName" class="text-gray-800 fw-bolder fs-6 my-1">Compte-rendu de chantier n°{{ report.reportNumber }}</p>
                                                <p v-if="report.reportDate" class="text-muted">{{report.reportDate}}</p>
                                            </td>
                                            <td></td>
                                            <td class="px-0 rounded-end">
                                                <EditButton @click="updateReport(report)"></EditButton>
                                                <DeleteButton @click="openDeleteConfirmModal(report)"></DeleteButton>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-else>
                                <p v-if="this.$apollo.queries.reports.loading" class="text-center">
                                    Chargement...
                                </p>
                                <p v-else class="text-center text-muted my-8">
                                    <span class="bi bi-folder me-2"></span>Aucun compte-rendu. Cliquez sur créer un compte-rendu pour en ajouter un<span class="ms-1 bi bi-arrow-up-right"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--end::Table-->
                </div>
                <!--end::Body-->
            </div>
        </div>
        <!-- OLD REPORTS LIST -->
<!--        <div v-for="report in this.reports" :key="report.id" class="col-12 col-sm-6 col-md-4">
            <div class="card card-stretch my-2 h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <IconButton iconName="file-text" @click="openReport(report)"></IconButton>
                        <EditButton @click="updateReport(report)"></EditButton>
                        <DeleteButton @click="openDeleteConfirmModal(report)"></DeleteButton>
                        <div>
                            <a v-show="report.reportName !== null" class="fs-4 text-gray-800 fw-bolder">Compte-rendu de
                                chantier n°{{ report.reportNumber }}</a>
                            <div v-show="report.reportDate !== null" class="fs-7 text-muted fw-bold mt-1">{{
                                report.reportDate }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
        <ReportForm v-if="showReportFormModal" :report="selectedReport" :initial-project-id="projectId"
            @close="closeReportFormModal"></ReportForm>
        <DeleteReportConfirmModal v-if="showDeleteConfirmModal" :projectId="projectId" :reportId="selectedReport.id"
            @close="closeDeleteConfirmModal">
        </DeleteReportConfirmModal>
    </div>
</template>

<script>
import DeleteButton from '@/views/_core/components/DeleteButton.vue';
import EditButton from '@/views/_core/components/EditButton.vue';
import { GET_REPORTS } from '../../data/reports_graphql';
import ReportForm from '../report-form/ReportForm.vue';
import DeleteReportConfirmModal from './components/DeleteReportConfirmModal.vue';

export default {
    name: "ReportsList",
    components: {
        ReportForm,
        DeleteReportConfirmModal,
        EditButton,
        DeleteButton
    },
    props: {
        projectId: String,
    },
    apollo: {
        reports: {
            query: GET_REPORTS,
            fetchPolicy: 'cache-and-network',
            variables() {
                return {
                    projectId: this.projectId
                }
            },
            skip() {
                return !this.projectId
            },
        }
    },
    data() {
        return {
            reports: [],
            showDeleteConfirmModal: false,
            showReportFormModal: false,
            selectedReport: undefined,
        }
    },
    methods: {
        openReport(report) {
            if (report.file?.url) {
                window.open(report.file.url);
            }
        },
        updateReport(report) {
            this.selectedReport = report;
            this.showReportFormModal = true;
        },
        closeReportFormModal() {
            this.showReportFormModal = false;
        },
        closeDeleteConfirmModal() {
            this.showDeleteConfirmModal = false;
        },
        openDeleteConfirmModal(report) {
            this.selectedReport = report;
            this.showDeleteConfirmModal = true;
        },
    },
};
</script>