import Image from '@tiptap/extension-image';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import CustomImageComponent from './CustomImageComponent.vue';

export default Image.extend({
    name: 'image',

    addAttributes() {
        return {
            ...this.parent?.(),

            fileId: {
                default: null,
            },

            width: {
                default: '100%',
                renderHTML: (attributes) => {
                    return {
                        width: attributes.width
                    };
                }
            },

            height: {
                default: 'auto',
                renderHTML: (attributes) => {
                    return {
                        height: attributes.height
                    };
                }
            },
        };
    },

    addCommands() {
        return {
            ...this.parent?.(),

            setSmall: () => ({ commands }) => {
                return commands.updateAttributes({
                    width: '20%',
                });
            },
            setMiddle: () => ({ commands }) => {
                return commands.updateAttributes('image', null, {
                    width: '50%',
                });
            },
            setBig: () => ({ commands }) => {
                return commands.updateAttributes('image', null, {
                    width: '80%',
                });
            },
        };
    },

    addNodeView() {
        return VueNodeViewRenderer(CustomImageComponent);
    }
});