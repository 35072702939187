<template>
    <KalmModal modalTitle="Photos & pièces-jointes" @close="close">
        <template v-slot:body>
            <div class="mt-4 mb-10">
                <label>Ajouter une photo :</label>
                <input class="form-control" type="file" @change="handleFileChange" accept="image/*" multiple>
            </div>
            <div v-if="todoItem.files.length > 0">
                <p class="fw-bold text-gray-700">Photos liées à la tâche :</p>
                <div class="d-row">
                    <div class="position-relative d-inline-block m-3" v-for="file in todoItem.files" :key="file.id">
                        <img class="rounded shadow shadow-xs w-auto" alt="Image non décrite inclue sur la tâche" :src="buildFullUrl(file.url)" height=100>
                        <button class="btn btn-icon btn-sm btn-primary position-absolute"
                                style="top: -10px; right: -10px;"
                                @click="removeFileFromTodoItem(file.id)">
                            <span class="bi bi-x"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div v-else class="">
                <p>Aucune photo liée à cette tâche</p>
            </div>
        </template>
    </KalmModal>
</template>

<script>
import KalmModal from '@/views/_core/components/KalmModal.vue';
import { FILE_UPLOAD } from "@/views/documents/data/files_graphql";
import { GET_TODO_ITEM_BY_ID, UNASSIGN_TODO_ITEM } from "@/views/todos/data/todos_graphql";

export default {
    name: "FilesForTodoItemModal",
    apollo: {
        todoItem: {
            query: GET_TODO_ITEM_BY_ID,
            variables() {
                return {
                    todoItemId: this.todoItemId,
                }
            },
            skip() {
                return !this.todoItemId;
            },
        },
    },
    data() {
        return {
            todoItem: {
                files: [],
            },
        };
    },
    props: {
        todoItemId: {
            type: String,
            required: true,
        },
    },
    components: {
        KalmModal,
    },
    methods: {
        removeFileFromTodoItem(fileId) {
            this.$apollo.mutate({
                mutation: UNASSIGN_TODO_ITEM,
                variables: {
                    fileId: fileId,
                    todoItemToRemoveId: this.todoItemId,
                },
                context: {
                    hasUpload: true,
                },
                refetchQueries: [
                    {
                        query: GET_TODO_ITEM_BY_ID,
                        variables: {
                            todoItemId: this.todoItemId,
                        },
                        skip() {
                            return !this.todoItemId;
                        },
                    },
                ],
            });
        },
        close() {
            this.$emit("close");
        },
        buildFullUrl(url){
            if (url.startsWith("http")) {
                return url;
            }
            return process.env.VUE_APP_URL_BACK + url
        },
        openFileExplorer() {
            // Trigger the click event on the hidden file input
            this.$refs.fileInput.click();
        },
        async handleFileChange(event) {
            const selectedFiles = event.target.files;
            for (const file of selectedFiles) {
                try {
                    await this.$apollo.mutate({
                        mutation: FILE_UPLOAD,
                        variables: {
                            file,
                            projectId: this.projectId,
                            todoItemId: this.todoItemId,
                            type: "MONITORING",
                        },
                        context: {
                            hasUpload: true,
                        },
                        refetchQueries: [
                            {
                                query: GET_TODO_ITEM_BY_ID,
                                variables: {
                                    todoItemId: this.todoItemId,
                                },
                                skip() {
                                    return !this.todoItemId;
                                },
                            },
                        ],
                    });
                } catch (error) {
                    console.error('File upload error:', error);
                }
            }
        },
    },
    computed: {
        projectId() {
            return this.$store.getters.currentProjectFocus;
        },
    }
}
</script>