<template>
    <IconButton iconName="trash"></IconButton>
</template>

<script>
import IconButton from './IconButton.vue';
export default {
    name: "DeleteButton",
    components: {
        IconButton
    }
};
</script>