<template>
  <div class="h-100">
    <div class="d-flex mb-2">
      <IconButton v-if="parentId" iconName="arrow-left" @click="openParentFolder"></IconButton>
      <ProjectBreadcrumbComponent v-if="projectId" :title="parentId ? this.folder?.name : 'Documents'" current_route="project.documents"
        :project_id="$route.params.id">
      </ProjectBreadcrumbComponent>
    </div>
    <div class="row g-5">
      <div class="card col-12">
        <!-- DOSSIERS -->
        <FolderList :folders="getFolders" @onCreate="onCreateFolderClick" @onEdit="modalEditFileOpen"
          @onDelete="modalConfirmDeleteFileOpen" :projectId="projectId" :parentId="parentId">
        </FolderList>
      </div>
      <div class="card col-12">
        <!-- FICHIERS -->
        <FileList :files="getFiles" @onEdit="modalEditFileOpen" @onDelete="modalConfirmDeleteFileOpen"
          @onAssignTask="assignTask" :projectId="projectId" :parentId="parentId"></FileList>
      </div>
    </div>
  </div>
  <div>
    <!-- CREATE FOLDER / EDIT FILE NAME -->
    <FolderModal v-if="modals.folderModal.show" @close="onCloseFolderModal" :projectId="projectId" :parentId="parentId"
      :folder="modals.folderModal.file">
    </FolderModal>
    <!-- ASSIGN TASK -->
    <TaskSelectModal v-if="projectId && modals.assignTaskModal.show" :initiallySelectedTaskIds="modals.assignTaskModal.file.tasks"
      :projectId="modals.assignTaskModal.file.project.id" @close="closeAssignTaskModal" @save="saveAssignedTasks">
    </TaskSelectModal>
    <!-- DELETE FILE -->
    <DeleteFileConfirmModal v-if="modals.confirmDeleteFileModal.show" :file-id="modals.confirmDeleteFileModal.file.id"
      :project-id="projectId" :parent-id="parentId" @close="modalConfirmDeleteFileClose">
    </DeleteFileConfirmModal>
  </div>
</template>

<script>
import IconButton from "@/views/_core/components/IconButton.vue";
import ProjectBreadcrumbComponent from "@/views/project/ProjectBreadcrumbComponent.vue";
import TaskSelectModal from '@/views/tasks/presentation/task-select-modal/TaskSelectModal.vue';
import {
ASSIGN_TASKS,
GET_FILES, GET_FOLDER_BY_ID
} from "../../data/files_graphql";
import DeleteFileConfirmModal from "./components/DeleteFileConfirmModal.vue";
import FileList from "./components/FileList.vue";
import FolderList from "./components/FolderList.vue";
import FolderModal from "./components/FolderModal.vue";

export default {
  name: "DocumentsPage",
  components: {
    ProjectBreadcrumbComponent,
    FolderModal,
    TaskSelectModal,
    IconButton,
    DeleteFileConfirmModal,
    FolderList,
    FileList,
  },
  apollo: {
    files: {
      query: GET_FILES,
      variables() {
        return this.getCommonVariables()
      },
      fetchPolicy: 'cache-and-network',
    },
    folder: {
      query: GET_FOLDER_BY_ID,
      variables() {
        return {
          folderId: this.parentId,
        }
      },
      fetchPolicy: 'cache-and-network',
      skip() {
        return !this.parentId;
      },
    },
  },
  data() {
    return {
      projectId: undefined,
      parentId: undefined,
      files: [],
      modals: {
        assignTaskModal: {
          show: false,
          file: {
            id: "",
            projectId: "",
            tasks: [],
          },
        },
        folderModal: {
          show: false,
          file: {
            id: "",
            name: "",
          },
        },
        confirmDeleteFileModal: {
          show: false,
          file: {
            id: "",
          }
        },
      }
    };
  },
  watch: {
    $route(to) {
      this.projectId = to.params.id;
      this.parentId = to.params.parentId;
    },
  },
  computed: {
    getFolders() {
      return this.files.filter(file => file.isDirectory);
    },
    getFiles() {
      return this.files.filter(file => !file.isDirectory);
    },
  },
  methods: {
    getCommonVariables() {
      return {
        projectId: this.projectId,
        parentId: this.parentId,
      }
    },
    openParentFolder() {
      if (this.parent) {
        this.$router.push({
          name: this.projectId ? 'project.documents.folder' : 'documents.folder',
          params: {
            projectId: this.projectId,
            parentId: this.folder.parent.id,
          },
        });
      } else {
        this.$router.push({
          name: this.projectId ? 'project.documents' : 'documents',
          params: {
            projectId: this.projectId,
          },
        });
      }
    },

    //-- MODALS
    onCreateFolderClick() {
      this.modals.folderModal.file = {
          id: "",
          name: "",
      };
      this.modals.folderModal.show = true;
    },
    onCloseFolderModal() {
      this.modals.folderModal.file = {
          id: "",
          name: "",
      };
      this.modals.folderModal.show = false;
    },
    assignTask(file) {
      this.modals.assignTaskModal.file = {
        ...file,
        tasks: file.tasks.map(task => task.id),
      }
      this.modals.assignTaskModal.show = true;
    },
    closeAssignTaskModal() {
      this.modals.assignTaskModal.show = false;
    },
    saveAssignedTasks(taskIds) {
      this.$apollo.mutate({
        mutation: ASSIGN_TASKS,
        variables: {
          fileId: this.modals.assignTaskModal.file.id,
          taskIds: taskIds,
        },
        refetchQueries: [
          {
            query: GET_FILES,
            variables: this.getCommonVariables(),
          },
        ],
      });
      this.closeAssignTaskModal();
    },
    modalEditFileOpen(file) {
      this.modals.folderModal.file.id = file.id;
      this.modals.folderModal.file.name = file.name;
      this.modals.folderModal.show = true;
    },
    modalEditFileClose() {
      this.modals.folderModal.file = {
          id: "",
          name: "",
      };
      this.modals.folderModal.show = false;
    },
    modalConfirmDeleteFileOpen(file) {
      this.modals.confirmDeleteFileModal.file.id = file.id;
      this.modals.confirmDeleteFileModal.show = true;
    },
    modalConfirmDeleteFileClose() {
      this.modals.confirmDeleteFileModal.file.id = "";
      this.modals.confirmDeleteFileModal.show = false;
    },
    //-- END MODAL
  },
  async mounted() {
    this.projectId = this.$route.params.id;
    this.parentId = this.$route.params.parentId;
  }
}
</script>

