
export const displayModule = {
    state: () => ({
        asideToggled: false,
        notificationToggled: false,
        profileToggled: false,
        currentProjectFocus: "0",
        currentProjectFocusName: "0",
        descriptionMode: "full",
        modalAssignTask: {
            show: false,
            data: {
                fileId: null,
            }
        },
        blur: false,
        expiredSessionModalToggled: false,
    }),
    mutations: {
        toggleExpiredSessionModal(state, { mode }) {
            switch (mode) {
                case "open":
                    state.expiredSessionModalToggled = true;
                    break;
                case "toggleLoginWindow":
                    window.open(process.env.VUE_APP_URL_BACK+"/auth/login", "newwindow", "width=500,height=500")
                    break;
                case "close":
                    state.expiredSessionModalToggled = false;
                    break;
                default:
                    state.expiredSessionModalToggled = !state.expiredSessionModalToggled;
            }
        },
        updateDescriptionDisplayMode(state, mode) {
            state.descriptionMode = mode;
        },
        toggleModalAssignTask(state, {fileId}) {
            state.modalAssignTask.show = true;
            state.modalAssignTask.data.fileId = fileId;
        },
        closeModalAssignTask(state) {
            state.modalAssignTask.show = false;
            state.modalAssignTask.data.taskId = null;
        },
        toggleAside(state) {
            state.asideToggled = !state.asideToggled;
        },
        closeAside(state) {
            state.asideToggled = false;
        },
        openAside(state) {
            state.asideToggled = true;
        },
        toggleNotification(state) {
            state.notificationToggled = !state.notificationToggled;
        },
        toggleProfile(state, mode) {
            state.profileToggled = (mode === 'show');
        },
        setCurrentProjectFocus(state, payload) {
            let id = payload.projectId;
            let name = payload.projectName;

            if(id === undefined || id === null) {
                id = "0";
                name = "0";
            }

            state.currentProjectFocus = id;
            state.currentProjectFocusName = name;
        },
        toggleBlur(state, status) {
            state.blur = status;
        }
    },
    getters: {
        currentProjectFocus(state) {
           return state.currentProjectFocus
        },
    }
}
