<template>
    <KalmModal modalTitle="Séléctionnez les tâches" @close="close">
        <template v-slot:body>
            <!-- Search bar that filters the results by task name or description -->
            <div>
                <input type="text" class="form-control" v-model="searchString" placeholder="Rechercher une tâche" />
            </div>
            <div v-for="task in filteredTasks" :key="task.id" class="mt-4 form-check">
                <input class="form-check-input mr-6" type="checkbox" :id="task.id" v-model="selectedTaskIds" :value="task.id" />
                <label class="form-check-label ml-2" :for="task.id">
                    <span v-if="task?.numero" class="me-2 text-muted font-monospace">{{task?.numero}}</span>{{ task.title }}
                </label>
            </div>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close">
                Annuler
            </button>
            <button type="button" class="btn btn-primary" @click="save">
                Enregistrer
            </button>
        </template>
    </KalmModal>
</template>

<script>
import KalmModal from "@/views/_core/components/KalmModal.vue";
import { GET_TASKS } from "../../data/tasks_graphql";

export default {
    name: "TaskSelectModal",
    components: {
        KalmModal,
    },
    props: {
        projectId: {
            type: String,
            required: true,
        },
        initiallySelectedTaskIds: {
            type: Array,
        },
    },
    apollo: {
        tasks: {
            query: GET_TASKS,
            variables() {
                return {
                    projectId: this.projectId,
                };
            },
            skip() {
                return !this.projectId;
            },
        },
    },
    data() {
        return {
            tasks: [],
            selectedTaskIds: [],
            searchString: "",
        };
    },
    created() {
        this.initializeData();
    },
    computed: {
        filteredTasks() {
            return this.tasks.filter((task) => {
                return (task?.title || "").toLowerCase().includes(this.searchString.toLowerCase())
                    || (task?.description || "").toLowerCase().includes(this.searchString.toLowerCase());
            });
        },
    },
    methods: {
        initializeData() {
            if (this.initiallySelectedTaskIds) {
                this.selectedTaskIds = [...this.initiallySelectedTaskIds];
            }
        },
        save() {
            this.$emit("save", this.selectedTaskIds);
        },
        close() {
            this.$emit("close");
        },
    },
};
</script>
