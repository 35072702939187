<template>
  <!--begin::Content-->
  <div :class="{'blur-content': this.isModalActive}">
    <ProjectBreadcrumbComponent
        title="Consultation"
        current_route="project.tender.manage"
        :project_id=$route.params.id
        :options="[{name: 'Suivi', id: 'project.tender.manage'},{name: 'Comparatif', id: 'project.tender.table'},{name: 'Scénarios', id: 'project.tender.scenarios', disabled: !this.currentUser?.groups?.includes('plan_premium')},]"
    ></ProjectBreadcrumbComponent>
    <!--begin::Row-->
    <div class="row g-5 print-hidden">
      <div class="col-12">
        <ProgressCard
            card_type="kalm"
            card_title="DCE • Consultation"
            :card_text_line_1="'' + this.card_count_proposals_sent + ((this.card_count_proposals_sent !== 1) ? ' descriptifs envoyés' : ' descriptif envoyé')"
            :card_text_line_2="'' + this.card_count_proposals_received + ((this.card_count_proposals_received !== 1) ? ' chiffrages reçus' : ' chiffrage reçu')"
            :card_progress_percent=(card_count_proposals_sent+card_count_proposals_received)/this.card_count_proposals_created*50>
        </ProgressCard>
      </div>
    </div>
    <!--end::Row-->
<!--    <h2 class="text-dark fw-bold mt-8 fs-1 mb-6">Gestion de la consultation</h2>
    <div class="card">
      <div class="card-body">
        <label>Importer le descriptif dans le module de suivi de chantier :</label>
        <button class="btn btn-light btn-active-accent ms-2">Importer</button>
      </div>
    </div>-->

    <div class="d-flex">
      <h2 class="align-bottom mt-2 ms-5">Suivi de la consultation</h2>
      <div class="d-flex ms-auto">
        <button class="ms-auto mb-3 btn btn-icon btn-primary me-2" v-tooltip:bottom="'Mettre à jour toutes les DPGF'" @click="this.modalUpdateOuvragesShow()"><span v-if="this.modals.updateOuvrages.loading" class="spinner-border spinner-border-sm"></span><span v-else class="bi bi-arrow-repeat"></span></button>
        <button class="mb-3 btn btn-icon btn-primary me-2" v-tooltip:bottom="'Ajouter un estimatif'" @click="this.modalEstimationCreateShow()"><i class="bi bi-file-plus"></i></button>
        <button class="mb-3 btn btn-icon btn-primary me-5" v-tooltip:bottom="'Ajouter une entreprise'" @click="this.modalProposalCreateShow()"><i class="bi bi-person-plus"></i></button>
      </div>
    </div>
    <!--begin::Row-->
    <div v-if="this.proposals.length > 0">
      <div class="row col-12 g-5 h-100">
        <div v-for="proposal in this.proposals" :key="proposal.id" class="col-12 col-sm-6 col-xl-4 col-xxl-4 h-auto">
          <div class="card h-100">
            <div class="card-body">
              <!-- TITLE & LOTS -->
              <div class="d-flex">
                <button class="btn btn-sm btn-primary px-2 py-0"
                        v-tooltip:bottom="'Mettre à jour les ouvrages'"
                @click="this.modalUpdateProposalShow(proposal)">
                  <span v-if="this.modals.updateOuvrages.loading || this.modals.updateProposal?.loadingTable[proposal.id]" class="spinner-border spinner-border-sm"></span><span v-else class="bi bi-arrow-repeat"></span>
                </button>
                <h3 class="mb-1 ms-2">{{ proposal.name || getContactNameById(proposal.contact.id) }}</h3>
              </div>
              <div>
                <div v-if="proposal.modeLots === 'ALL'" class="text-muted"><span v-if="proposal?.contact">Consulté sur tous les lots</span><span v-else>Sur tous les lots</span></div>
                <div v-else-if="proposal.lots.length === 1" class="text-muted"><span v-if="proposal?.contact">Consulté sur le lot {{ proposal.lots[0].number }}</span><span v-else>Sur le lot {{ proposal.lots[0].number }}</span></div>
                <div v-else-if="proposal.lots.length === 0" class="text-muted"><span v-if="proposal?.contact">Consulté sur aucun lot</span><span v-else>Sur aucun lot</span></div>
                <div v-else-if="proposal.lots.length >= 1" class="text-muted"><span v-if="proposal?.contact">Consulté sur les lots {{ proposal.lots.map(l => l.number).join(", ") }}</span><span v-else>Sur les lot {{ proposal.lots.map(l => l.number).join(", ") }}</span></div>
              </div>
              <div v-if="proposal?.contact && proposal.steps && proposal.steps.length">
                <div class="mt-3">
                  <div v-for="step in JSON.parse(proposal.steps)" :key="step.id" class="text-gray-800">
                    <span class="me-2 bi" :class="step.status ? 'bi-check-circle-fill' : 'bi-circle'"></span>{{ step.name }}
                  </div>
                </div>
              </div>

              <!-- NOTES -->
              <div v-if="proposal.notes" class="mt-8">
                <label class="text-muted">Notes :</label>
                <div class="w-100 border-0 text-dark" style="min-height: 66px;">{{ proposal.notes }}</div>
              </div>
            </div>
            <div class="card-footer d-flex p-0 justify-content-center">
              <button type="button"
                      v-tooltip:bottom="'Modifier'"
                      @click="modalProposalEditShow(proposal.id)"
                      class="btn flex-grow-1 rounded-0 btn-active-light-primary"
                      style="border-bottom-left-radius: 15px;"><i class="bi bi-pencil"></i></button>
              <button type="button"
                      v-tooltip:bottom="'Dupliquer'"
                      @click="modalProposalDuplicateShow(proposal.id)"
                      class="btn flex-grow-1 rounded-0 btn-active-light-primary"><i class="bi bi-clipboard-plus"></i></button>
<!--              <button type="button"
                      v-tooltip:bottom="'Mettre à jour'"
                      @click="modalUpdateProposalShow(proposal.id)"
                      class="btn btn-primary flex-grow-1 rounded-0"><i class="bi bi-arrow-repeat"></i></button>-->
              <button type="button"
                      v-tooltip:bottom="'Télécharger'"
                      @click="modalDownloadShow(proposal.id)"
                      class="btn flex-grow-1 rounded-0 btn-active-light-primary"><i class="bi bi-download"></i></button>
              <button type="button"
                      v-tooltip:bottom="'Supprimer'"
                      @click="modalProposalDeleteShow(proposal.id)"
                      class="btn flex-grow-1 rounded-0 btn-active-light-primary" style="border-bottom-right-radius: 15px;"><i class="bi bi-trash"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
<!--    <p v-if="this.$apollo.queries.x.loading">
        Chargement...
    </p>-->
      <p class="text-center text-muted my-12">
        Aucune entreprise consultée pour ce projet. Cliquez sur consulter une entreprise pour créer une consultation ou un estimatif.<span class="ms-1 bi bi-arrow-up-right"></span>
      </p>
    </div>
  </div>
  <!--end::Content-->

  <!-- MODAL MENU -->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="this.modalProposalMenuClose()"
       :style="this.modals.proposalMenu.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalProposalMenuClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body text-center align-items-center justify-content-center w-100">
            <button class="btn btn-outline btn-outline-dashed h-150px w-150px m-2" @click="this.modalProposalCreateShow()">Demande de chiffrage</button>
            <button class="btn btn-outline btn-outline-dashed h-150px w-150px m-2" @click="this.modalEstimationCreateShow()">Budget estimatif</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL CREATE PROPOSAL -->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="this.modalProposalCreateClose()"
       :style="this.modals.proposalCreate.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalProposalCreateClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div :class="this.modals.proposalCreate.page === 0 ? 'd-flex' : 'd-none'" class="flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Demande de chiffrage</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Entreprise</label>
                        <router-link :to="{name: 'contacts'}" class="fs-8 align-self-end form-label">+ Importer une nouvelle entreprise</router-link>
                        <select v-model="this.modals.proposalCreate.contact" class="form-select form-select-solid">
                          <option v-for="contact in [{id: '', name: 'Sélectionner un contact...', disabled: true}, ...this.contacts.filter(c => c.type === 'COMPANY')]" :key="contact.id" :value="contact.id" :disabled="contact?.disabled">{{ contact.company || contact.name }}</option>
                        </select>
                      </div>

                      <div class="">
                        <p class="mb-1 text-dark fw-bold mb-2">Import personnalisé depuis le descriptif</p>
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" id="proposalCreate.importUnit" v-model="this.modals.proposalCreate.importUnit">
                          <label class="ms-2 fs-6 mb-1 form-label" for="proposalCreate.importUnit">Unités</label>
                        </div>
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" id="proposalCreate.importQt" v-model="this.modals.proposalCreate.importQt">
                          <label class="ms-2 fs-6 mb-1 form-label" for="proposalCreate.importQt">Quantités</label>
                        </div>
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" id="proposalCreate.importPrice" v-model="this.modals.proposalCreate.importPrice">
                          <label class="ms-2 fs-6 mb-1 form-label" for="proposalCreate.importPrice">Prix unitaires</label>
                        </div>
                      </div>

                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button"
                              @click="this.modals.proposalCreate.page++; this.modals.proposalCreate.name = getContactNameById(this.modals.proposalCreate.contact);"
                              :disabled="!this.modals.proposalCreate.contact"
                              class="btn btn-lg btn-primary btn-active-light-primary">
                        Suivant >
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->

              <!--begin::Content1-->
              <div :class="this.modals.proposalCreate.page === 1 ? 'd-flex' : 'd-none'" class="flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Demande de chiffrage</h3>
                        <p class="text-gray-500 fw-bold"><i class="bi bi-building me-2"></i>{{ getContactNameById(this.modals.proposalCreate.contact) }}</p>
                      </div>
                      <!--end::Heading-->

                      <div class="mb-5">
                        <label class="fs-6 mb-1 fw-bolder form-label">Nom du chiffrage</label>
                        <div class="input-group align-middle">
                          <input class="form-control form-control-solid" type="text" v-model="this.modals.proposalCreate.name">
                        </div>
                      </div>

                      <!--begin::Form Group-->
                      <div class="mb-10 w-100">
                        <div>
                          <label class="fs-6 mb-1 fw-bolder form-label">Consultation sur</label>
                          <select class="form-select form-select-solid" v-model="this.modals.proposalCreate.modeLots" aria-label="Select project">
                            <option v-for="mode in [{id: 'ALL', name: 'Tous les lots'}, {id: 'PARTIAL', name: 'Une partie des lots'}]" :value="mode.id" :name="mode.name" :key="mode.id">{{ mode.name }}</option>
                          </select>
                        </div>
                        <div class="mt-3" v-if="this.modals.proposalCreate.modeLots === 'PARTIAL'">
                          <div class="d-flex justify-content-between">
                            <label class="fs-6 mb-2 fw-bolder text-dark form-label">Lots concernés</label>
                            <p class="fs-8 mb-2 align-self-end primary text-hover-secondary fw-bolder text-primary form-label text-decoration-underline"></p>
                          </div>
                          <div class="text-end text-muted">
                            <a class="cursor-pointer" @click="proposalLotsQuickSelect(this.modals.proposalCreate.lots, 'all')">Tout sélectionner</a>
                            /
                            <a class="cursor-pointer" @click="proposalLotsQuickSelect(this.modals.proposalCreate.lots, 'none')">Aucun</a>
                          </div>
                          <!--begin::Form Group-->
                          <div v-for="lot in lots" :key="lot.id">
                            <!--begin:Option-->
                            <label class="d-flex flex-stack mb-6 cursor-pointer">
                              <span class="d-flex align-items-center me-2">
                                <span class="d-flex flex-column">
                                  <span class="fs-6">{{ lot.fullName }}</span>
                                </span>
                              </span>
                              <span class="form-check form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox" :value="lot.id" v-model="this.modals.proposalCreate.lots">
                              </span>
                            </label>
                            <!--end::Option-->
                          </div>
                        </div>
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button"
                              @click="modalProposalCreateValidate()"
                              :disabled="this.modals.proposalCreate.modeLots === 'PARTIAL' && !this.modals.proposalCreate.lots.length"
                              class="btn btn-lg btn-primary btn-active-light-primary">
                        Suivant >
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content1-->

              <!--begin::Content2-->
              <div :class="this.modals.proposalCreate.page >= 2 ? 'd-flex' : 'd-none'" class="flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactModalForm"
                     class="pb-5 w-100 w-sm-400px">
                  <!--begin::Step-->
                  <div class="pb-3 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Demande de chiffrage</h3>
                        <p class="text-gray-500 fw-bold"><i class="bi bi-building me-2"></i>{{ getContactNameById(this.modals.proposalCreate.contact) }}</p>
                      </div>
                      <!--end::Heading-->

                      <!--
                        <div class="mb-5 w-100">
                          <div class="d-flex justify-content-between">
                            <label class="fs-6 mb-2 fw-bolder text-dark form-label">Lien à partager</label>
                          </div>
                          <div class="input-group input-group-lg input-group-solid">
                            <input type="text" ref="dceModalLinkShareField" class="form-control form-control-lg form-control-solid" value="dce.kalm.ai/iAkzndaIaBJAUhaU" placeholder="Email">
                            <span @click="copyLinkToClipboard()"
                                class="input-group-text btn rounded-end btn-outline-primary btn-active-bg-light btn-active-light pe-0">
                              <i class="la la-copy fs-3 pe-4"></i>
                            </span>
                          </div>
                        </div>
                      -->
                    </div>
                  </div>
                  <!--end::Step-->
                  <!--begin::Alert-->
                  <div class="alert bg-primary d-flex flex-column flex-sm-row p-5 mb-10">
                    <!--begin::Icon-->
                    <span class="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0"><i class="bi fs-1 bi-check"></i></span>
                    <!--end::Icon-->

                    <!--begin::Wrapper-->
                    <div class="d-flex flex-column text-light pe-0 pe-sm-10">
                      <!--begin::Title-->
                      <h5 class="mb-1">Demande de chiffrage créée</h5>
                      <!--end::Title-->
                      <!--begin::Content-->
                      <span>Vous pouvez désormais mettre à jour son statut d'avancement et télécharger le DCE en format Excel dans l'onglet suivi. Dès que vous recevrez le chiffrage, vous pourrez le saisir dans l'onglet Comparatif afin de compléter le tableau de synthèse.</span>
                      <!--end::Content-->
                    </div>
                    <!--end::Wrapper-->
                  </div>
                  <!--end::Alert-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button"
                              @click="modalProposalCreateClose()"
                              class="btn btn-lg btn-primary btn-active-light-primary">
                        Terminer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content2-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL CREATE ESTIMATION -->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="this.modalEstimationCreateClose()"
       :style="this.modals.proposalEstimation.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalEstimationCreateClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div :class="this.modals.proposalEstimation.page === 0 ? 'd-flex' : 'd-none'" class="flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Nouvel estimatif</h3>
                      </div>
                      <!--end::Heading-->
                    </div>

                    <div class="w-100">
                      <div class="mb-5">
                        <label class="fs-6 mb-1 fw-bolder form-label">Nom de l'estimatif</label>
                        <div class="input-group align-middle">
                          <input class="form-control form-control-solid" type="text" v-model="this.modals.proposalEstimation.name">
                        </div>
                      </div>

                      <!--begin::Form Group-->
                      <div class="mb-10 w-100">
                        <div>
                          <label class="fs-6 mb-1 fw-bolder form-label">Estimatif sur</label>
                          <select class="form-select form-select-solid" v-model="this.modals.proposalEstimation.modeLots" aria-label="Select project">
                            <option v-for="mode in [{id: 'ALL', name: 'Tous les lots'}, {id: 'PARTIAL', name: 'Une partie des lots'}]" :value="mode.id" :name="mode.name" :key="mode.id">{{ mode.name }}</option>
                          </select>
                        </div>
                        <div class="mt-3" v-if="this.modals.proposalEstimation.modeLots === 'PARTIAL'">
                          <div class="d-flex justify-content-between">
                            <label class="fs-6 mb-2 fw-bolder text-dark form-label">Lots concernés</label>
                            <p class="fs-8 mb-2 align-self-end primary text-hover-secondary fw-bolder text-primary form-label text-decoration-underline"></p>
                          </div>
                          <div class="text-end text-muted">
                            <a class="cursor-pointer" @click="proposalLotsQuickSelect(this.modals.proposalEstimation.lots, 'all')">Tout sélectionner</a>
                            /
                            <a class="cursor-pointer" @click="proposalLotsQuickSelect(this.modals.proposalEstimation.lots, 'none')">Aucun</a>
                          </div>
                          <!--begin::Form Group-->
                          <div v-for="lot in lots" :key="lot.id">
                            <!--begin:Option-->
                            <label class="d-flex flex-stack mb-6 cursor-pointer">
                              <span class="d-flex align-items-center me-2">
                                <span class="d-flex flex-column">
                                  <span class="fs-6">{{ lot.fullName }}</span>
                                </span>
                              </span>
                              <span class="form-check form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox" :value="lot.id" v-model="this.modals.proposalEstimation.lots">
                              </span>
                            </label>
                            <!--end::Option-->
                          </div>
                        </div>
                      </div>

                      <div class="">
                        <p class="mb-1 text-dark fw-bold mb-2">Import personnalisé depuis le descriptif</p>
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" id="proposalEstimation.importUnit" v-model="this.modals.proposalEstimation.importUnit">
                          <label class="ms-2 fs-6 mb-1 form-label" for="proposalEstimation.importUnit">Unités</label>
                        </div>
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" id="proposalEstimation.importQt" v-model="this.modals.proposalEstimation.importQt">
                          <label class="ms-2 fs-6 mb-1 form-label" for="proposalEstimation.importQt">Quantités</label>
                        </div>
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" id="proposalEstimation.importPrice" v-model="this.modals.proposalEstimation.importPrice">
                          <label class="ms-2 fs-6 mb-1 form-label" for="proposalEstimation.importPrice">Prix unitaires</label>
                        </div>
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->
                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button"
                              @click="modalEstimationCreateValidate()"
                              :disabled="this.modals.proposalEstimation.modeLots === 'PARTIAL' && !this.modals.proposalEstimation.lots.length"
                              class="btn btn-lg btn-primary btn-active-light-primary">
                        Suivant >
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->


                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->

              <!--begin::Content1-->
              <div :class="modals.proposalEstimation.page === 1 ? 'd-flex' : 'd-none'" class="flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <!--end::Form-->
              </div>
              <!--end::Content1-->

              <!--begin::Content2-->
              <div :class="modals.proposalEstimation.page >= 2 ? 'd-flex' : 'd-none'" class="flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactModalForm"
                     class="pb-5 w-100 w-sm-400px">
                  <!--begin::Step-->
                  <div class="pb-3 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Nouvel estimatif</h3>
                        <p class="text-gray-500 fw-bold">Nom : {{ this.modals.proposalEstimation.name }}</p>
                      </div>
                      <!--end::Heading-->

                      <!--
                        <div class="mb-5 w-100">
                          <div class="d-flex justify-content-between">
                            <label class="fs-6 mb-2 fw-bolder text-dark form-label">Lien à partager</label>
                          </div>
                          <div class="input-group input-group-lg input-group-solid">
                            <input type="text" ref="dceModalLinkShareField" class="form-control form-control-lg form-control-solid" value="dce.kalm.ai/iAkzndaIaBJAUhaU" placeholder="Email">
                            <span @click="copyLinkToClipboard()"
                                class="input-group-text btn rounded-end btn-outline-primary btn-active-bg-light btn-active-light pe-0">
                              <i class="la la-copy fs-3 pe-4"></i>
                            </span>
                          </div>
                        </div>
                      -->
                    </div>
                  </div>
                  <!--end::Step-->
                  <!--begin::Alert-->
                  <div class="alert bg-primary d-flex flex-column flex-sm-row p-5 mb-10">
                    <!--begin::Icon-->
                    <span class="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0"><i class="bi fs-1 bi-check"></i></span>
                    <!--end::Icon-->

                    <!--begin::Wrapper-->
                    <div class="d-flex flex-column text-light pe-0 pe-sm-10">
                      <!--begin::Title-->
                      <h5 class="mb-1">Votre estimatif a été créé !</h5>
                      <!--end::Title-->
                      <!--begin::Content-->
                      <span>Vous pouvez dès maintenant le remplir puis l'exporter en vous rendant sur l'onglet Comparatif.</span>
                      <!--end::Content-->
                    </div>
                    <!--end::Wrapper-->
                  </div>
                  <!--end::Alert-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button"
                              @click="modalEstimationCreateClose()"
                              class="btn btn-lg btn-primary btn-active-light-primary">
                        Terminer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content2-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL DUPLICATE PROPOSAL -->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="this.modalProposalDuplicateClose()"
       :style="this.modals.proposalDuplicate.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalProposalDuplicateClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div :class="this.modals.proposalCreate.page === 0 ? 'd-flex' : 'd-none'" class="flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px">
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Dupliquer le chiffrage</h3>
                      </div>
                      <!--end::Heading-->
                    </div>

                    <div class="">
                      <label class="text-muted mt-2 mb-1">Nom du chiffrage</label>
                      <div class="input-group align-middle">
                        <input class="form-control" type="text" v-model="this.modals.proposalDuplicate.name">
                      </div>
                    </div>


                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button"
                              @click="this.modalProposalDuplicateClose()"
                              class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button"
                              @click="this.modalProposalDuplicateValidate()"
                              :disabled="!this.modals.proposalDuplicate.name"
                              class="btn btn-lg btn-primary btn-active-light-primary ms-2">
                        Confirmer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL - CONFIRM DELETE CONTACT-->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="this.modalProposalDeleteClose()"
       :style="this.modals.proposalDelete.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalProposalDeleteClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Confirmer la suppression</h3>
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="modalProposalDeleteClose()" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="modalProposalDeleteValidate()" class="btn btn-lg btn-primary ms-2">
                        Supprimer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL - DOWNLOAD -->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="modalDownloadClose()"
       :style="this.modals.download.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalDownloadClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content2-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactModalForm"
                     class="pb-5 w-100 w-sm-400px">
                  <!--begin::Step-->
                  <div class="pb-3 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">
                          <span v-if="this.modals.download?.contactId">Demande de chiffrage</span>
                          <span v-else>Budget estimatif</span>
                        </h3>
                        <p class="text-gray-500 fw-bold">
                          <span v-if="this.modals.download?.contactId">
                            <i class="bi bi-building me-2"></i>{{ getContactNameById(this.modals.download.contactId) }}
                          </span>
                        </p>
                      </div>
                      <!--end::Heading-->

                      <!--
                        <div class="mb-5 w-100">
                          <div class="d-flex justify-content-between">
                            <label class="fs-6 mb-2 fw-bolder text-dark form-label">Lien à partager</label>
                          </div>
                          <div class="input-group input-group-lg input-group-solid">
                            <input type="text" ref="dceModalLinkShareField" class="form-control form-control-lg form-control-solid" value="dce.kalm.ai/iAkzndaIaBJAUhaU" placeholder="Email">
                            <span @click="copyLinkToClipboard()"
                                class="input-group-text btn rounded-end btn-outline-primary btn-active-bg-light btn-active-light pe-0">
                              <i class="la la-copy fs-3 pe-4"></i>
                            </span>
                          </div>
                        </div>
                      -->
                      <!--begin::Form Group-->
                      <div class="mb-5 w-100">
                        <div class="d-flex justify-content-between">
                          <label class="fs-6 mb-2 form-label">Fichiers à télécharger et partager :</label>
                        </div>
                        <div class="row g-1">
                          <button @click="this.downloadAsXlsxV2(this.modals.download.proposalId)"
                                  :disabled="!this.canDownloadAsXlsxV2(this.modals.download.proposalId)"
                                  class="col-12 w-100 btn btn-light btn-active-accent align-content-center"><span class="bi bi-file-earmark-spreadsheet align-self-baseline me-1"></span>DPGF.xlsx</button>
<!--                          <button class="col-12 w-100 btn btn-light btn-active-accent" disabled><span class="bi bi-file-earmark-pdf me-2"></span>CCTP.pdf — À VENIR</button>-->
                        </div>
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->

<!--                  <div class="alert bg-primary d-flex flex-column flex-sm-row p-5 mb-10">
                    &lt;!&ndash;begin::Icon&ndash;&gt;
                    <span class="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0"><i class="bi fs-1 bi-check"></i></span>
                    &lt;!&ndash;end::Icon&ndash;&gt;
                    <div class="d-flex flex-column text-light pe-0 pe-sm-10">
                      begin::Title
                      <h5 class="mb-1">Demande de chiffrage créée</h5>
                      end::Title
                      begin::Content
                      <span>Vous pouvez télécharger le DCE et l'envoyer à {{ getContactNameById(this.modals.download.contactId) }}</span>
                      end::Content
                    </div>
                  </div>-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button"
                              @click="modalDownloadClose()"
                              class="btn btn-lg btn-primary btn-active-light-primary">
                        Terminer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content2-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL - EDIT -->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="this.modalProposalEditClose()"
       :style="this.modals.proposalEdit.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalProposalEditClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content2-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactModalForm"
                     class="pb-5 w-100 w-sm-400px">
                  <!--begin::Step-->
                  <div class="pb-3 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="">
                        <h3 class="fw-bolder text-dark display-6">
                          <span v-if="this.modals.proposalEdit?.contactId">Modifier le chiffrage</span>
                          <span v-else>Modifier l'estimatif</span>
                        </h3>
                        <p v-if="this.modals.proposalEdit?.contactId" class="text-gray-500 fw-bold"><i class="bi bi-building me-2"></i>{{ getContactNameById(this.modals.proposalEdit.contactId) }}</p>
                      </div>
                      <!--end::Heading-->

                      <div class="mt-5">
                        <label class="text-muted mt-2 mb-1">
                          <span v-if="this.modals.proposalEdit?.contactId">Nom du chiffrage</span>
                          <span v-else>Nom de l'estimatif</span>
                        </label>
                        <div class="input-group align-middle">
                          <input class="form-control" type="text" v-model="this.modals.proposalEdit.name">
                        </div>
                      </div>

                      <div class="mt-5">
                        <label class="text-muted mt-2 mb-1">Taux de TVA par défaut pour les ouvrages :</label>
                        <div class="input-group align-middle">
                          <input class="form-control" type="text" v-model="this.modals.proposalEdit.vatDefaultValue">
                          <span class="input-group-text">%</span>
                        </div>
                      </div>

                      <div class="mt-3" v-if="this.modals.proposalEdit?.contactId">
                        <label class="text-muted mb-1">Avancement :</label>
                        <div v-for="step in this.modals.proposalEdit.formattedSteps"
                             :key="step.id"
                             class="form-check form-check-custom mb-1">
                          <input type="checkbox"
                                 class="form-check-input"
                                 :ref="step.id"
                                 v-model="this.modals.proposalEdit.modelSteps[step.id]"
                                 :id="step.id">
                          <label class="form-check-label text-gray-800 ms-2 text-muted" :for="step.id">
                            {{ step.name }}
                          </label>
                        </div>
                      </div>
                      <div class="mt-3">
                        <label class="fs-6 mb-2 text-muted">
                          <span v-if="this.modals.proposalEdit?.contactId">Consultation sur :</span>
                          <span v-else>Estimatif sur :</span>
                        </label>
                        <select class="form-select form-select-solid" v-model="this.modals.proposalEdit.modeLots" aria-label="Select project">
                          <option v-for="mode in [{id: 'ALL', name: 'Tous les lots'}, {id: 'PARTIAL', name: 'Une partie des lots'}]" :value="mode.id" :name="mode.name" :key="mode.id">{{ mode.name }}</option>
                        </select>
                      </div>

                      <div class="mt-3" v-if="this.modals.proposalEdit.modeLots === 'PARTIAL'">
                        <div class="d-flex justify-content-between">
                          <label class="fs-6 mb-2 form-label text-muted">Lots concernés</label>
                          <p class="fs-8 mb-2 align-self-end primary text-hover-secondary fw-bolder text-primary form-label text-decoration-underline"></p>
                        </div>
                        <div class="text-end text-muted">
                          <a class="cursor-pointer" @click="proposalLotsQuickSelect(this.modals.proposalEdit.lots, 'all')">Tout sélectionner</a>
                          /
                          <a class="cursor-pointer" @click="proposalLotsQuickSelect(this.modals.proposalEdit.lots, 'none')">Aucun</a>
                        </div>
                        <!--begin::Form Group-->
                        <div v-for="lot in lots" :key="lot.id">
                          <!--begin:Option-->
                          <label class="d-flex flex-stack mb-6 cursor-pointer">
                              <span class="d-flex align-items-center me-2">
                                <span class="d-flex flex-column">
                                  <span class="fs-6">{{ lot.fullName }}</span>
                                </span>
                              </span>
                            <span class="form-check form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox" :value="lot.id" v-model="this.modals.proposalEdit.lots">
                              </span>
                          </label>
                          <!--end::Option-->
                        </div>
                      </div>

                      <div class="mt-3">
                        <label class="text-muted mt-2 mb-1">Notes :</label>
                        <div class="">
                          <textarea class="form-control" v-model="this.modals.proposalEdit.notes"></textarea>
                        </div>
                      </div>

                      <!--
                        <div class="mb-5 w-100">
                          <div class="d-flex justify-content-between">
                            <label class="fs-6 mb-2 fw-bolder text-dark form-label">Lien à partager</label>
                          </div>
                          <div class="input-group input-group-lg input-group-solid">
                            <input type="text" ref="dceModalLinkShareField" class="form-control form-control-lg form-control-solid" value="dce.kalm.ai/iAkzndaIaBJAUhaU" placeholder="Email">
                            <span @click="copyLinkToClipboard()"
                                class="input-group-text btn rounded-end btn-outline-primary btn-active-bg-light btn-active-light pe-0">
                              <i class="la la-copy fs-3 pe-4"></i>
                            </span>
                          </div>
                        </div>
                      -->


                    </div>
                  </div>

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button"
                              @click="modalProposalEditClose()"
                              class="me-2 btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button"
                              @click="modalProposalEditValidate()"
                              class="btn btn-lg btn-primary btn-active-light-primary">
                        Modifier
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content2-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL - CONFIRM UPDATE ALL PROPOSALS -->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="this.modalUpdateOuvragesClose()"
       :style="this.modals.updateOuvrages.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalUpdateOuvragesClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6 mb-0">Mettre à jour</h3>
                        <p class="mt-0 text-dark">toutes les consultations & estimatifs</p>
                        <p class="text-gray-600">Pour correspondre à la dernière version du descriptif, les nouveaux ouvrages seront ajoutés à la consultation, les ouvrages supprimés seront enlevés.</p>

                        <p class="mt-3 text-dark fw-bold">Import personnalisé des nouveaux ouvrages</p>
                        <div class="form-check form-switch mb-3">
                          <label class="form-check-label text-gray-800 ms-2 text-muted" for="importUnit">
                            Unités
                          </label>
                          <input class="form-check-input" type="checkbox" v-model="this.modals.updateOuvrages.importUnit" id="importUnit">
                        </div>
                        <div class="form-check form-switch mb-3">
                          <label class="form-check-label text-gray-800 ms-2 text-muted" for="importQt">
                            Quantités
                          </label>
                          <input class="form-check-input" type="checkbox" v-model="this.modals.updateOuvrages.importQt" id="importQt">
                        </div>
                        <div class="form-check form-switch mb-3">
                          <label class="form-check-label text-gray-800 ms-2 text-muted" for="importPrice">
                            Prix unitaires
                          </label>
                          <input class="form-check-input" type="checkbox" v-model="this.modals.updateOuvrages.importPrice" id="importPrice">
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="this.modalUpdateOuvragesClose()" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="this.modalUpdateOuvragesValidate()" class="btn btn-lg btn-primary ms-2">
                        Mettre à jour
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL - CONFIRM UPDATE PROPOSAL-->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="this.modalUpdateProposalCancel()"
       :style="this.modals.updateProposal.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalUpdateProposalCancel()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactModalForm" class="pb-5 w-100 w-sm-400px">
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6 fs-1">Importer depuis le descriptif</h3>
                        <p class="text-gray-600">Pour correspondre à la dernière version du descriptif, les nouveaux ouvrages seront ajoutés à la consultation, les ouvrages supprimés seront enlevés.</p>

                        <p class="mt-3 text-dark fw-bold">Nouveaux ouvrages</p>

                        <div class="form-check form-switch mb-3">
                          <label class="form-check-label text-gray-800 ms-2 text-muted" for="updateProposalImportUnit">
                            Unités
                          </label>
                          <input class="form-check-input" type="checkbox" v-model="this.modals.updateProposal.importUnit" id="updateProposalImportUnit">
                        </div>
                        <div class="form-check form-switch mb-3">
                          <label class="form-check-label text-gray-800 ms-2 text-muted" for="updateProposalImportQt">
                            Quantités
                          </label>
                          <input class="form-check-input" type="checkbox" v-model="this.modals.updateProposal.importQt" id="updateProposalImportQt">
                        </div>
                        <div class="form-check form-switch mb-3">
                          <label class="form-check-label text-gray-800 ms-2 text-muted" for="updateProposalImportPrice">
                            Prix unitaires
                          </label>
                          <input class="form-check-input" type="checkbox" v-model="this.modals.updateProposal.importPrice" id="updateProposalImportPrice">
                        </div>

                        <p class="mt-5 text-dark fw-bold">Réinitialisation des ouvrages déjà importés</p>

                        <div class="form-check form-switch mb-3">
                          <label class="form-check-label text-gray-800 ms-2 text-muted" for="resetExisting">
                            Réinitialiser les prix, unités et quantités des ouvrages existants à partir du descriptif
                          </label>
                          <input class="form-check-input" type="checkbox" v-model="this.modals.updateProposal.resetExisting" id="resetExisting">
                        </div>
                        <div v-if="this.modals.updateProposal.resetExisting">
                            <p class="text-danger">Attention, cela supprimera tous les prix, quantités et unités déjà saisis et les remplacera par ceux du descriptif.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="this.modalUpdateProposalCancel()" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="this.modalUpdateProposalValidate()" class="btn btn-lg btn-primary ms-2">
                        Importer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CURRENT_USER_QUERY,
  GET_PROPOSALS_FOR_PROJECT,
  MUTATION_TENDER_PROPOSAL_UPDATE_OUVRAGES,
  MUTATION_TENDER_UPDATE_OUVRAGES
} from "@/graphql/graphql";
import kalmStore from "@/store";
import ProgressCard from "@/views/_core/components/ProgressCard";
import { ALL_CONTACTS_QUERY } from "@/views/contacts/data/contacts_graphql";
import ProjectBreadcrumbComponent from "@/views/project/ProjectBreadcrumbComponent";
import writeXlsxFile from "write-excel-file";

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  name: "TenderPage",
  components: { ProgressCard, ProjectBreadcrumbComponent },
  apollo: {
    contacts: {
      query: ALL_CONTACTS_QUERY,
    },
    currentUser: {
      query: CURRENT_USER_QUERY,
    }
  },
  data() {
    return {
      contacts: [],
      currentUser: {},
      modals: {
        proposalDelete: {
          show: false,
          id: '',
        },
        updateProposal: {
          show: false,
          proposalId: '',
          importUnit: true,
          importQt: false,
          importPrice: false,
          resetExisting: false,
          loadingTable: {},
        },
        proposalMenu: {
          show: false,
        },
        proposalCreate: {
          show: false,
          page: 0,
          name: "Budget estimatif",
          contact: '',
          modeLots: 'ALL',
          lots: [],
          proposalId: '',
          importUnit: true,
          importQt: false,
          importPrice: false,
        },
        proposalEstimation: {
          show: false,
          page: 0,
          modeLots: 'ALL',
          lots: [],
          proposalId: '',
          importUnit: true,
          importQt: true,
          importPrice: true,
        },
        proposalEdit: {
          show: false,
          proposalId: '',
          name: '',
          contactId: '',
          formattedSteps: [],
          modelSteps: {},
          modeLots: 'ALL',
          lots: [],
          vatDefaultValue: 0,
          notes: '',
        },
        proposalDuplicate: {
          show: false,
          proposalId: '',
          name: '',
        },
        download: {
          show: false,
          includeChiffrages: false,
          proposalId: '',
          contactId: '',
        },
        updateOuvrages: {
          show: false,
          loading: false,
          lots: [],
          activateComplexImport: false,
          importUnit: true,
          importQt: false,
          importPrice: false,
        }
      }
    }
  },
  computed: {
    storeIsReady(){
      return kalmStore.getters.currentProjectFocus && kalmStore.getters.currentProjectFocus !== "0"
    },
    lots() {
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus).lots
    },
    locations() {
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus).locations
    },
    proposals() {
      return kalmStore.getters.getProposalsForProject(kalmStore.getters.currentProjectFocus)
    },
    isModalActive: function() {
      return kalmStore.state.display.profileToggled
          || this.modals.proposalCreate.show
          || this.modals.proposalEstimation.show
          || this.modals.proposalMenu.show
          || this.modals.proposalEdit.show
          || this.modals.download.show
          || this.modals.proposalDelete.show
          || this.modals.updateOuvrages.show
          || this.modals.proposalDuplicate.show
          || this.modals.updateProposal.show;
    },
    currentProjectFocus() {
      return kalmStore.getters.currentProjectFocus
    },
    card_count_proposals_created(){
      return kalmStore.getters.getProposalsForProject(kalmStore.getters.currentProjectFocus)
          .filter(p => p?.contact)
          .length
    },
    card_count_proposals_sent(){
      let ret = 0;
      kalmStore.getters.getProposalsForProject(kalmStore.getters.currentProjectFocus)
      .filter(p => p?.contact)
      .forEach(p => {
        ret += JSON.parse(p.steps).find(s => s.id === "1")?.status ? 1 : 0;
      })
      return ret
    },
    card_count_proposals_received(){
      let ret = 0;
      kalmStore.getters.getProposalsForProject(kalmStore.getters.currentProjectFocus)
      .filter(p => p?.contact)
      .forEach(p => {
        ret += JSON.parse(p.steps).find(s => s.id === "2")?.status ? 1 : 0;
      })
      return ret
    },
    currentProject() {
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus)
    }
  },
  methods: {
    async downloadAsXlsx(proposalId) {
      const proposal = kalmStore.getters.getProposalById(proposalId);
      const project = this.currentProject

      const includeChiffrages = this.modals.download.includeChiffrages;

      const HEADER_ROW_CORE = [
        {
          value: 'Lot',
          fontWeight: 'bold'
        },
        {
          value: 'Emplacement',
          fontWeight: 'bold'
        },
        {
          value: 'Ouvrage',
          fontWeight: 'bold'
        },
        {
          value: 'Description détaillée',
          fontWeight: 'bold'
        },
        {
          value: 'Quantité',
          fontWeight: 'bold'
        },
        {
          value: 'Prix HT',
          fontWeight: 'bold'
        },
        {
          value: 'TVA',
          fontWeight: 'bold'
        },
        {
          value: 'Prix TTC',
          fontWeight: 'bold'
        }
      ];
      let chiffrages = [];

      // TODO: move to utils
      function sortLotNumber(aArray, bArray) {
        if(aArray.length === 0 && bArray.length === 0) {
          return 0
        }
        else if(aArray.length === 0 && bArray.length !== 0) {
          return -1
        }
        else if(aArray.length !== 0 && bArray.length === 0) {
          return 1
        }
        else {
          const a = aArray[0];
          const aRest = aArray.length > 1 ? aArray.slice(1) : []
          const b = bArray[0];
          const bRest = bArray.length > 1 ? bArray.slice(1) : []

          if(parseInt(a) < parseInt(b)) {
            return -1
          }
          else if(parseInt(a) > parseInt(b)) {
            return 1
          }
          else {
            return sortLotNumber(aRest, bRest);
          }
        }
      }
      function sortLots(a,b) {
        const regex = /\d+/g;
        let aArray = a.number.match(regex) || [];
        let bArray = b.number.match(regex) || [];

        return sortLotNumber(aArray, bArray)
      }
      function sortChiffrages(a, b) {
        if (a.order < b.order) { return -1; }
        if (a.order > b.order) { return 1; }
        return 0;
      }

      JSON.parse(JSON.stringify(proposal.lots)).sort(sortLots).forEach(l => {
        const sortedChiffrages = proposal.chiffrages.filter(c => c.lot.id === l.id).sort(sortChiffrages);

        sortedChiffrages.forEach(c => {
          if(project.featureMultiLocation){
            chiffrages.push([
              {
                type: String,
                value: c.lot.fullName ? String(c.lot.fullName) : "-"
              },
              {
                type: String,
                value: c.locations.map(l => l.name) ? c.locations.map(l => l.name).join(", ") : "-"
              },
              {
                type: String,
                value: c.title ? String(c.title) : "-"
              },
              {
                type: String,
                value: c.description ? String(c.description) : "-"
              },
              {
                type: String,
                value: c.qt ? String(c.qt) : "-"
              },
              {
                type: String,
                value: c.value && includeChiffrages ? String(c.value) : "-"
              },
              {
                type: String,
                value: ""
              },
              {
                type: String,
                value: ""
              },
            ])
          }
          else {
            chiffrages.push([
              {
                type: String,
                value: c.lot.fullName ? String(c.lot.fullName) : "-"
              },
              {
                type: String,
                value: c.location?.name ? String(c.location?.name) : "-"
              },
              {
                type: String,
                value: c.title ? String(c.title) : "-"
              },
              {
                type: String,
                value: c.description ? String(c.description) : "-"
              },
              {
                type: String,
                value: c.qt ? String(c.qt) : "-"
              },
              {
                type: String,
                value: includeChiffrages ? c.value && c.value !== 0 ? String(c.value) : "-" : "-"
              },
              {
                type: String,
                value: ""
              },
              {
                type: String,
                value: ""
              },
            ])
          }
        })
      })


      const data_core = [HEADER_ROW_CORE, ...chiffrages];
      const data_general = [[
        {
          value: 'nom',
          fontWeight: 'bold'
        },
        {
          value: 'adresse',
          fontWeight: 'bold'
        },
        {
          value: 'moe_name',
          fontWeight: 'bold'
        },
        {
          value: 'moe_number',
          fontWeight: 'bold'
        },
        {
          value: 'moe_email',
          fontWeight: 'bold'
        },
        {
          value: 'moa_name',
          fontWeight: 'bold'
        },
        {
          value: 'moa_number',
          fontWeight: 'bold'
        },
        {
          value: 'moa_email',
          fontWeight: 'bold'
        },
      ],
        [
          {
            type: String,
            value: project.name
          },
          {
            type: String,
            value: project.address
          },
          {
            type: String,
            value: project.contacts.find(c => c.role === "MOE")?.contact?.name || ""
          },
          {
            type: String,
            value: project.contacts.find(c => c.role === "MOE")?.contact?.phone || ""
          },
          {
            type: String,
            value: project.contacts.find(c => c.role === "MOE")?.contact?.email || ""
          },
          {
            type: String,
            value: project.contacts.find(c => c.role === "MOA")?.contact?.name || ""
          },
          {
            type: String,
            value: project.contacts.find(c => c.role === "MOA")?.contact?.phone || ""
          },
          {
            type: String,
            value: project.contacts.find(c => c.role === "MOA")?.contact?.email || ""
          },
        ]]
      const today_date = (new Date()).toISOString().slice(0, 10);

      await writeXlsxFile([data_core, data_general], {
        fileName: `kalm_chiffrage_${proposal.contact.name}_${today_date}.xlsx`,
        sheets: ['DATA', 'GENERAL']
      })
    },
    canDownloadAsXlsxV2(proposalId) {
      const proposal = kalmStore.getters.getProposalById(proposalId);
      return proposal?.fileXlsxUrl
    },
    downloadAsXlsxV2(proposalId) {
      const proposal = kalmStore.getters.getProposalById(proposalId);

      if(proposal?.fileXlsxUrl){
        if(proposal.fileXlsxUrl.startsWith("http")){
          window.open(proposal.fileXlsxUrl);
        }
        else {
          window.open(process.env.VUE_APP_URL_BACK + proposal.fileXlsxUrl);
        }
      }
    },
    updateOuvrages() {
      const projectId = kalmStore.getters.currentProjectFocus;

      this.modals.updateOuvrages.loading = true;

      this.$apollo.mutate({
        mutation: MUTATION_TENDER_UPDATE_OUVRAGES,
        variables: {
          projectId: projectId,
          importUnit: this.modals.updateOuvrages.importUnit,
          importQt: this.modals.updateOuvrages.importQt,
          importPrice: this.modals.updateOuvrages.importPrice,
        },
        update: (cache, result) => {
          const proposals = result.data.projectTenderUpdateOuvrages.tender.proposals;
          proposals.forEach(proposal => {
            kalmStore.commit("updateLocalProposal", {proposal})
          })
        }
      }).catch(() => {
        this.modals.updateOuvrages.loading = false;
        toast.error('Une erreur est survenue lors de la mise à jour des ouvrages');
      }).then(() => {
        this.modals.updateOuvrages.loading = false;
        toast.success('Les ouvrages ont été mis à jour avec succès');
      })
    },
    updateOuvragesLotsQuickSelect(proposals, mode){
      if(mode === 'all') {
        proposals.length = 0;
        this.proposals.forEach(p => {
          proposals.push(p.id)
        })
      }
      else {
        proposals.length = 0
      }
    },
    proposalLotsQuickSelect(lots, mode) {
      if(mode === 'all') {
        lots.length = 0;
        this.lots.forEach(l => {
          lots.push(l.id)
        })
      }
      else {
        lots.length = 0
      }
    },
    modalProposalDeleteShow(id) {
      this.modals.proposalDelete.show = true;
      this.modals.proposalDelete.id = id;
    },
    modalProposalDeleteClose() {
      this.modals.proposalDelete.show = false;
      this.modals.proposalDelete.id = '';
    },
    modalProposalDeleteValidate() {
      const apolloClient = this.$apollo;
      const proposalId = this.modals.proposalDelete.id;

      kalmStore.commit("deleteProposal", {proposalId, apolloClient})

      this.modalProposalDeleteClose()
    },
    modalProposalMenuShow() {
      this.modals.proposalMenu.show = true;
    },
    modalProposalMenuClose() {
      this.modals.proposalMenu.show = false;
    },
    modalProposalCreateShow() {
      this.modals.proposalMenu.show = false;
      this.modals.proposalCreate.page = 0;
      this.modals.proposalCreate.modeLots = "ALL";
      this.modals.proposalCreate.contact = '';
      this.modals.proposalCreate.importUnit = true;
      this.modals.proposalCreate.importQt = false;
      this.modals.proposalCreate.importPrice = false;
      this.modals.proposalCreate.lots = [];
      this.lots.forEach(l => {
        this.modals.proposalCreate.lots.push(l.id)
      })
      this.modals.proposalCreate.show = true;
    },
    modalProposalCreateClose() {
      this.modals.proposalCreate.show = false;
      this.modals.proposalCreate.contact = '';
      this.modals.proposalCreate.modeLots = "ALL";
      this.modals.proposalCreate.lots = [];
    },
    modalProposalCreateValidate() {
      this.modals.proposalCreate.page = 2;
      const projectId = kalmStore.getters.currentProjectFocus;
      const contactId = this.modals.proposalCreate.contact;
      const name = this.modals.proposalCreate.name;
      const apolloClient = this.$apollo
      const modeLots = this.modals.proposalCreate.modeLots;
      const lots = this.modals.proposalCreate.lots;
      const importUnit = this.modals.proposalCreate.importUnit;
      const importQt = this.modals.proposalCreate.importQt;
      const importPrice = this.modals.proposalCreate.importPrice;

      kalmStore.commit("createProposal", {projectId, contactId, name, modeLots, lots, importQt, importUnit, importPrice, apolloClient});
    },
    modalEstimationCreateShow() {
      this.modals.proposalMenu.show = false;
      this.modals.proposalEstimation.page = 0;
      this.modals.proposalEstimation.name = "Budget Estimatif";
      this.modals.proposalEstimation.importQt = true;
      this.modals.proposalEstimation.importUnit = true;
      this.modals.proposalEstimation.importPrice = true;
      this.modals.proposalEstimation.modeLots = "ALL";
      this.modals.proposalEstimation.lots = [];
      this.lots.forEach(l => {
        this.modals.proposalEstimation.lots.push(l.id)
      })
      this.modals.proposalEstimation.show = true;
    },
    modalEstimationCreateClose() {
      this.modals.proposalEstimation.show = false;
      this.modals.proposalEstimation.modeLots = "ALL";
      this.modals.proposalEstimation.lots = [];
    },
    modalEstimationCreateValidate() {
      this.modals.proposalEstimation.page = 2;
      const projectId = kalmStore.getters.currentProjectFocus;
      const name = this.modals.proposalEstimation.name;
      const apolloClient = this.$apollo
      const modeLots = this.modals.proposalEstimation.modeLots;
      const lots = this.modals.proposalEstimation.lots;
      const importUnit = this.modals.proposalEstimation.importUnit;
      const importQt = this.modals.proposalEstimation.importQt;
      const importPrice = this.modals.proposalEstimation.importPrice;

      kalmStore.commit("createProposal", {projectId, contactId: null, name, modeLots, lots, importUnit, importQt, importPrice, apolloClient});
    },
    modalProposalEditShow(proposalId) {
      const proposal = this.proposals.find(p => p.id === proposalId);

      this.modals.proposalEdit.proposalId = proposalId;
      this.modals.proposalEdit.contactId = proposal?.contact?.id || null;
      this.modals.proposalEdit.name = proposal.name;
      this.modals.proposalEdit.modeLots = proposal.modeLots;
      this.modals.proposalEdit.lots = proposal.lots.map(l => l.id);

      this.modals.proposalEdit.formattedSteps = JSON.parse(proposal.steps);
      this.modals.proposalEdit.formattedSteps.forEach(s => {
        this.modals.proposalEdit.modelSteps[s.id] = s.status;
      });

      this.modals.proposalEdit.notes = proposal.notes;
      this.modals.proposalEdit.vatDefaultValue = proposal.vatDefaultValue;
      this.modals.proposalEdit.show = true;
    },
    modalProposalEditClose() {
      this.modals.proposalEdit.proposalId = '';
      this.modals.proposalEdit.contactId = '';
      this.modals.proposalEdit.notes = '';
      this.modals.proposalEdit.name = '';
      this.modals.proposalEdit.vatDefaultValue = 0;
      this.modals.proposalEdit.formattedSteps = [];
      this.modals.proposalEdit.modelSteps = {};
      this.modals.proposalEdit.show = false;
    },
    modalProposalEditValidate() {
      const proposalId = this.modals.proposalEdit.proposalId;

      this.modals.proposalEdit.formattedSteps.map(s => {
        s.status = this.modals.proposalEdit.modelSteps[s.id] || false;
        return s;
      });

      const modeLots = this.modals.proposalEdit.modeLots;
      const lots = this.modals.proposalEdit.lots;

      const newProposal = {
        name: this.modals.proposalEdit.name,
        steps: JSON.stringify(this.modals.proposalEdit.formattedSteps),
        notes: this.modals.proposalEdit.notes,
        vatDefaultValue: parseFloat(this.modals.proposalEdit.vatDefaultValue),
      }

      const apolloClient = this.$apollo;
      kalmStore.commit("manageProposal", {proposalId, newProposal, modeLots, lots, apolloClient})
      this.modalProposalEditClose();
    },
    modalProposalDuplicateShow(proposalId) {
      const proposal = this.proposals.find(p => p.id === proposalId);

      this.modals.proposalDuplicate.proposalId = proposalId;
      this.modals.proposalDuplicate.name = "Copie de " + (proposal.name || proposal.contact.company || proposal.contact.name);

      this.modals.proposalDuplicate.show = true;
    },
    modalProposalDuplicateClose() {
      this.modals.proposalDuplicate.show = false;
      this.modals.proposalDuplicate.proposalId = '';
      this.modals.proposalDuplicate.name = '';
    },
    modalProposalDuplicateValidate() {
      const proposalId = this.modals.proposalDuplicate.proposalId;
      const name = this.modals.proposalDuplicate.name;
      const apolloClient = this.$apollo;

      kalmStore.commit("duplicateProposal", {proposalId, name, apolloClient})

      this.modalProposalDuplicateClose();
    },
    modalUpdateProposalShow(proposal){
      this.modalUpdateProposalCancel()
      this.modals.updateProposal.proposalId = proposal.id;
      this.modals.updateProposal.contactId = proposal.contact?.id
      this.modals.updateProposal.show = true;
    },
    modalUpdateProposalCancel(){
      this.modals.updateProposal.loadingTable = {};
      this.modals.updateProposal.show = false;
    },
    modalUpdateProposalValidate(){
      this.modals.updateProposal.loadingTable[this.modals.updateProposal.proposalId] = true;

      this.$apollo.mutate({
        mutation: MUTATION_TENDER_PROPOSAL_UPDATE_OUVRAGES,
        variables: {
          proposalId: this.modals.updateProposal.proposalId,
          importUnit: this.modals.updateProposal.importUnit,
          importQt: this.modals.updateProposal.importQt,
          importPrice: this.modals.updateProposal.importPrice,
          resetExisting: this.modals.updateProposal.resetExisting,
        },
        update: (cache, result) => {
          const proposal = result.data.projectTenderProposalUpdateOuvrages.proposal;
          kalmStore.commit("updateLocalProposal", {proposal})
        }
      }).catch(() => {
        this.modals.updateProposal.loadingTable[this.modals.updateProposal.proposalId] = false;
        toast.error('Une erreur est survenue lors de la mise à jour des ouvrages');
      }).then(() => {
        this.modals.updateProposal.loadingTable[this.modals.updateProposal.proposalId] = false;
        toast.success('Les ouvrages ont été mis à jour avec succès');
      })

      this.modals.updateProposal.show = false;
    },
    modalDownloadShow(proposalId){
      this.modals.download.show = true;
      this.modals.download.proposalId = proposalId;
      this.modals.download.name = this.proposals.find(p => p.id === proposalId)?.name;
      this.modals.download.contactId = this.proposals.find(p => p.id === proposalId)?.contact ? this.proposals.find(p => p.id === proposalId).contact.id : null;
    },
    modalDownloadClose(){
      this.modals.download.show = false;
    },
    modalUpdateOuvragesShow(){
      this.modals.updateOuvrages.show = true;
      this.modals.updateOuvrages.importUnit = true;
      this.modals.updateOuvrages.importQt = false;
      this.modals.updateOuvrages.importPrice = false;
    },
    modalUpdateOuvragesClose(){
      this.modals.updateOuvrages.show = false;
    },
    modalUpdateOuvragesValidate(){
      this.updateOuvrages();
      this.modalUpdateOuvragesClose();
    },
    getContactNameById(contactId) {
      let ret = "";
      if(contactId) {
        const contact = this.contacts.find( c => c.id === contactId );
        ret = contact?.company || contact?.name || "";
      }
      return ret;
    },
    /*copyLinkToClipboard() {
      const element = this.$refs.dceModalLinkShareField;
      element.select();
      element.setSelectionRange(0, 99999);
      document.execCommand('copy');
    },*/
  },
  async mounted() {
    if((kalmStore.getters.currentProjectFocus
        && kalmStore.getters.currentProjectFocus !== "0"
        && kalmStore.getters.currentProjectFocus !== 0)) {
      const query = await this.$apollo.query({
        query: GET_PROPOSALS_FOR_PROJECT,
        variables: {
          projectId: kalmStore.getters.currentProjectFocus,
        },
        skip() {
          return !(kalmStore.getters.currentProjectFocus
              && kalmStore.getters.currentProjectFocus !== "0"
              && kalmStore.getters.currentProjectFocus !== 0)
        },
      });

      query.data.proposals.forEach( (newProposal) => {
        kalmStore.commit("initializeProposal", { newProposal })
      })
    }
  },
  watch: {
    'currentProjectFocus': async function() {
      const query = await this.$apollo.query({
        query: GET_PROPOSALS_FOR_PROJECT,
        variables: {
          projectId: kalmStore.getters.currentProjectFocus,
        },
        skip() {
          return !(kalmStore.getters.currentProjectFocus
              && kalmStore.getters.currentProjectFocus !== "0"
              && kalmStore.getters.currentProjectFocus !== 0)
        },
      });

      query.data.proposals.forEach( (newProposal) => {
        kalmStore.commit("initializeProposal", { newProposal })
      })
    }
  }
}
</script>

<style scoped>
  .blur-content{
    filter: blur(5px);
  }
</style>