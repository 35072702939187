import { VueRenderer } from '@tiptap/vue-3'
import tippy from 'tippy.js'

import CommandsList from './CommandsList.vue'
import {hasParentNodeOfType} from "prosemirror-utils";

export default {
    items: ({ query, editor }) => {
        return [
            {
                title: 'Bloc ouvrage',
                disabled: (editor) => {
                    return !hasParentNodeOfType(editor.state.doc.type.schema.nodes.docLot)(editor.state.selection)
                },
                command: ({ editor, range }) => {
                    editor
                        .chain()
                        .focus()
                        .deleteRange(range)
                        .insertContent('<ouvrage><h4></h4></ouvrage>')
                        .run()
                },
            },
            {
                title: 'Bloc texte',
                disabled: () => {
                    return false
                },
                command: ({ editor, range }) => {
                    editor
                        .chain()
                        .focus()
                        .deleteRange(range)
                        .insertContent('<lot-text><p></p></lot-text>')
                        .run()
                },
            },
        ].filter(item => {
            return item.title.toLowerCase().startsWith(query.toLowerCase()) && !item.disabled(editor)
        }).slice(0, 10)
    },

    render: () => {
        let component
        let popup

        return {
            onStart: props => {
                component = new VueRenderer(CommandsList, {
                    // using vue 2:
                    // parent: this,
                    // propsData: props,
                    props,
                    editor: props.editor,
                })

                if (!props.clientRect) {
                    return
                }

                popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                })
            },

            onUpdate(props) {
                component.updateProps(props)

                if (!props.clientRect) {
                    return
                }

                popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                })
            },

            onKeyDown(props) {
                if (props.event.key === 'Escape') {
                    popup[0].hide()

                    return true
                }

                return component.ref?.onKeyDown(props)
            },

            onExit() {
                popup[0].destroy()
                component.destroy()
            },
        }
    },
}
