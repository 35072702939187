<template>
    <div :class="modalActive ? 'blur-content' : ''">
        <div class="print-hidden">
            <ProjectBreadcrumbComponent title="Suivi de Chantier" current_route="project.monitoring_old"
                                        :project_id=$route.params.id :options="[
          { name: 'Cockpit de chantier', id: 'project.monitoring_old' },
          { name: 'App Chantier', type: 'link', url: currentUser.appsheetUrl, disabled: !this.currentUser.appsheetUrl },
          { name: 'Avancement', type: 'link', url: this.project.betaSpreadsheet, disabled: !this.project.betaSpreadsheet },
        ]"></ProjectBreadcrumbComponent>
        </div>
        <!--begin::Row-->
        <!--  <div class="row">
            <div class="col-12">
              <ProgressCard
                  card_type="kalm"
                  card_title="Suivi de chantier"
                  card_subtitle="Dernière réunion il y a 2 jours"
                  card_text_line_1="7 ouvrages réalisés"
                  card_text_line_2="90 restants"
                  :card_progress_percent="8"></ProgressCard>
            </div>
          </div>-->
        <!--end::Row-->
        <h2 class="text-dark fw-bolder mt-10 mb-5 print-hidden">Compte-rendus</h2>
        <div class="row g-3 print-hidden">

            <div v-if="!this.$apollo.queries.monitoringReports.loading && (!this.monitoringReports || this.monitoringReports.length === 0)">
                Les compte-rendus générés avec l'application de suivi de chantier apparaîtront ici.

            </div>
            <div v-for="report in this.monitoringReports" :key="report.id" class="col-12 col-sm-6 col-md-4">
                <a class="card card-stretch my-2 h-100 " :href="report.url" target="_blank">
                    <!--begin::Body-->
                    <div class="card-body">
                        <!--begin::Section-->
                        <div class="d-flex align-items-center">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-50px me-5">
                <span class="symbol-label bg-light-primary">
                  <!--begin::Svg Icon | path: icons/duotone/Home/Library.svg-->
                  <i class="bi bi-file-text"></i>
                    <!--end::Svg Icon-->
                </span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div>
                                <a v-show="report.reportName !== null" class="fs-4 text-gray-800 fw-bolder">Compte-rendu de chantier n°{{ report.reportNumber }}</a>
                                <div v-show="report.reportDate !== null" class="fs-7 text-muted fw-bold mt-1">{{ report.reportDate }}</div>
                            </div>
                            <!--end::Title-->
                        </div>
                        <!--end::Section-->
                    </div>
                    <!--end::Body-->
                </a>
            </div>
        </div>
        <PrintHeader pageTitle="Suivi du chantier" sectionTitle="Planning de chantier"></PrintHeader>
        <h2 class="text-dark fw-bolder mt-5 mb-5 print-hidden">Tableau de bord</h2>
        <!--  PLANNING DE CHANTIER  -->
        <div class="row g-5">
            <div class="col-12 print-hidden">
                <div class="card">
                    <div class="card-header border-0 pt-5">
                        <h3 class="card-title align-items-start flex-column">
                            <span class="card-label fw-bolder text-dark fs-3"><i class="bi fs-3 me-2 bi-gear"></i>Informations importantes générales</span>
                        </h3>
                    </div>
                    <div class="card-body w-100 row pt-2">
                        <div class="col-12">
                            <div class="form-group w-100 h-100 pb-9 mb-3">
                                <label class="text-muted">Compte-rendus — Informations importantes par défaut <i class="bi bi-info-circle"
                                                                                                                 v-tooltip:bottom="`Code d\'immeuble, précaution importante... ce paragraphe sera la valeur par défaut de la rubrique &quot;Informations Importantes&quot; des comptes-rendus de chantier de ce projet. Vous pourrez la personnaliser sur chaque compte-rendu dans l\'application Kalm.`"></i><span></span></label>
                                <textarea style="height:100%;" v-model="this.projectProperties.importantInfo"
                                          class="form-control form-control-lg form-control-solid w-100" type="text"></textarea>
                            </div>
                        </div>
                        <div class="flex-column text-end">
                            <button :disabled="this.isArchived" class="btn btn-primary btn-active-accent"
                                    @click="this.updateProjectProperties()">
                                Enregistrer
                                <span v-if="this.buttonProjectPropertiesLoading" class="ms-1 spinner-border spinner-border-sm"
                                      role="status">
                  <span class="sr-only">Loading...</span>
                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 print-hidden">
                <!--begin::Card-->
                <div class="card card-stretch h-100 mh-100 p-2 flex-grow-1">
                    <!--begin::Body-->
                    <div class="card-header border-0 pt-5">
                        <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3"><i
                  class="bi fs-3 me-2 bi-gear-wide-connected"></i>Synchronisation descriptif &lt;&gt; chantier</span>
                        </h3>
                    </div>
                    <div class="card-body pt-3 mt-n3 align-content-center d-flex">
                        <div class="align-self-center my-auto">
                            <label class="me-2">Importer mon descriptif dans l'app de suivi de chantier :</label>
                            <button class="btn btn-light btn-active-accent fw-bolder" @click="this.modalUploadTaskShow()">Lancer
                                l'import</button>
                        </div>
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Card-->
            </div>
        </div>
    </div>
    <!-- MODAL - CONFIRM UPLOAD TASKS-->
    <div class="modal" tabindex="-1" @keydown.esc="this.modalUploadTaskClose()"
         :style="this.modalUploadTask.show ? 'display: block;' : 'display: none;'">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalUploadTaskClose()">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Stepper-->
                        <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content0-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <form ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <!--begin::Heading-->
                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">Confirmer l'import descriptif > chantier</h3>
                                            </div>
                                            <p>Tous les nouveaux ouvrages de votre descriptif vont être importés comme des tâches dans
                                                l'application de suivi de chantier.</p>
                                        </div>
                                    </div>
                                    <!--end::Step-->

                                    <!--begin::Actions-->
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button type="button" @click="this.modalUploadTaskClose()" class="btn btn-lg btn-light">
                                                Annuler
                                            </button>
                                            <button type="button" @click="this.modalUploadTaskValidate()" class="btn btn-lg btn-primary ms-2">
                                                Importer
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </form>
                                <!--end::Form-->
                            </div>
                            <!--end::Content0-->
                        </div>
                        <!--end::Stepper-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL EDIT LOT -->
    <div class="modal" tabindex="-1" :style="modals.editLotPlanning.show ? 'display: block;' : 'display: none;'">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="modalEditLotPlanningCancel">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Stepper-->
                        <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content0-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <form ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <!--begin::Heading-->
                                            <div class="pb-1">
                                                <h3 class="fw-bolder text-dark display-6">Modifier un lot</h3>
                                            </div>
                                            <!--end::Heading-->

                                            <!--begin::Form Group-->
                                            <div class="mt-5 w-100">
                                                <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de début</label>
                                                <label class="ms-1 fs-8 text-muted form-label"></label>
                                                <input type="date" v-model="modals.editLotPlanning.lot.startDate"
                                                       class="form-control form-control-lg form-control-solid" placeholder="2023-07-01">
                                            </div>
                                            <!--end::Form Group-->

                                            <!--begin::Form Group-->
                                            <div class="mt-5 w-100">
                                                <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de fin</label>
                                                <label class="ms-1 fs-8 text-muted form-label"></label>
                                                <input type="date" v-model="modals.editLotPlanning.lot.endDate"
                                                       class="form-control form-control-lg form-control-solid" placeholder="2023-08-01">
                                            </div>
                                            <!--end::Form Group-->

                                            <!--begin::Form Group-->
                                            <div class="mt-5 w-100">
                                                <label class="fs-6 me-2 fw-bolder text-dark form-label">Commentaire pour le CR</label>
                                                <textarea v-model="modals.editLotPlanning.lot.comment"
                                                          class="form-control form-control-lg form-control-solid"
                                                          placeholder="Pose du parquet dans les chambres lundi et mardi"></textarea>
                                            </div>
                                            <!--end::Form Group-->

                                        </div>
                                    </div>
                                    <!--end::Step-->

                                    <!--begin::Actions-->
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button type="button" @click="modalEditLotPlanningCancel" class="btn btn-lg btn-light">
                                                Annuler
                                            </button>
                                            <button type="button" @click="modalEditLotPlanningValidate" class="btn btn-lg btn-primary ms-2">
                                                Modifier
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </form>
                                <!--end::Form-->
                            </div>
                            <!--end::Content0-->
                        </div>
                        <!--end::Stepper-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import ProgressCard from "@/views/_core/components/ProgressCard";
import {
    CURRENT_USER_QUERY,
    GET_PROJECT_BY_ID,
    MUTATION_PROJECT_UPLOAD_TASKS, GET_CRS_FOR_PROJECT
} from "@/graphql/graphql";
import kalmStore from "@/store";
import ProjectBreadcrumbComponent from "@/views/project/ProjectBreadcrumbComponent";
import PrintHeader from "@/views/project/tender/PrintHeader";

export default {
    name: "MonitoringPage",
    components: {/*ProgressCard, */ProjectBreadcrumbComponent, PrintHeader },
    apollo: {
        currentUser: {
            query: CURRENT_USER_QUERY
        },
        project: {
            query: GET_PROJECT_BY_ID,
            variables() {
                return {
                    id: this.currentProjectId,
                }
            },
            skip() {
                return !(this.currentProjectId
                    && this.currentProjectId !== "0"
                    && this.currentProjectId !== 0)
            }
        },
        monitoringReports: {
            query: GET_CRS_FOR_PROJECT,
            variables () {
                return {
                    projectId: kalmStore.getters.currentProjectFocus,
                }
            },
            skip() {
                return !(kalmStore.getters.currentProjectFocus
                    && kalmStore.getters.currentProjectFocus !== "0"
                    && kalmStore.getters.currentProjectFocus !== 0)
            },
        }
    },
    data() {
        return {
            currentUser: {},
            project: {},
            modalUploadTask: {
                show: false,
            },
            modals: {
                editLotPlanning: {
                    show: false,
                    lotId: "",
                    lot: {
                        comment: "",
                        startDate: "",
                        endDate: "",
                    }
                }
            },
            buttonProjectPropertiesLoading: false,
            editProject: {
                importantInfo: "",
            },
        }
    },
    methods: {
        printDocument() {
            window.print();
        },
        modalEditLotPlanningShow(lotId) {
            this.modals.editLotPlanning.lotId = lotId;

            const lot = this.currentProject.lots.find(l => l.id === lotId);

            this.modals.editLotPlanning.lot.comment = lot.comment;
            this.modals.editLotPlanning.lot.startDate = lot.startDate;
            this.modals.editLotPlanning.lot.endDate = lot.endDate;

            this.modals.editLotPlanning.show = true;
        },
        modalEditLotPlanningCancel() {
            this.modals.editLotPlanning.show = false;

            this.modals.editLotPlanning.lotId = "";
            this.modals.editLotPlanning.lot.comment = "";
            this.modals.editLotPlanning.lot.startDate = "";
            this.modals.editLotPlanning.lot.endDate = "";
        },
        modalEditLotPlanningValidate() {

            kalmStore.dispatch("editLotPlanning", {
                lotId: this.modals.editLotPlanning.lotId,
                apolloClient: this.$apollo,
                startDate: this.modals.editLotPlanning.lot.startDate,
                endDate: this.modals.editLotPlanning.lot.endDate,
                comment: this.modals.editLotPlanning.lot.comment,
            })

            this.modalEditLotPlanningCancel();
        },
        modalUploadTaskShow() {
            this.modalUploadTask.show = true;
        },
        modalUploadTaskValidate() {
            this.modalUploadTaskClose();
            this.uploadTasks();
        },
        modalUploadTaskClose() {
            this.modalUploadTask.show = false;
        },
        uploadTasks() {
            this.$apollo.mutate(
                {
                    mutation: MUTATION_PROJECT_UPLOAD_TASKS,
                    variables: {
                        projectId: this.currentProjectId,
                    },
                    skip() {
                        return !(this.currentProjectId
                            && this.currentProjectId !== "0"
                            && this.currentProjectId !== 0)
                    },
                }
            )
        },
        storeIsReady() {
            return this.currentProjectId
                && this.currentProjectId !== "0"
                && this.currentProjectId !== 0
        },
        updateProjectProperties() {
            this.buttonProjectPropertiesLoading = true;

            setTimeout(() => {
                this.buttonProjectPropertiesLoading = false;
            }, 350);

            const apolloClient = this.$apollo;
            const newProperties = {
                importantInfo: this.projectProperties.importantInfo,
            }

            kalmStore.dispatch("updateProjectProperties", { apolloClient, newProperties })
        },
    },
    computed: {
        currentProjectId() {
            return kalmStore.getters.currentProjectFocus;
        },
        modalActive() {
            return kalmStore.state.display.profileToggled || this.modalUploadTask.show || this.modals.editLotPlanning.show
        },
        currentProject() {
            return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus)
        },
        isArchived() {
            return this.currentProject.archived
        },
        projectProperties: {
            get() {
                return {
                    importantInfo: this.editProject.importantInfo || this.currentProject.importantInfo,
                }
            },
            set(newValue) {
                this.editProject = newValue;
            }
        },
    },
}
</script>

<style scoped>
@media print {
    .print-hidden {
        display: none;
    }
}
</style>