<template>
    <div :class="{'blur-content': this.isModalActive}"
         class="row g-5">
        <!-- 1 - MES BIBLIOTHÈQUES -->
        <div class="col-12" id="biblios">
            <div class="card card-stretch">
                <!--begin::Header-->
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder text-dark fs-3">Mes bibliothèques</span>
                    </h3>
                    <div class="card-toolbar">
                        <ul class="nav nav-pills nav-pills-sm nav-light">
                            <li class="nav-item">
                                <a class="nav-link btn btn-primary fw-bolder me-2" @click="modalCreateBiblioShow()">Créer une bibliothèque</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-3 mt-n3">
                    <div class="tab-content mt-4" id="myTabTables2">
                        <!--begin::Table-->
                        <div class="table-responsive">
                            <div v-if="this.sortedBiblios.length">
                                <table class="table table-borderless align-middle">
                                    <thead>
                                    <tr>
                                        <th class="px-2 py-0 w-50px"></th>
                                        <th class="px-2 py-0 w-100"></th>
                                        <th class="px-2 py-0 min-w-100px"></th>
                                        <th class="px-2 py-0 min-w-100px"></th>
                                        <th class="px-2 py-0 min-w-50px"></th>
                                        <th class="px-2 py-0 min-w-100px"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="biblio in this.sortedBiblios.filter(b => b.type !== 'DEFAULT')" :key="biblio.id" @click="this.navigateToBiblio(biblio.id)" class="bg-hover-light">
                                        <td class="px-0 py-3 rounded-start p-5">
                                            <div class="symbol symbol-55px ms-5 mt-1 me-5">
                        <span class="symbol-label bg-light-primary align-middle align-items-end">
                <!--         REMPLACER PAR IMAGE-AVATAR DU PROJET <img alt="Logo" src="/assets/media/svg/avatars/001-boy.svg" class="mh-40px" />-->
                          <i class="bi bi-bookmark-check fs-1 align-middle align-self-center"></i>
                        </span>
                                            </div>
                                        </td>
                                        <td class="px-0">
                                            <p class="text-gray-800 fw-bolder fs-6 my-1">{{ biblio.name }}</p>
                                        </td>
                                        <td></td>
                                        <td class="px-0 text-center">
                                            <!--                      <div v-if="project?.team?.length" class="d-grid justify-content-center m-auto" style="grid-template-columns: repeat(auto-fit,  minmax(10px, max-content))">
                                                                    <div class="bg-light text-gray-800 border w-auto text-center rounded rounded-circle text-center align-content-center w-25px h-25px" style="z-index: 3;">A</div>
                                                                    <div class="bg-light text-gray-800 border w-auto text-center rounded rounded-circle text-center align-content-center w-25px h-25px" style="z-index: 2; transform: translateX(-7px);">B</div>
                                                                    <div class="bg-light text-gray-800 border w-auto text-center rounded rounded-circle text-center align-content-center w-25px h-25px" style="z-index: 1; transform: translateX(-14px);">C</div>
                                                                  </div>-->
                                        </td>
                                        <td></td>
                                        <td class="px-0 rounded-end">
                                            <button v-tooltip:bottom="'Supprimer la bibliothèque'" @click="this.modalConfirmDeleteBiblioShow(biblio.id)" class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"><i class="bi bi-trash-fill align-baseline text-center"></i></button>
                                            <!-- <button v-tooltip:bottom="'Dupliquer ce projet'" @click="this.modalDuplicateFromProjectShow(project.id)" class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"><i class="fa fa-copy align-baseline text-center"></i></button>-->
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-else>
                                <p class="text-center text-muted my-8">
                                    <span class="bi bi-folder me-2"></span>Aucune bibliothèque. Cliquez sur créer une bibliothèque pour en ajouter une<span class="ms-1 bi bi-arrow-up-right"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--end::Table-->
                </div>
                <!--end::Body-->
            </div>
        </div>
        <!-- 2 - BIBLIOTHÈQUES PAR DÉFAUT -->
        <div class="col-12" id="biblios">
            <div class="card card-stretch">
                <!--begin::Header-->
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder text-dark fs-3">Bibliothèques par défaut</span>
                    </h3>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-3 mt-n3">
                    <div class="tab-content mt-4" id="myTabTables2">
                        <!--begin::Table-->
                        <div class="table-responsive">
                            <div v-if="this.sortedBiblios.length">
                                <table class="table table-borderless align-middle">
                                    <thead>
                                    <tr>
                                        <th class="px-2 py-0 w-50px"></th>
                                        <th class="px-2 py-0 w-100"></th>
                                        <th class="px-2 py-0 min-w-100px"></th>
                                        <th class="px-2 py-0 min-w-100px"></th>
                                        <th class="px-2 py-0 min-w-50px"></th>
                                        <th class="px-2 py-0 min-w-100px"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="biblio in this.sortedBiblios.filter(b => b.type === 'DEFAULT')" :key="biblio.id" @click="this.navigateToBiblio(biblio.id)" class="bg-hover-light">
                                        <td class="px-0 py-3 rounded-start p-5">
                                            <div class="symbol symbol-55px ms-5 mt-1 me-5">
                        <span class="symbol-label bg-light-primary align-middle align-items-end">
                <!--         REMPLACER PAR IMAGE-AVATAR DU PROJET <img alt="Logo" src="/assets/media/svg/avatars/001-boy.svg" class="mh-40px" />-->
                          <i class="bi bi-bookmark-check fs-1 align-middle align-self-center"></i>
                        </span>
                                            </div>
                                        </td>
                                        <td class="px-0">
                                            <p class="text-gray-800 fw-bolder fs-6 my-1">{{ biblio.name }}</p>
                                        </td>
                                        <td></td>
                                        <td class="px-0 text-center">
                                            <!--                      <div v-if="project?.team?.length" class="d-grid justify-content-center m-auto" style="grid-template-columns: repeat(auto-fit,  minmax(10px, max-content))">
                                                                    <div class="bg-light text-gray-800 border w-auto text-center rounded rounded-circle text-center align-content-center w-25px h-25px" style="z-index: 3;">A</div>
                                                                    <div class="bg-light text-gray-800 border w-auto text-center rounded rounded-circle text-center align-content-center w-25px h-25px" style="z-index: 2; transform: translateX(-7px);">B</div>
                                                                    <div class="bg-light text-gray-800 border w-auto text-center rounded rounded-circle text-center align-content-center w-25px h-25px" style="z-index: 1; transform: translateX(-14px);">C</div>
                                                                  </div>-->
                                        </td>
                                        <td></td>
                                        <td class="px-0 rounded-end">
                                            <!-- <button v-tooltip:bottom="'Dupliquer ce projet'" @click="this.modalDuplicateFromProjectShow(project.id)" class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"><i class="fa fa-copy align-baseline text-center"></i></button>-->
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-else>
                                <p class="text-center text-muted my-8">
                                    <span class="bi bi-folder me-2"></span>Aucune bibliothèque. Cliquez sur créer une bibliothèque pour en ajouter une<span class="ms-1 bi bi-arrow-up-right"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--end::Table-->
                </div>
                <!--end::Body-->
            </div>
        </div>
    </div>
    <!-- MODAL CREATE NEW BIBLIO -->
    <div class="modal"
         tabindex="-1"
         :style="this.modals.createBiblio.show ? 'display: block;' : 'display: none;'">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="modalCreateBiblioCancel()">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Stepper-->
                        <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <div ref="contactEditModalForm"
                                     class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">

                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">Nouvelle bibliothèque d'ouvrages</h3>
                                            </div>

                                            <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                <label class="fs-6 fw-bolder text-dark form-label">Nom de la bibliothèque</label>
                                                <input v-model="modals.createBiblio.newBiblio.name" type="text" class="form-control form-control-lg form-control-solid" placeholder="Projet Témoin - Appartement">
                                            </div>

                                        </div>
                                    </div>
                                    <!--end::Step-->

                                    <!--begin::Actions-->
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button class="btn btn-lg btn-light"
                                                    @click="modalCreateBiblioCancel()">
                                                Annuler
                                            </button>
                                            <button class="btn ms-2 btn-lg btn-primary btn-active-light-primary"
                                                    :disabled="!modals.createBiblio.newBiblio.name"
                                                    @click="modalCreateBiblioValidate()">
                                                Créer une bibliothèque
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </div>
                                <!--end::Form-->
                            </div>
                            <!--end::Content-->
                        </div>
                        <!--end::Stepper-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL CONFIRM DELETE BIBLIO -->
    <div class="modal"
         tabindex="-1"
         :style="this.modals.deleteBiblio.show ? 'display: block;' : 'display: none;'">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalConfirmDeleteBiblioCancel()">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Stepper-->
                        <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content0-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <form ref="contactModalForm"
                                      class="pb-5 w-100 w-sm-400px" novalidate>
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <!--begin::Heading-->
                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">Supprimer la bibliothèque</h3>
                                            </div>
                                            <p>
                                                Vous pourrez toujours le consulter, le dupliquer, mais plus le modifier ou en faire des exports. Si besoin de le désarchiver, n'hésitez pas à contacter le service client :)
                                            </p>
                                        </div>
                                    </div>
                                    <!--end::Step-->

                                    <!--begin::Actions-->
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button type="button" @click="this.modalConfirmDeleteBiblioCancel()" class="btn btn-lg btn-light">
                                                Annuler
                                            </button>
                                            <button type="button" @click="this.modalConfirmDeleteBiblioValidate()" class="btn btn-lg btn-primary ms-2">
                                                Confirmer
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </form>
                                <!--end::Form-->
                            </div>
                            <!--end::Content0-->
                        </div>
                        <!--end::Stepper-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {
    ALL_BIBLIO_QUERY, MUTATION_BIBLIO_CREATE, MUTATION_BIBLIO_DELETE
} from "@/graphql/graphql";
import kalmStore from "@/store";


export default {
    name: "BibliosPage",
    apollo: {
        biblios: {
            query: ALL_BIBLIO_QUERY,
        },
    },
    components: { },
    methods: {
        modalConfirmDeleteBiblioShow(id) {
            event.stopPropagation()
            this.modals.deleteBiblio.id = id
            this.modals.deleteBiblio.show = true;
        },
        modalConfirmDeleteBiblioCancel() {
            this.modals.deleteBiblio.id = "";
            this.modals.deleteBiblio.show = false;
        },
        modalConfirmDeleteBiblioValidate() {
            const id = this.modals.deleteBiblio.id;

            this.$apollo.mutate({
                mutation: MUTATION_BIBLIO_DELETE,
                variables: { id },
                refetchQueries: [
                    {
                        query: ALL_BIBLIO_QUERY,
                    }
                ],
            })

            this.modals.deleteBiblio.show = false;
        },
        navigateToBiblio(biblioId) {
            this.$router.push({
                name: "biblio.edit",
                params: {
                    id: biblioId
                }
            });
        },
        modalCreateBiblioShow() {
            this.modals.createBiblio.show = true;
            this.modals.createBiblio.newBiblio.name = "";
        },
        modalCreateBiblioCancel() {
            this.modals.createBiblio.show = false;
            this.modals.createBiblio.newBiblio.name = "";
        },
        modalCreateBiblioValidate() {
            this.$apollo.mutate(
                {
                    mutation: MUTATION_BIBLIO_CREATE,
                    variables: {
                        newBiblioName: this.modals.createBiblio.newBiblio.name,
                    },
                    refetchQueries: [
                        {
                            query: ALL_BIBLIO_QUERY,
                        }
                    ],
                }
            ).then((res) => {
                this.$router.push({
                    name: "biblio.edit",
                    params: {
                        id: res.data.biblioCreate.biblio.id,
                    }
                });

            })

            this.modalCreateBiblioCancel()
        },
    },
    computed: {
        sortedBiblios() {
            return JSON.parse(JSON.stringify(this.biblios || [])).sort((a, b) => a.name.localeCompare(b.name))
        },
        isModalActive() {
            return kalmStore.state.display.profileToggled
                || this.modals.createProject.show
                || this.modals.duplicateProject.show
                || this.modals.archiveProject.show
                || this.modals.unarchiveProject.show
                || this.modals.deleteProject.show
                || this.modals.createBiblio.show
                || this.modals.deleteBiblio.show
        },
        sortedProjects() {
            return JSON.parse(JSON.stringify(this.projects)).sort(function(a, b){
                if(''+a.name < ''+b.name) return -1
                if(''+a.name > ''+b.name) return 1
                return 0
            })
        }
    },
    data() {
        return {
            modals: {
                createProject: {
                    show: false,
                    newProject: {
                        name: "",
                        newInfoOpName: "",
                        newInfoOpAddress: "",
                    }
                },
                createBiblio: {
                    show: false,
                    newBiblio: {
                        name: "",
                    }
                },
                duplicateBiblio: {
                    show: false,
                    oldBiblioId: "",
                    newBiblio: {
                        name: "",
                    }
                },
                duplicateProject: {
                    show: false,
                    oldProjectId: "",
                    duplicateLocations: true,
                    duplicateContacts: true,
                    duplicateQts: true,
                    newProject: {
                        name: "",
                        newInfoOpName: "",
                        newInfoOpAddress: "",
                    }
                },
                archiveProject: {
                    show: false,
                    projectId: "",
                },
                deleteBiblio: {
                    show: false,
                    id: "",
                },
                unarchiveProject: {
                    show: false,
                    projectId: "",
                },
                deleteProject: {
                    show: false,
                    projectId: "",
                }
            },
            projects: [],
            libraries: [],
        }
    }
}
</script>

<style scoped>

</style>