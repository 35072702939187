<template>
  <div class="d-flex px-5 flex-stack flex-wrap flex-sm-nowrap">
    <!--begin::Info-->
    <div class="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
      <!--begin::Title-->
      <h3 class="text-dark fw-bolder my-1">{{ title }}</h3>
      <!--end::Title-->
      <!--begin::Breadcrumb-->
      <ul class="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
        <li class="breadcrumb-item">
          <router-link :to="{name: 'project.home', params: {id: project_id}}" class="text-muted text-hover-primary">Projet</router-link>
        </li>
        <li class="breadcrumb-item text-dark">
          <router-link :to="{name: current_route, params: {id: project_id}}" class="text-muted text-hover-primary">{{ title }}</router-link>
        </li>
      </ul>
      <!--end::Breadcrumb-->
    </div>
    <!--end::Info-->
    <!--begin::Nav-->
    <div class="d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1">
      <div v-for="option in this.$props.options" :key="option.id">
        <a v-if="option.type === 'link'" target="_blank" :href="option.url" :class="{ disabled: option?.disabled }" class="btn btn-active-accent fw-bolder ms-2">
          <i class="bi bi-arrow-up-right me-2 align-middle px-0"></i>{{ option.name }}
        </a>
        <router-link v-else :to="{name: option.id, params: {id: project_id}}"
                     :class="{ active: isPageActive(option.id), disabled: option?.disabled }"
                     class="btn btn-active-accent fw-bolder ms-2 px-5">{{ option.name }}</router-link>
      </div>
    </div>
    <!--end::Nav-->
  </div>
  <br>
</template>

<script>
export default {
  name: "ProjectBreadcrumbComponent",
  props: {
    title: {
      type: String,
      default: 'Titre'
    },
    project_id: {
      type: String,
    },
    appsheetUrl: {
      type: String,
    },
    current_route: {
      type: String,
      default: 'project.home'
    },
    options: {
      type: Array,
      default: () => [],
    }
  },
  methods: {
    /**
     * Compares a menu page ID to the current route
     * @param page_id
     * @returns {boolean} true if page_id matches current route
     */
    isPageActive: function(page_id) {
      return this.$route.name === page_id;
    }
  }
}
</script>

<style scoped>
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
</style>