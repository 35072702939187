import posthog from "posthog-js";

export default {
  install(app) {
      app.config.globalProperties.$posthog = posthog.init(
          "phc_x0eN1WB4CibEC0numDfoXqjDBPF9pgjcnK0LKcQC6U8",
          {
              api_host: "https://eu.posthog.com",
              session_recording: {
                  maskAllInputs: false,
                  maskInputOptions: {
                      password: true,
                  },
                  maskCapturedNetworkRequestFn: (request) => {
                      return request;
                  }
              }
          },
      );
  },
};