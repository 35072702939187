<template>
  <div class="container p-0"
       :class="isModalActive ? 'blur-content' : ''">
    <div class="editor row g-0 mt-xs-0 mt-sm-12" tabindex=0>
      <!-- EDITOR BUTTONS BAR - SOLID -->
      <input id="modalInsertPhotoInput" ref="modalInsertPhotoInput" type="file" accept="image/png, image/jpeg" @change="this.modalInsertPhotoValidate" multiple hidden>
      <div v-if="editor && incrementDummy" class="justify-content-center d-flex flex-lg-nowrap flex-wrap mx-auto print-hidden w-100 sticky-top" style='top: 45px;'>
        <div class="print-hidden editor-toolbar toolbar-top px-2 px-md-7 d-flex justify-content-between align-items-center w-100 mt-0">
          <div></div>
          <div class="px-2 text-center">
            <button class="btn btn-color-white mx-1 p-2" v-tooltip:bottom="'Annuler'" @click="editor.chain().focus().undo().run()"  :disabled="!editor.can().chain().focus().undo().run()">
              <span class="text-center align-middle">
                <i class="bi bi-arrow-counterclockwise fs-2 p-0"></i>
              </span>
            </button>
            <button class="btn btn-color-white mx-1 p-2" v-tooltip:bottom="'Refaire'" @click="editor.chain().focus().redo().run()"  :disabled="!editor.can().chain().focus().redo().run()">
              <span class="text-center align-middle">
                <i class="bi bi-arrow-clockwise fs-2 p-0"></i>
              </span>
            </button>
            <button class="btn btn-color-white mx-1 p-2" v-tooltip:bottom="'Gras'" @click="editor.chain().focus().toggleBold().run()"  :disabled="!editor.can().chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
              <span class="text-center align-middle">
                <i class="bi bi-type-bold fs-2 p-0"></i>
              </span>
            </button>
            <button class="btn btn-color-white mx-1 p-2" v-tooltip:bottom="'Italique'" @click="editor.chain().focus().toggleItalic().run()"  :disabled="!editor.can().chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
              <span class="text-center align-middle">
                <i class="bi bi-type-italic fs-2 p-0"></i>
              </span>
            </button>
            <button class="btn btn-color-white mx-1 p-2" v-tooltip:bottom="'Sous-ligner'" @click="editor.chain().focus().toggleUnderline().run()"  :disabled="!editor.can().chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
              <span class="text-center align-middle">
                <i class="bi bi-type-underline fs-2 p-0"></i>
              </span>
            </button>
            <button class="btn btn-color-white mx-1 p-2" v-tooltip:bottom="'Barrer'" @click="editor.chain().focus().toggleStrike().run()"  :disabled="!editor.can().chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
              <span class="text-center align-middle">
                <i class="bi bi-type-strikethrough fs-2 p-0"></i>
              </span>
            </button>
            <br class="d-sm-none">
            <button class="btn btn-color-white mx-1 p-2" v-tooltip:bottom="'Titre'"
                    @click="editor.chain().focus().toggleHeading({level: 1}).run()"
                    :disabled="!editor.can().chain().focus().toggleHeading({level: 1}).run()">
              <span class="text-center align-middle">
                <i class="bi bi-type-h1 fs-2 p-0"></i>
              </span>
            </button>
            <button class="btn btn-color-white mx-1 p-2" v-tooltip:bottom="'Paragraphe'"
                    @click="editor.chain().focus().toggleNode('paragraph','text').run()"
                    :disabled="!editor.can().chain().focus().toggleNode('paragraph','text').run()">
              <span class="text-center align-middle">
                <i class="bi bi-justify-left fs-2 p-0"></i>
              </span>
            </button>
            <button class="btn btn-color-white mx-1 p-2" v-tooltip:bottom="'Couleur'" @click="this.menuColorToggle()">
              <span class="text-center" :style="'border-bottom: 2px solid'+ (editor.getAttributes('textStyle').color || '#000000')">
                <i class="bi bi-text-alphabet fs-2 p-0 pb-0 mb-0" style="font-style: normal;">A</i>
              </span>
            </button>
            <button class="btn btn-color-white mx-1 p-2" v-tooltip:bottom="'Date du jour'" @click="editor.chain().focus().insertContent({type: 'heading', attrs: {level: 1}, content: [{type: 'text', text: 'Le '+new Date().toLocaleDateString()}]}).run()"  :disabled="!editor.can().chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
              <span class="text-center align-middle">
                <i class="bi bi-calendar-date fs-2 p-0"></i>
              </span>
            </button>
            <button class="btn btn-color-white mx-1 p-2" v-tooltip:bottom="'Photo'" @click="this.modalInsertPhoto()" :class="{ 'is-active': editor.isActive('photos') }">
              <span class="text-center align-middle">
                <i class="bi bi-camera fs-2 p-0"></i>
              </span>
            </button>
            <button class="btn btn-color-white mx-1 p-2" v-tooltip:bottom="'Liste à puces'" @click="editor.chain().focus().toggleBulletList().run()"  :disabled="!editor.can().chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
              <span class="text-center align-middle">
                <i class="bi bi-list-ul fs-2 p-0"></i>
              </span>
            </button>
            <button class="btn btn-color-white mx-1 p-2" v-tooltip:bottom="'Liste de tâches'" @click="editor.chain().focus().toggleTaskList().run()"  :disabled="!editor.can().chain().focus().toggleTaskList().run()" :class="{ 'is-active': editor.isActive('taskList') }">
              <span class="text-center align-middle">
                <i class="bi bi-list-check fs-2 p-0"></i>
              </span>
            </button>
          </div>
          <div class="">
            <button class="btn btn-color-white mx-1 p-2" @click="this.saveNotes()" title="Enregistrer" :disabled="!this.leaveGuard">
              <span class="d-none d-sm-block text-center align-middle">
                Enregistrer<span v-if="this.isSaving" class="mt-1 ms-2 spinner-border spinner-border-sm position-absolute"></span>
              </span>
              <span class="d-sm-none la la-floppy-o fs-1"><span v-if="this.isSaving" class="mt-1 ms-2 spinner-border spinner-border-sm position-absolute"></span></span>
            </button>
          </div>
        </div>
        <div v-if="this.menuColor.show" class="card shadow p-3" style="position: absolute; top: 55px;">
          <div class="card-body p-1">
            <button class="btn btn-icon rounded-circle m-1" :style="`background-color: ${this.currentUser.org.persoColor1 || `#000000`}; height: 20px; width: 20px;`" @click="this.editor.chain().focus().setColor(this.currentUser.org.persoColor1 || '#000000').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: this.currentUser.org.persoColor1 || '#000000' })}"></button>
            <button class="btn btn-icon rounded-circle m-1" :style="`background-color: ${this.currentUser.org.persoColor2 || `#195D5B`}; height: 20px; width: 20px;`" @click="this.editor.chain().focus().setColor(this.currentUser.org.persoColor2 || '#195D5B').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: this.currentUser.org.persoColor2 || '#195D5B' })}"></button>
            <button class="btn btn-icon rounded-circle m-1" :style="`background-color: ${this.currentUser.org.persoColor3 || `#3C78D8`}; height: 20px; width: 20px;`" @click="this.editor.chain().focus().setColor(this.currentUser.org.persoColor3 || '#3C78D8').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: this.currentUser.org.persoColor3 || '#3C78D8' })}"></button>
            <button class="btn btn-icon rounded-circle m-1" :style="`background-color: ${this.currentUser.org.persoColor4 || `#A64D79`}; height: 20px; width: 20px;`" @click="this.editor.chain().focus().setColor(this.currentUser.org.persoColor4 || '#A64D79').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: this.currentUser.org.persoColor4 || '#A64D79' })}"></button>
            <button class="btn btn-icon rounded-circle m-1" :style="`background-color: ${this.currentUser.org.persoColor5 || `#CC0000`}; height: 20px; width: 20px;`" @click="this.editor.chain().focus().setColor(this.currentUser.org.persoColor5 || '#CC0000').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: this.currentUser.org.persoColor5 || '#CC0000' })}"></button>
            <button class="btn btn-icon rounded-circle m-1" :style="`background-color: ${this.currentUser.org.persoColor6 || `#F1C232`}; height: 20px; width: 20px;`" @click="this.editor.chain().focus().setColor(this.currentUser.org.persoColor6 || '#F1C232').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: this.currentUser.org.persoColor6 || '#F1C232' })}"></button>
            <i class="bi bi-x text-gray-800 text-hover-primary cursor-pointer align-middle fs-1" @click="this.menuColorToggle()"></i>
          </div>
        </div>
      </div>
      <div v-if="editor" class="form__item form__item--title">
        <PrintHeader v-if="this.editor && this.currentFocus" pageTitle="Descriptif Travaux" sectionTitle=""></PrintHeader>
        <div class="p-10 overflow-visible" tabindex=0>
          <editor-content v-if="this?.editor && this?.incrementDummy" :editor="this.editor"/>
        </div>
      </div>
    </div>
  </div>
  <div>
    <!-- ASSIGN TASK -->
    <TaskSelectModal v-if="this.$store.state.display.modalAssignTask.show" :initiallySelectedTaskIds="modals.assignTaskModal.file.tasks"
                     :projectId="this.currentFocus" @close="closeAssignTaskModal" @save="saveAssignedTasks">
    </TaskSelectModal>
  </div>

</template>

<script>
import kalmStore from "@/store";

import PrintHeader from "@/views/project/tender/PrintHeader";
import { debounce } from '@/utils/debounce'

import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-3'
import { Placeholder } from "@tiptap/extension-placeholder";
import { Underline } from "@tiptap/extension-underline";
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'

import { Paragraph } from '@tiptap/extension-paragraph'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import {CURRENT_USER_QUERY, GET_NOTES_BY_PROJECT_ID, MUTATION_NOTES_UPDATE_BY_PROJECT_ID} from "@/graphql/graphql";

import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import CustomImage from "@/views/project/description/CustomImage/customImage";
import {FileHandler} from "@tiptap-pro/extension-file-handler";
import {ASSIGN_TASKS, FILE_UPLOAD, GET_FILES} from "@/views/documents/data/files_graphql";
import TaskSelectModal from "@/views/tasks/presentation/task-select-modal/TaskSelectModal.vue";

export default {
  name: "NotesEditorPage",
  components: {
      TaskSelectModal,
    EditorContent,
    PrintHeader,
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
    },
    notes: {
      query: GET_NOTES_BY_PROJECT_ID,
      variables() {
        return {
          id: this.currentFocus,
        }
      },
      result({data}) {
        let ret = JSON.parse(JSON.stringify(data.notes));

        ret.document = data.notes.document ? JSON.parse(data.notes.document) : null;

        this.localNotes.document = ret.document;
        this.localNotes.lastUpdate = ret.lastUpdate;

        return ret;
      }
    }
  },
  data() {
    return {
      incrementDummy: 1,
      notes: {},
      localNotes: {
        id: "",
        document: {},
        lastUpdate: null,
      },
      currentUser: {},
      menuColor: {
        show: false,
      },
      editor: null,
      isEditorScrolledDown: false,
      leaveGuard: false,
      isSaving: false,
      modals: {
          assignTaskModal: {
              file: {
                  tasks: [],
              },
          },
      },
      floatingMenuKey: 0,
    }
  },
  beforeRouteLeave(to, from, next) {
    if(this.leaveGuard) {
      if(window.confirm("Vous avez des changements non sauvegardés. Êtes-vous sûr de vouloir quitter ?")){
        next()
      }
      else {
        next(false)
      }
    }
    else { next() }
  },
  methods: {
    closeAssignTaskModal() {
      this.$store.commit('closeModalAssignTask')
    },
    saveAssignedTasks(taskIds) {
      this.$apollo.mutate({
        mutation: ASSIGN_TASKS,
        variables: {
          fileId: kalmStore.state.display.modalAssignTask.data.fileId,
          taskIds: taskIds,
        },
        refetchQueries: [
          {
            query: GET_FILES,
            variables: this.getCommonVariables(),
          },
        ],
      });
      this.closeAssignTaskModal();
    },
    modalInsertPhoto() {
      this.$refs.modalInsertPhotoInput.click()
    },
    modalInsertPhotoValidate($event) {
      Array.from($event.target.files).forEach(file => {
        this.$apollo.mutate({
          mutation: FILE_UPLOAD,
          variables: {
            file,
            ...this.getCommonVariables(),
          },
          context: {
            hasUpload: true,
          },
          update: (cache, result) => {
            const src = (result.data.fileUpload.fileInfo.url.startsWith("http") ? "" : process.env.VUE_APP_URL_BACK) + result.data.fileUpload.fileInfo.url
            this.editor.chain().insertContentAt(this.editor.state.selection.anchor, {
              type: 'image',
              attrs: {
                src,
                fileId: result.data.fileUpload.fileInfo.id,
                width: '500',
              },
            }).focus().run()
          }
        })
      })
    },
    getCommonVariables() {
      return {
        projectId: kalmStore.getters.currentProjectFocus,
        type: "NOTES",
      }
    },
    saveNotes() {
      this.isSaving = true;

      this.$apollo.mutate({
        mutation: MUTATION_NOTES_UPDATE_BY_PROJECT_ID,
        variables: {
          projectId: this.currentFocus,
          document: this.editor.getJSON(),
          lastUpdate: this.localNotes.lastUpdate || "",
        },
        update: (cache, result) => {
          const data = result.data.notesUpdateByProjectId;
          this.localNotes.lastUpdate = data.notes.lastUpdate;
        },
      }).then(() => {
        this.leaveGuard = false;
        // wait 200ms to avoid flickering
        setTimeout(() => {
          this.isSaving = false;
        }, 200);
        console.log("SAVED");
      }).catch(() => {
          toast.error("Une version plus récente a été enregistrée ailleurs.")
          toast.error("Erreur lors de l'enregistrement des notes.")
          this.isSaving = false;
        })
    },
    onElementVisibility(state) {
      this.isEditorScrolledDown = !state;
    },
    scrollMeTo(lotId) {
      setTimeout(function() {document.querySelector(lotId).scrollIntoView({ behavior: 'smooth'});}, 2000);
    },
    fetchFocusDoc() {
      const doc = this.localNotes.document;
      let newDoc;

      if(doc && doc?.type === "doc") {
        newDoc = doc;
      }
      else {
        newDoc = {"type":"doc","content":[{"type": "paragraph"},{"type":"heading","attrs":{"level":1},"content":[{"type":"text","text":"Le "+new Date().toLocaleDateString()}]},{"type":"paragraph","content":[{"type":"text","text":"Bienvenue dans vos notes pour ce projet !"}]},{"type":"taskList","content":[{"type":"taskItem","attrs":{"checked":true},"content":[{"type":"paragraph","content":[{"type":"text","text":"Ajoutez y des éléments à cocher"}]}]},{"type":"taskItem","attrs":{"checked":false},"content":[{"type":"paragraph","content":[{"type":"text","text":"et triez vos notes en ajoutant la date du jour en un clic avec le bouton calendrier ⬆️"}]}]}]},{"type":"paragraph","content":[{"type":"text","text":"Enfin, comme sur vos descriptifs ou votre éditeur de texte habituel :"}]},{"type":"bulletList","content":[{"type":"listItem","content":[{"type":"paragraph","content":[{"type":"text","text":"Vous pouvez ajouter des listes à puces"}]},{"type":"bulletList","content":[{"type":"listItem","content":[{"type":"paragraph","content":[{"type":"text","text":"Avec plusieurs niveaux de hiérarchie"}]}]}]}]},{"type":"listItem","content":[{"type":"paragraph","content":[{"type":"text","text":"Et des photos !"}]},{"type":"bulletList","content":[{"type":"listItem","content":[{"type":"paragraph","content":[{"type":"text","text":"En cliquant sur le bouton de la barre des tâches ⬆️"}]}]},{"type":"listItem","content":[{"type":"paragraph","content":[{"type":"text","text":"En collant une photo depuis votre presse-papier"}]}]},{"type":"listItem","content":[{"type":"paragraph","content":[{"type":"text","text":"En la glissant dans l'éditeur"}]}]}]}]}]},{"type":"paragraph","content":[{"type":"text","text":"Toutes les photos seront enregistrées dans le dossier "},{"type":"text","marks":[{"type":"code"}],"text":"NOTES"},{"type":"text","text":" du projet :-)"}]}]}
      }
      return newDoc;
    },
    syncDocument(newJSONDoc) {
      this.localNotes.document = newJSONDoc;
      this.saveNotes()
    },
    printDocument() {
      this.$nextTick(function(){
        window.print();
      })
    },
    loadEditor() {
      console.log("RESETTING EDITOR")
      this.floatingMenuKey++;
      this.editor = new Editor({
        extensions: [
          Document,
          TaskList,
          TaskItem,
          Placeholder,
          Underline,
          TextStyle,
          Color,
          CustomImage.configure({
            inline: true,
          }),
          FileHandler.configure({
            priority: 10000,
            allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],

            onDrop: (currentEditor, files, pos) => {

              files.forEach(file => {
                this.$apollo.mutate({
                  mutation: FILE_UPLOAD,
                  variables: {
                    file,
                    ...this.getCommonVariables(),
                  },
                  context: {
                    hasUpload: true,
                  },
                  update: (cache, result) => {
                    const src = (result.data.fileUpload.fileInfo.url.startsWith("http") ? "" : process.env.VUE_APP_URL_BACK) + result.data.fileUpload.fileInfo.url
                    currentEditor.chain().insertContentAt(pos, {
                      type: 'image',
                      attrs: {
                        src,
                        fileId: result.data.fileUpload.fileInfo.id,
                        width: '500',
                      },
                    }).focus().run()
                  }
                })
              })
            },
            onPaste: (currentEditor, files) => {
              files.forEach(file => {
                this.$apollo.mutate({
                  mutation: FILE_UPLOAD,
                  variables: {
                    file,
                    ...this.getCommonVariables(),
                  },
                  context: {
                    hasUpload: true,
                  },
                  update: (cache, result) => {
                    const src = (result.data.fileUpload.fileInfo.url.startsWith("http") ? "" : process.env.VUE_APP_URL_BACK) + result.data.fileUpload.fileInfo.url;
                    currentEditor.chain().insertContentAt(currentEditor.state.selection.anchor, {
                      type: 'image',
                      attrs: {
                        src,
                        fileId: result.data.fileUpload.fileInfo.id,
                        width: '500',
                      },
                    }).focus().run()
                  }
                })
              })
            },
          }),
          StarterKit.configure({
            dropcursor: false,
            paragraph: false,
          }),
          Paragraph.extend({
            priority: 1001,
          }),
        ],
      })

      this.editor.on('update', () => {this.leaveGuard = true});
      this.editor.on('update', debounce(({editor}) => this.syncDocument(editor.getJSON()), 500));
    },
    menuColorToggle() {
      this.menuColor.show = !this.menuColor.show;
    },
    beforeWindowUnload(e) {
      if(this.editor && this.leaveGuard) {
        e.preventDefault();
        e.returnValue = '';
      }
    },
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },
  mounted() {

  },
  watch: {
    'editor': function(newValue, oldValue) {
      if(newValue && !oldValue && this.isStoreReady){
          console.log("type 1")
        console.log("and is valid, loading content")
        this.editor.chain().setContent(this.fetchFocusDoc()).focus('start').run();
        this.editor.commands.focus("start")
      }
    },
    'currentProject': async function () {
        this.$apollo.queries.notes.refresh()
    },
    'notes': {
        deep: true,
        handler: function(newValue, oldValue) {
            if(newValue?.id !== oldValue?.id) {
                if(this.editor) {
                    if(this.isStoreReady) {
                        this.editor.off('update');
                        this.editor.destroy();
                        this.loadEditor();
                        this.editor.chain().setContent(this.fetchFocusDoc()).run();
                    }
                    else {
                        this.editor.off('update');
                        this.editor.destroy();
                        this.loadEditor();
                    }
                }
                else {
                    this.loadEditor();
                }
            }
        }
    }
  },
  computed: {
    isStoreReady(){
      return !!this?.localNotes?.document
    },
    isModalActive(){
      return kalmStore.state.display.profileToggled
    },
    currentFocus(){
      return this.$route.params.id;
    },
    currentProject(){
      return kalmStore.state.description.projects.find(p => p.id === this.currentFocus);
    },
    getProjects(){
      return kalmStore.state.description.projects.filter(p => p.id !== this.currentFocus)
    },
  },
  beforeUnmount() {
    if(this.editor){
      this.editor.destroy();
    }
    document.removeEventListener('keydown', this._keyListener);
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },
}
</script>

<style lang="scss">
/* Basic editor styles */
.tiptap {
  img {
    max-width: 80%;
    height: auto;
    margin-top: 5px;
    margin-bottom: 15px;
    border-radius: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;

    &.ProseMirror-selectednode {
      outline: 3px solid #68CEF8;
    }
  }
}

</style>

<style>

.tooltip {
  z-index: 10000;
}



/* Removes the arrows from number inputs*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ProseMirror h4.is-empty::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror h4 + p.is-empty::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

@media print {
  .tooltip {
    display: none !important;
    visibility: hidden !important;
  }

  .ProseMirror > p {
    display: none;
  }
}
</style>

<style scoped>


.editor {
  border-radius: 20px;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0 20px 30px; /*0 0px 40px 5px rgba(0, 0, 0, 0.2);*/
  background-color: white;
}

.editor-buttons-sticky {
    /*noinspection CssInvalidPropertyValue*/
    position: -webkit-sticky;
  position: sticky;
  top: 70px;
  z-index: 180;
}

.drag-handle {
  flex: 0 0 auto;
  position: relative;
  width: 1rem;
  height: 1rem;
  top: 0.3rem;
  right: 50px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  cursor: grab;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.ProseMirror-focused:focus {
  outline: none !important;
}
.ProseMirror:focus {
  outline: none !important;
}
.ProseMirror-focused:focus-visible {
  outline: none !important;
}
.ProseMirror:focus-visible {
  outline: none !important;
}

.floating-menu {
  display: flex;
  padding: 0.2rem;
  border-radius: 0.5rem;
  z-index: 100 !important;
}
.floating-menu button {
  position: relative;
  color: #000;
  margin: 0.1rem;
  border: 1px solid black;
  border-radius: 0.3rem;
  padding: 0.1rem 0.4rem;
  background: white;
  accent-color: black;
  z-index: 100 !important;
}
.floating-menu button:hover, .floating-menu button.is-active {
  opacity: 1;
}

.editor-toolbar {
  background-color: #195D5B;
  opacity: 1;
  backdrop-filter: blur(10px);
}
.editor-toolbar.toolbar-top {
  border-radius: 20px 20px 0 0;
  min-height: 50px;
}
.editor-toolbar.toolbar-sticky {
  border-radius: 25px;
  min-height: 40px;
}

@media print {
  .card {
    box-shadow: none;
  }

  .print-hidden {
    display: none !important;
  }

  .editor {
    box-shadow: none;
  }
}
</style>

<style lang="scss">
  ul[data-type="taskList"] {
    list-style: none;
    padding-left: 0;

    li {
      display: flex;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;
      }

      > div {
        flex: 1 1 auto;
      }

      ul li,
      ol li {
        display: list-item;
      }

      ul[data-type="taskList"] > li {
        display: flex;
      }
    }
  }
</style>