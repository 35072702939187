import gql from 'graphql-tag';

export const CURRENT_USER_QUERY = gql`
    query CurrentUserQuery {
        currentUser {
            id,
            email,
            phone,
            firstName,
            lastName,
            groups,
            org {
                id,
                name,
                logo,
                infoSiret,
                infoAddress,
                persoAlwaysForceSave,
                persoColorMain,
                persoColor1,
                persoColor2,
                persoColor3,
                persoColor4,
                persoColor5,
                persoColor6,
                persoTaskSort1,
                persoTaskSort2,
                persoTaskSort3,
                persoTaskSort4,
                persoFontBody,
                persoFontTitle,
                team {
                    id,
                    firstName,
                    lastName,
                    photo,
                    color,
                    email,
                    phone,
                    role,
                    linkedContact {
                        id
                    }
                }
            }
            isSuperuser,
            orgs {
                id,
                name
            }
            appsheetUrl,
            role,
            trustleadToken,
            trustleadActivated,
            trustleadOrganizationId,
        }
    }
`
