<template>
    <KalmModal modalTitle="Supprimer cette tâche" @close="close">
        <template v-slot:body>
            <p>Etes-vous sur de vouloir supprimer cette tâche ?</p>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close">Annuler</button>
            <LoadingButton v-if="isSaving"></LoadingButton>
            <button v-if="!isSaving" type="button" class="btn btn-danger" @click="confirm">Supprimer</button>
        </template>
    </KalmModal>
</template>

<script>
import KalmModal from '@/views/_core/components/KalmModal.vue';
import LoadingButton from '@/views/_core/components/LoadingButton.vue';
import {
DELETE_TODO_ITEM,
GET_TODOS
} from "../../../data/todos_graphql";
export default {
    name: "DeleteTodoItemConfirmModal",
    components: {
        KalmModal,
        LoadingButton
    },
    props: {
        todoItem: {
            type: Object,
            required: true,
        },
        projectId: {
            type: String,
            required: false,
        },
        assignedToCurrentUser: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            isSaving: false,
        };
    },
    methods: {
        async confirm() {
            this.isSaving = true;
            try {
                await this.$apollo.mutate({
                    mutation: DELETE_TODO_ITEM,
                    variables: {
                        id: this.todoItem.id,
                    },
                    refetchQueries: [
                        {
                            query: GET_TODOS,
                            variables: {
                                projectId: this.projectId,
                                assignedToCurrentUser: this.assignedToCurrentUser
                            }
                        },
                    ],
                });
            } catch (error) {
                console.error('Delete todo item error:', error);
            }
            this.isSaving = false;
            this.close();
        },
        close() {
            this.$emit("close");
        },
    },
};
</script>