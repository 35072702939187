import gql from 'graphql-tag'

export const ALL_STATUSES_QUERY = gql`
    query AllStatusesQuery {
        statuses: allStatuses {
            id,
            name,
            type,
            order,
            defaultIncluded,
            isDefault,
            color,
        }
    }
`