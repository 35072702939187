<template>
    <KalmModal modalTitle="Photos & pièces-jointes" @close="close">
        <template v-slot:body>
            <div class="">
                <label class="">Disposition des photos
                    <span class="ms-1 bi bi-info-circle" v-tooltip:bottom="'Nombre de photos par ligne'"></span>
                </label>
                <div>
                    <input type="radio" class="btn-check" id="layoutCheckbox1" :value=1 v-model="this.layout">
                    <label title="Largeur complète" class="btn btn-sm rounded-end-0 btn-light" for="layoutCheckbox1">
                        1 <span class="ms-2 bi bi-square"></span>
                    </label>
                    <input type="radio" class="btn-check" id="layoutCheckbox2" :value=2 v-model="this.layout">
                    <label title="2 images par ligne" class="btn btn-sm rounded-0 btn-light" for="layoutCheckbox2">
                        2 <span class="ms-2 bi bi-grid"></span>
                    </label>
                    <input type="radio" class="btn-check" id="layoutCheckbox3" :value=3 v-model="this.layout">
                    <label title="3 images par ligne" class="btn btn-sm rounded-start-0 btn-light" for="layoutCheckbox3">
                        3 <span class="ms-2 bi bi-grid-3x2-gap"></span>
                    </label>
                </div>
            </div>
            <div class="mt-4 mb-10">
                <label>Ajouter une photo :</label>
                <input class="form-control" type="file" @change="handleFileChange" accept="image/*" multiple>
            </div>
            <div v-if="task.files.length > 0">
                <p class="fw-bold text-gray-700">Photos liées à la tâche :</p>
                <div class="d-row">
                    <div class="position-relative d-inline-block m-3" v-for="file in task.files" :key="file.id">
                        <img class="rounded shadow shadow-xs w-auto" alt="Image non décrite inclue sur la tâche pour le CR" :src="buildFullUrl(file.url)" height=100>
                        <button class="btn btn-icon btn-sm btn-primary position-absolute"
                                style="top: -10px; right: -10px;"
                                @click="removeFileFromTask(file.id)">
                            <span class="bi bi-x"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div v-else class="">
                <p>Aucune photo liée à cette tâche</p>
            </div>
        </template>
    </KalmModal>
</template>

<script>
import KalmModal from '@/views/_core/components/KalmModal.vue';
import {GET_TASK_BY_ID, UPDATE_TASK} from "@/views/tasks/data/tasks_graphql";
import {FILE_UPLOAD, UNASSIGN_TASK} from "@/views/documents/data/files_graphql";

export default {
    name: "FilesForTaskModal",
    apollo: {
        task: {
            query: GET_TASK_BY_ID,
            variables() {
                return {
                    taskId: this.taskId,
                }
            },
            skip() {
                return !this.taskId;
            },
        },
    },
    data() {
        return {
            task: {
                files: [],
            },
        };
    },
    props: {
        taskId: {
            type: String,
            required: true,
        },
    },
    components: {
        KalmModal,
    },
    methods: {
        removeFileFromTask(fileId) {
            this.$apollo.mutate({
                mutation: UNASSIGN_TASK,
                variables: {
                    fileId: fileId,
                    taskToRemoveId: this.taskId,
                },
                context: {
                    hasUpload: true,
                },
                refetchQueries: [
                    {
                        query: GET_TASK_BY_ID,
                        variables: {
                            taskId: this.taskId,
                        },
                        skip() {
                            return !this.taskId;
                        },
                    },
                ],
            });
        },
        close() {
            this.$emit("close");
        },
        buildFullUrl(url){
            if (url.startsWith("http")) {
                return url;
            }
            return process.env.VUE_APP_URL_BACK + url
        },
        openFileExplorer() {
            // Trigger the click event on the hidden file input
            this.$refs.fileInput.click();
        },
        async handleFileChange(event) {
            const selectedFiles = event.target.files;
            for (const file of selectedFiles) {
                try {
                    await this.$apollo.mutate({
                        mutation: FILE_UPLOAD,
                        variables: {
                            file,
                            projectId: this.projectId,
                            taskId: this.taskId,
                            type: "MONITORING",
                        },
                        context: {
                            hasUpload: true,
                        },
                        refetchQueries: [
                            {
                                query: GET_TASK_BY_ID,
                                variables: {
                                    taskId: this.taskId,
                                },
                                skip() {
                                    return !this.taskId;
                                },
                            },
                        ],
                    });
                } catch (error) {
                    console.error('File upload error:', error);
                }
            }
        },
    },
    computed: {
        projectId() {
            return this.$store.getters.currentProjectFocus;
        },
        layout: {
            get() {
                return this.task.layout
            },
            set(newValue) {
                console.log("newvalue", newValue)
                this.$apollo.mutate({
                    mutation: UPDATE_TASK,
                    variables: {
                        id: this.taskId,
                        input: {
                            layout: newValue
                        }
                    }
                });
            }
        }
    }
}
</script>