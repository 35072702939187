<template>
    <div class="p-8 border rounded">
        <div>
            <label class="form-label" for="name">Nom</label>
            <input v-model="name" type="text" class="form-control" id="name" />
        </div>
        <div class="mt-4">
            <label class="form-label" for="description">Description</label>
            <textarea v-model="description" type="text" rows="3" class="form-control" id="description" />
        </div>
        <div class="mt-4">
            <label class="form-label" for="date">Date</label>
            <input v-model="dueDate" type="date" class="form-control" id="date" />
        </div>
        <div class="mt-4">
            <UserSelect v-model="assignedToId" title="Assigné à" />
        </div>
        <div class="mt-8 d-flex gap-3">
            <button @click="close" type="button" class="btn btn-secondary">Annuler</button>
            <button v-if="!isSaving" @click="save" :disabled="!canSave()" type="button" class="btn btn-primary">{{
                this.todoItem ? 'Sauvegarder' : 'Ajouter' }}</button>
            <LoadingButton v-if="isSaving"></LoadingButton>
        </div>
    </div>
</template>

<script>
import LoadingButton from '@/views/_core/components/LoadingButton.vue';
import UserSelect from '@/views/users/presentation/user-select/UserSelect.vue';
import dayjs from "dayjs";
import { CREATE_TODO_ITEM, GET_TODOS, UPDATE_TODO_ITEM } from '../../data/todos_graphql';

export default {
    name: "TodoForm",
    props: {
        todoItem: Object,
        todoId: {
            type: String,
            required: true,
        },
        projectId: {
            type: String,
            required: false,
        },
        lastOrder: Number,
        autoAssignedToId: String,
        assignedToCurrentUser: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    components: {
        UserSelect,
        LoadingButton
    },
    data() {
        return {
            name: "",
            description: "",
            dueDate: undefined,
            isSaving: false,
            assignedToId: undefined,
        };
    },
    created() {
        this.initializeData();
    },
    methods: {
        initializeData() {
            if (this.todoItem) {
                this.name = this.todoItem.name;
                this.description = this.todoItem.description;
                this.dueDate = this.todoItem.dueDate ? dayjs(this.todoItem.dueDate).format('YYYY-MM-DD') : undefined
                this.assignedToId = this.todoItem.assignedTo?.id;
            } else if (this.autoAssignedToId) {
                this.assignedToId = this.autoAssignedToId;
            }
        },
        canSave() {
            return this.name?.length;
        },
        close() {
            this.$emit("close");
        },
        async save() {
            this.isSaving = true;
            if (this.todoItem) {
                try {
                    await this.$apollo.mutate({
                        mutation: UPDATE_TODO_ITEM,
                        variables: {
                            id: this.todoItem.id,
                            name: this.name,
                            description: this.description,
                            dueDate: this.dueDate,
                            assignedToId: this.assignedToId == null ? '' : this.assignedToId,
                        },
                        refetchQueries: [
                            {
                                query: GET_TODOS,
                                variables: {
                                    projectId: this.projectId,
                                    assignedToCurrentUser: this.assignedToCurrentUser
                                }
                            },
                        ],
                    });
                }
                catch (error) {
                    console.error('Update todoItem error:', error);
                }
                this.close();
            }
            else {
                try {
                    await this.$apollo.mutate({
                        mutation: CREATE_TODO_ITEM,
                        variables: {
                            name: this.name,
                            description: this.description,
                            dueDate: this.dueDate,
                            projectId: this.projectId,
                            todoId: this.todoId,
                            assignedToId: this.assignedToId,
                            order: (this.lastOrder || 0) + 1,
                        },
                        refetchQueries: [
                            {
                                query: GET_TODOS,
                                variables: {
                                    projectId: this.projectId,
                                    assignedToCurrentUser: this.assignedToCurrentUser
                                }
                            },
                        ],
                    });
                    this.name = "";
                    this.description = "";
                    this.due_date = undefined;
                }
                catch (error) {
                    console.error('Create todoItem error:', error);
                }
            }
            this.isSaving = false;
        },
    },
}
</script>