import { createApp, h } from "vue";
import App from "@/App";

// Apollo
import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { logErrorMessages } from '@vue/apollo-util'

import kalmRouter from "@/router/index.js";
import kalmStore from "@/store";

import { Tooltip } from "bootstrap";
import 'bootstrap-icons/font/bootstrap-icons.css'
import Vue3Toastify from "vue3-toastify";
import ganttastic from '@infectoone/vue-ganttastic';
import dayjs from 'dayjs';
import localeFR from 'dayjs/locale/fr';
import dayjsTimezone from 'dayjs/plugin/timezone'
import dayjsUtc from "dayjs/plugin/utc";
import './registerServiceWorker'
import VueObserveVisibility from 'vue3-observe-visibility'
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3';

// Monitoring
import posthogPlugin from "@/utils/posthog";
import * as Sentry from "@sentry/vue";
import {onError} from "@apollo/client/link/error";

// dayjs configuration
dayjs.locale(localeFR)
dayjs.extend(dayjsTimezone)
dayjs.extend(dayjsUtc)
dayjs.tz.setDefault('Europe/Paris')

fetch(process.env.VUE_APP_URL_BACK+"/auth/session/", {credentials: "include"})
    .then(response => response.json())
    .then((data) => {
        if(!data.isAuthenticated) {
            window.location.replace(process.env.VUE_APP_URL_BACK+"/auth/login");
        }
        else {

            // HTTP connection to the API -- with file upload capacity
            const uploadLink = createUploadLink({
                // You should use an absolute URL here
                uri: process.env.VUE_APP_URL_BACK+"/auth/graphql",
                credentials: 'include'
            })

            // Error Handler
            const errorLink = onError((error) => {
                if (process.env.NODE_ENV !== 'production') {
                    logErrorMessages(error)
                }
                console.log("global error handler")
                const errorMessage = error.response?.errors[0]?.message

                // If session expired error, toggle re-authentication flow and mute error
                if(errorMessage === "Unauthenticated") {
                    kalmStore.commit("toggleExpiredSessionModal",  {mode: "open"})
                    error.response.errors = null;
                }
            })

            // Cache implementation
            const cache = new InMemoryCache()

            // Create the apollo client
            const kalmApolloClient = new ApolloClient({
                link: errorLink.concat(uploadLink),
                cache,
            })

            // Apollo
            const kalmApolloProvider = createApolloProvider({
                defaultClient: kalmApolloClient,
            })

            // Tooltip
            const tooltipDirective = {
                mounted(el, binding) {
                    el.setAttribute('data-toggle', 'tooltip')
                    el.classList.add("tooltip-inverse");

                    new Tooltip(el,{
                        title: binding.value,
                        placement: binding.arg,
                        trigger: 'hover',
                        delay: { "show": 200, "hide": 100 },
                    })
                }
            }

            const app = createApp({
                render: () => h(App),
            })
                .use(posthogPlugin)
                // load apollo
                .use(kalmApolloProvider)
                // load router & store
                .use(kalmRouter)
                .use(kalmStore)
                .use(Vue3Toastify, {
                    dangerouslyHTMLString: true,
                    autoClose: 3000,
                    newestOnTop: true,
                    position: 'bottom-right',
                    hideProgressBar: true,
                    transition: 'slide',
                })
                .use(dayjs)
                .use(ganttastic)
                .use(TawkMessengerVue, {
                    propertyId : '65707384bfb79148e59aab66',
                    widgetId : '1hgvk6f9u',
                })
                .directive('observe-visibility', VueObserveVisibility)
                .directive('tooltip', tooltipDirective)

            // Monitoring
            Sentry.init({
                app,
                dsn: "https://b5504e0ac8664a446ae0da3bdcae3d3b@o4506099219365888.ingest.us.sentry.io/4507455317016576",
                enabled: process.env.NODE_ENV === 'production',
                integrations: [
                    Sentry.browserTracingIntegration({router: kalmRouter}),
                    Sentry.replayIntegration(),
                ],
                // Performance Monitoring
                tracesSampleRate: 1.0, //  Capture 100% of the transactions
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                //tracePropagationTargets: ["localhost", "https://app.kalm.ai/auth/graphql", "https://dev.kalm.ai/auth/graphql"],
                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });

            app.mount("#app")
        }
    })
