<template>
    <div>
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
                <!-- if isDraggable -->
                <div v-if="isDraggable" class="lot-drag-handle me-2" style="cursor: grab;">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                        style="width: 15px; height: 100%; display: block;" class="align-self-center">
                        <path fill-opacity="0.2"
                            d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                    </svg>
                </div>
                <div class="form-check">
                    <input @change="markAsDone()" class="form-check-input" type="checkbox" :value="todoItem.isDone"
                        :checked="todoItem.isDone" :id="todoItem.id">
                    <label class="form-check-label" :class="{ 'text-decoration-line-through': todoItem.isDone }"
                        :for="todoItem.id">
                        {{ todoItem.name }}
                    </label>
                </div>
            </div>
            <div class="flex-shrink-0">
                <span class="badge badge-secondary me-4 text-gray-600" v-if="todoItem.assignedTo">{{
                    todoItem.assignedTo.firstName + ' ' + todoItem.assignedTo.lastName }}</span>
                <span class="badge badge-secondary me-4 text-gray-600" v-if="todoItem.dueDate">{{
                    getFormattedDueDate(todoItem.dueDate)
                    }}</span>
                <IconButton v-if="!!todoItem.description" iconName="file-earmark-text"
                    v-tooltip:bottom="todoItem.description.substring(0, 100)" @click="editTodoItem" />
                <FileCounterForTodoItem :todoItemId="todoItem.id" @click="openFiles" />
                <EditButton @click="editTodoItem"></EditButton>
                <DeleteButton @click="showDeleteTodoItem"></DeleteButton>
            </div>
        </div>

        <!-- Edit form -->
        <TodoItemForm class="mt-4" v-if="showTodoItemForm" :todoItem="todoItem" :projectId="projectId" :todo-id="todoId"
            :assignedToCurrentUser="assignedToCurrentUser" @close="reset" />
        <!-- Delete modal -->
        <DeleteTodoItemConfirmModal v-if="showTodoItemDeleteModal" :todoItem="todoItem" :projectId="projectId"
            :assignedToCurrentUser="assignedToCurrentUser" @close="reset" />
        <!-- Files modal -->
        <FilesForTodoItemModal v-if="showFilesModal" :todoItemId="todoItem.id" @close="reset" />
    </div>
</template>

<script>
import DeleteButton from '@/views/_core/components/DeleteButton.vue';
import EditButton from '@/views/_core/components/EditButton.vue';
import IconButton from '@/views/_core/components/IconButton.vue';
import FileCounterForTodoItem from '@/views/documents/presentation/file-counter-for-todo-item/FileCounterForTodoItem.vue';
import FilesForTodoItemModal from "@/views/documents/presentation/file-list-modal/FilesForTodoItemModal.vue";
import dayjs from 'dayjs';
import {
UPDATE_TODO_ITEM
} from "../../../data/todos_graphql";
import TodoItemForm from "../../todo-form/TodoItemForm.vue";
import DeleteTodoItemConfirmModal from "./DeleteTodoItemConfirmModal.vue";
export default {
    name: "TodoItem",
    props: {
        todoItem: {
            type: Object,
            required: true,
        },
        todoId: {
            type: String,
            required: true,
        },
        projectId: {
            type: String,
            required: false,
        },
        isDraggable: {
            type: Boolean,
            required: false,
            default: true,
        },
        assignedToCurrentUser: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        DeleteTodoItemConfirmModal,
        TodoItemForm,
        EditButton,
        DeleteButton,
        FileCounterForTodoItem,
        FilesForTodoItemModal,
        IconButton,
    },
    data() {
        return {
            showTodoItemDeleteModal: false,
            showTodoItemForm: false,
            showFilesModal: false,
        };
    },
    methods: {
        reset() {
            this.showTodoItemDeleteModal = false;
            this.showTodoItemForm = false;
            this.showFilesModal = false;
        },
        showDeleteTodoItem() {
            this.showTodoItemDeleteModal = true;
        },
        editTodoItem() {
            this.showTodoItemForm = true;
        },
        markAsDone() {
            this.$apollo.mutate({
                mutation: UPDATE_TODO_ITEM,
                variables: {
                    id: this.todoItem.id,
                    isDone: !this.todoItem.isDone,
                },
            });
        },
        openFiles() {
            this.showFilesModal = true;
        },
        getFormattedDueDate(date) {
            return dayjs(date).format('DD MMM YY');
        }
    }
}
</script>