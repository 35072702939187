<template>
  <div>Redirection...</div>
</template>

<script>
export default {
  name: "LogoutPage",
  mounted() {
    // todo ping api logout route
    window.location.href = process.env.VUE_APP_URL_BACK + "/auth/logout/";
  },
}
</script>

<style scoped>

</style>