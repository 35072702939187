<template>
  <div id="print-header-main" class="mb-5 d-print-flex flex-column align-content-center">
    <div class="row">
      <div v-if="currentUser.org.logo" class="col-2">
        <img alt="Logo" :src="imageUrl" fill="#01949A" style="max-width: 150px;" />
      </div>
      <div :class="currentUser.org.logo ? 'col-10' : 'col-12'">
        <h1 class="text-center text-dmserif mb-0" :style="{color: this.mainColor}">{{ pageTitle }}</h1>
        <p class="text-center text-gray-700 fs-3">Projet {{ project.name }} — {{ new Date().toLocaleDateString("fr") }}</p>
        <div class="justify-content-center" v-if="project.address">
          <p class="text-center text-gray-700 text-break" v-if="project.infoOpName"><span class="fw-bolder text-gray-800">Opération : </span>{{ project.infoOpName }}</p>
          <p class="text-center text-gray-700 text-break" v-if="project.address"><span class="fw-bolder text-gray-800">Adresse : </span>{{ project.address }}</p>
        </div>
      </div>
    </div>
    <div class="mt-10 row justify-content-center">
      <div class="col-5" v-if="this.moes?.length">
        <h3 class="text-dmserif text-center" :style="{color: this.mainColor}">Maîtrise d'œuvre</h3>
        <template v-for="moe in this.moes" :key="moe.id">
            <div v-if="moe.contact?.infoLine1 || moe.contact?.infoLine1">
                <p class="mb-0 text-break fw-bolder">{{ moe.contact.infoLine1 }}</p>
                <p class="mb-0 text-break" style="white-space: pre-wrap">{{ moe.contact.infoLine2 }}</p>
            </div>
            <div v-else>
                <p class="mb-0 text-break fw-bolder">{{ currentUser.org.name }}</p>
                <p class="mb-0 text-break">{{ moe.contact.name }}</p>
                <p class="mb-0 text-break">{{ moe.contact.phone }}</p>
                <p class="text-break">{{ moe.contact.email }}</p>
            </div>
        </template>
      </div>
      <div class="col-1"></div>
      <div class="col-5" v-if="this.moas?.length">
        <h3 class="text-dmserif text-center" :style="{color: this.mainColor}">Maîtrise d'ouvrage</h3>
        <template v-for="moa in this.moas" :key="moa.id">
            <div v-if="moa.contact?.infoLine1 || moa.contact?.infoLine1">
                <p class="mb-0 text-break fw-bolder">{{ moa.contact.infoLine1 }}</p>
                <p class="mb-0 text-break" style="white-space: pre-wrap">{{ moa.contact.infoLine2 }}</p>
            </div>
            <div v-else>
              <p class="mb-0 text-break fw-bolder" v-if="moa.contact.type === 'CUSTOMER_PRO'">{{ moa.contact.company }}</p>
              <p class="mb-0 text-break fw-bolder" v-else>{{ moa.contact.name }}</p>
              <p class="mb-0 text-break">{{ moa.contact.phone }}</p>
              <p class="text-break ">{{ moa.contact.email }}</p>
          </div>

        </template>
      </div>
    </div>
    <h2 v-if="sectionTitle" class="text-center mt-5 mb-0 text-dmserif" :style="{color: this.mainColor}">{{ sectionTitle }}</h2>
    <p v-if="sectionText" class="text-center text-gray-700 pt-5">{{ sectionText }}</p>
  </div>
</template>

<script>
import kalmStore from "@/store";
import { CURRENT_USER_QUERY } from "@/graphql/graphql";

export default {
  name: "PrintHeader",
  apollo: {
    currentUser: CURRENT_USER_QUERY,
  },
  props: {
    pageTitle: {
      type: String,
      default: "",
      required: true
    },
    sectionTitle: {
      type: String,
      default: "",
      required: true
    },
    sectionText: {
      type: String,
      default: "",
      required: false
    },
  },
  data() {
    return {
      currentUser: {
        org: {
          logo: null,
          persoColorMain: null,
        }
      }
    }
  },
  methods: {

  },
  computed: {
    mainColor(){ return this.currentUser.org?.persoColorMain || "#195D5B"},
    project() {
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus);
    },
    moas() {
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus).contacts.filter(c => c.role === "MOA");
    },
    moes() {
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus).contacts.filter(c => c.role === "MOE");
    },
    imageUrl() {
      return (this.currentUser.org.logo.startsWith("http") ? "" : process.env.VUE_APP_URL_BACK) + this.currentUser.org.logo
    }
  },
}
</script>

<style scoped>
  @media print {
    #print-header-main {
      display: block;
    }

    @page {
      /* margin box at top right showing page number */
      @bottom-right-corner {
        content: "Page";
        color: black;
        display: block;
      }
    }
  }

  @media screen {
    #print-header-main {
      display: none;
      visibility: hidden;
    }
  }

  h1 {
    font-size: 26pt;
  }

  h2 {
    font-size: 20pt;
  }

  h3 {
    font-size: 16pt;
  }
</style>

<style>
  .text-dmserif {
    font-family: "DM Serif Display", serif;
  }
</style>