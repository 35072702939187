import gql from 'graphql-tag';

/*export const GET_REPORTS = gql`
    query ProjectReports($projectId: UUID!) {
        reports: projectReports(projectId: $projectId) {
          id
          reportDate
          reportNumber
          reportName
          file {
              id
              url
              name
              creationDate
              archived
              archivedDate
          }
          prochaineReunion
          importantInfo
          includePlanning
          crIncludedStatus {
              id
              name
              type
          }
          iterationMode
          projectReportContacts {
              id
              isPresent
              attendanceStatus
              isConvoque
              contactProjectParticipation {
                  contact {
                      id
                  }
              }
          }
        }
    }
`;*/


export const EXPORT_DOCUMENT_EXCEL_MUTATION = gql`
  mutation ProjectExportDocument($exportFormat: String = "EXCEL", $lots: [UUID], $projectId: UUID!) {
    projectExportDocument(exportFormat: $exportFormat, lots: $lots, projectId: $projectId) {
      url
    }
  }
`;